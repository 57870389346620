import React, { ReactNode } from 'react'
import { Backdrop, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogTitle, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import CMSApi from '../../utils/opsloth_cms_service_api'
import getSmallerImgUrl from '../../utils/getSmallerImgUrl'
import ExcelJS from 'exceljs'
import savefile from '../../utils/savefile';
import CircularProgressWithLabel from '../public/CircularProgressWithLabel';


function OrderTableArray(props: any) {
  const [showMore, setShowMore] = React.useState(false)
  const [products, setProducts] = React.useState(props.order.products?.slice(0, 1))

  const [chooseDialogShow, setChooseDialogShow] = React.useState(false)
  const [chooseDialogItems, setChooseDialogItems] = React.useState<string[]>([])
  const [chooseItemClickedValue, setChooseItemClickedValue] = React.useState('/')

  const [realColumnsModifyDialogShow, setRealColumnsModifyDialogShow] = React.useState(false)
  const [realColumnsClickedValue, setRealColumnsClickedValue] = React.useState(0)
  const [realColumnsInputValue, setRealColumnsInputValue] = React.useState(0)

  const modifyClickedIndex = React.useRef(-1)
  const [clickedDialogKey, setClickedDialogKey] = React.useState('')

  const lastCheckedSubProductIndex = React.useRef(0)
  const [checkedSubproductArray, setCheckedSubproductArray] = React.useState<number[]>([])
  const [exportPercentage, setExportPercentage] = React.useState(0)

  const minimal_base64_img = 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
  const ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

  let headers = ['序号', '例图', '品牌', '货号', '日文名称', '中文名称', '日元售价', '代理版(元/个)', '日版(元/个)', '每袋数量', '每箱数量', '订购(袋)', '订购(箱)', '备注', '发货数量', '金额', '发货状态']
  let headers_key = ['order', 'mainImage', 'brand', 'product_code', 'name_jp', 'name_zh', 'price_jpy', 'price_cny_cn', 'price_cny_jp', 'pack_spec', 'carton_spec', 'order_number_pack', 'order_number_carton', 'order_remark', 'real_number', 'order_price', 'cargo_sent']
  let order_headers = ['订单金额￥', '已付定金￥', '物流费用￥', '已付尾款￥', '物流状态', '物流单号', '订单状态', '订单备注', '创建者', '创建时间']
  let order_headers_key = ['price_cny', 'deposit_cny', 'logistic_cny', 'balance_cny', 'cargo_sent', 'cargo_no', 'order_status', 'remark', 'nickname', 'createdAt']


  const handleMoreClick = async () => {
    if (showMore) {
      setShowMore(false)
      setProducts(props.order.products.slice(0, 1))
    } else {
      setShowMore(true)
      setProducts(props.order.products)
    }
  }

  const handleChooseItemClick = (event: any, key: string) => {
    if (key === 'cargo_sent') {
      setChooseDialogItems(['/', '已请款', '已发货', '已取消'])
    }

    if (key === 'real_status') {
      setChooseDialogItems(['/', '提交报关', '报关失败', '报关成功'])
    }

    setClickedDialogKey(key)

    setChooseDialogShow(true)
    setChooseItemClickedValue((event.target as any).textContent)
  }

  const handleChooseItemChange = async (event: any) => {

    let column_name = ''
    if (clickedDialogKey === 'cargo_sent') {
      column_name = '发货状态'
    }

    if (clickedDialogKey === 'real_status') {
      column_name = '报关进度'
    }

    await modifySubProdct(clickedDialogKey, modifyClickedIndex.current, event.target.value, column_name, checkedSubproductArray)
  }

  const handleOrderChecked = (event: any) => {
    function modifyCheckedArrayByIndex(index: number) {
      console.log(event.target.checked, checkedSubproductArray.indexOf(index))
      if (event.target.checked && checkedSubproductArray.indexOf(index) == -1) {
        checkedSubproductArray.push(index)
      } else if (!event.target.checked && checkedSubproductArray.indexOf(index) >= 0) {
        checkedSubproductArray.splice(checkedSubproductArray.indexOf(index), 1)
      }
    }

    if (showMore) {
      if (event.shiftKey) {
        for (let i = Math.min(lastCheckedSubProductIndex.current, parseInt(event.target.dataset.index)); i < Math.min(lastCheckedSubProductIndex.current, parseInt(event.target.dataset.index)) + 1 + Math.abs(lastCheckedSubProductIndex.current - parseInt(event.target.dataset.index)); i++) {
          modifyCheckedArrayByIndex(i)
        }
      } else {
        modifyCheckedArrayByIndex(parseInt(event.target.dataset.index))
      }
      setCheckedSubproductArray([...checkedSubproductArray])
      lastCheckedSubProductIndex.current = parseInt(event.target.dataset.index)
    }
  }

  const handleModifyClick = (event: any, key: string) => {
    setRealColumnsClickedValue(event.target.textContent)
    setRealColumnsInputValue(event.target.textContent)
    setRealColumnsModifyDialogShow(true)
    setClickedDialogKey(key)
    console.log(event, event.target.dataset.index)
  }

  const handleRealColumnInput = (event: any) => {
    setRealColumnsInputValue(event.target.value)
  }

  const handleRealColumnModifySubmit = async (event: any) => {
    let column_name = ''
    if (clickedDialogKey === 'real_pack') {
      column_name = '报关(袋)'
    }
    if (clickedDialogKey === 'real_carton') {
      column_name = '报关(箱)'
    }
    if (clickedDialogKey === 'real_number') {
      if (props.category == '海关') {
        column_name = '报关(个)'
      } else {
        column_name = '发货数量'
      }
    }
    if (clickedDialogKey === 'custom_carton') {
      column_name = '已到数量(箱)'
    }
    await modifySubProdct(clickedDialogKey, modifyClickedIndex.current, realColumnsInputValue, column_name, checkedSubproductArray)
    setRealColumnsModifyDialogShow(false)
  }

  const modifySubProdct = (key: string | number, subClickIndex: number, newValue: string | number, column_name: string, checkedArray: number[]) => {
    return new Promise(async (resolve) => {
      const dataModel = new CMSApi('orders' + '/' + props.order._id)
      let updateData: any = { $set: {} }
      let checkedName = [products[subClickIndex].name_zh ?? '']
      if (checkedArray.length > 0) {
        for (let i = 0; i < checkedArray.length; i++) {
          updateData.$set[`products.${checkedArray[i]}.${key}`] = newValue
          checkedName[i] = products[i].name_zh ?? ""
        }
      } else {
        updateData.$set[`products.${subClickIndex}.${key}`] = newValue
      }
      const updateRes: any = await dataModel.put(updateData)
      if (updateRes.data.modifiedCount == 1) {
        const logDataModel = new CMSApi('logs')
        logDataModel.post({
          table_name: 'Orders',
          document_id: props.order._id,
          column_key: 'products',
          column_name: checkedName.toString() + "\n" + column_name,
          old_value: products[subClickIndex][key] ?? '/',
          new_value: newValue,
          mainImage: '',
          log_user: (window as any).user_nickname ?? '无名称'
        })
        if (checkedArray.length > 0) {
          for (let i = 0; i < checkedArray.length; i++) {
            props.order.products[checkedArray[i]][key] = newValue
          }
        } else {
          props.order.products[subClickIndex][key] = newValue
        }
        resolve(true)
      }
    })
  }

  const handleExportClick = async () => {
    const workbook = new ExcelJS.Workbook()
    workbook.creator = 'opsloth-cms'
    workbook.created = new Date()
    workbook.modified = new Date()
    workbook.lastPrinted = new Date()
    workbook.properties.date1904 = true

    const sheet: any = workbook.addWorksheet(props.order.compamy)

    const columns: { name: string, width?: number, hidden?: boolean, totalsRowLabel?: string, totalsRowFunction?: string, fill?: any }[] = []
    headers.forEach((header, header_index) => {
      let widthTemp = 16
      if (headers_key[header_index] === 'name_jp') {
        widthTemp = 46
      } else if (headers_key[header_index] === 'order_number_pack' ||
        headers_key[header_index] === 'order_number_carton' ||
        headers_key[header_index] === 'pack_spec' ||
        headers_key[header_index] === 'carton_spec' ||
        headers_key[header_index] === 'order_price'
      ) {
        widthTemp = 12
      } else if (headers_key[header_index] === 'mainImage') {
        widthTemp = 14
      } else if (headers_key[header_index] === 'name_zh') {
        widthTemp = 30
      } else if (headers_key[header_index] === 'order') {
        widthTemp = 5
      }
      if (
        headers_key[header_index] === 'order_number_pack'
        || headers_key[header_index] === 'order_number_carton'
        || headers_key[header_index] === 'order_price'
      ) {
        columns.push({ name: header, width: widthTemp, totalsRowFunction: 'sum' })
      } else {
        columns.push({ name: header, width: widthTemp, totalsRowFunction: 'none' })
      }
    })

    const rows: (string | number | object)[][] = []
    let cargo_sent_cancled_count = 0
    // products.forEach((product: any, product_index: number) => {
    
    let productExportArray = products
    if (checkedSubproductArray.length > 0){
      productExportArray = []
      checkedSubproductArray.sort().forEach(index => {
        productExportArray.push(products[index])
      })
    }
    for (let i = 0; i < productExportArray.length; i++) {
      const product = productExportArray[i]
      if (product['cargo_sent'] != '已取消') {
        setExportPercentage(parseFloat(((i / productExportArray.length) * 100).toFixed(1)))
        const product_index = i - cargo_sent_cancled_count
        // const imgBase64 = await downloadImgs(product.mainImage.replace('http://', 'https://')) as string
        const imgBase64: any = await getBase64Image(getSmallerImgUrl(product.mainImage))
        const imageIdTemp = workbook.addImage({
          base64: imgBase64 ?? minimal_base64_img,
          extension: 'png'
        })
        sheet.getRow(product_index + 2).height = 120
        sheet.getRow(product_index + 2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        let row: (string | number | object)[] = []
        headers_key.forEach((header_item, cell_index: number) => {
          if (cell_index == 0) {
            row.push(product_index + 1)
          } else {
            if ((!isNaN(product[header_item]) && product[header_item] > 0) && (header_item === 'order_number_pack' || header_item === 'order_number_carton' || header_item === 'order_remark')) {
              sheet.getCell(`${ALPHABET[row.length]}${product_index + 2}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC5D9F1' } }
              sheet.getCell(`${ALPHABET[row.length]}${product_index + 2}`).border = { right: { style: 'thin', color: { argb: 'FFFFFFFF' } } };
            }
            if (header_item === 'order_price') {
              if (product.price_cny_cn === 0 && product.price_cny_jp === 0) {
                row.push('*价格')
              } else {
                if (isNaN(product[header_item])){
                  row.push("")
                }else{
                  row.push({ formula: `O${product_index + 2}*Max(H${product_index + 2}, I${product_index + 2})`, result: product[header_item] })
                }
                // row.push(product[header_item])
              }
            } else if (header_item === 'real_number') {
              if (product.real_number) {
                row.push(product[header_item])
              } else {
                // console.log(props.schema.category, product)
                if (props.schema.category.label == '海关') {
                  row.push(product.order_number_carton * product.carton_spec)
                } else {
                  let real_number = product.order_number_carton * product.carton_spec + product.order_number_pack * product.pack_spec
                  row.push(isNaN(real_number) ? "" : real_number)
                }
              }
            } else if (header_item === 'order_remark') {
              if (props.schema.category.label == '周边' || props.schema.category.label == '手办') {
                row.push(product[header_item])
              } else {
                row.push(product[header_item])
              }
            } else {
              row.push(product[header_item])
            }

          }
        })
        rows.push(row)
        sheet.addImage(imageIdTemp, {
          tl: { col: 1, row: product_index + 1 },
          br: { col: 2, row: product_index + 2 },
          hyperlinks: {
            hyperlink: product.mainImage,
            tooltip: product.mainImage
          }
        })
      } else {
        cargo_sent_cancled_count++
      }
    }
    sheet.getRow(productExportArray.length + 2).style = { font: { size: 16, color: { theme: 4 } }, alignment: { vertical: 'middle', horizontal: 'center' } }
    rows.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '物流费用', props.order.logistic_cny])
    let table = {
      name: `Sheet1`,
      ref: 'A1',
      headRow: false,
      totalsRow: true,
      style: {
        theme: 'TableStyleLight9',
        showRowStripes: true,
      },
      columns: columns,
      rows: rows,
    }
    sheet.columns = columns
    sheet.getRow(rows.length + 2).style = { font: { size: 16, color: { theme: 4 } }, alignment: { vertical: 'middle', horizontal: 'center' } }
    sheet.getCell(`A${rows.length + 2}`).style = { font: { size: 16, color: { theme: 4 } }, alignment: { vertical: 'middle', horizontal: 'left' } }
    sheet.getRow(1).height = 40
    sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' }
    sheet.getRow(1).border = { left: { style: 'thin', color: { argb: 'FFFFFFFF' } }, };
    sheet.addTable(table as any)

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: "application/vnd.ms-excel" })
    savefile(`回单-${props.order.company ?? ''}-${new Date().toLocaleString().replaceAll('/', '-')}.xlsx`, blob)
    setExportPercentage(0)
  }

  const getBase64Image = (imgUrl: string) => {
    return new Promise((resolve, reject) => {
      if (imgUrl && imgUrl != '/') {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = imgUrl
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx: any = canvas.getContext('2d');
          canvas.height = image.naturalHeight;
          canvas.width = image.naturalWidth;
          ctx.drawImage(image, 0, 0);
          const dataUrl = canvas.toDataURL();
          resolve(dataUrl)
        }
      } else {
        resolve(minimal_base64_img)
      }
    })
  }

  React.useEffect(() => {
    setShowMore(false)
    setProducts(props.order.products?.slice(0, 1))
  }, [props.order.products])

  React.useEffect(() => {
    if (!showMore) {
      setCheckedSubproductArray([])
    }
  }, [showMore])

  return (
    <TableContainer
      sx={{ overflowX: showMore ? 'scroll' : 'hidden' }}
    >
      <Table>
        {
          showMore &&
          <Box sx={{ display: 'flex', position: 'absolute' }}>
            <Box
              onClick={handleMoreClick}
              sx={{ ml: .5, px: '1em', py: '.2em', height: '1.5em', lineHeight: '1.5em', background: '#0d47a166', cursor: 'pointer', color: 'white', fontWeight: 'bold', fontSize: '1em', textAlign: 'center', position: 'relative' }}
            >收起</Box>
            <Box
              onClick={handleExportClick}
              sx={{ ml: '2em', height: '1.5em', px: '1em', py: '.2em', lineHeight: '1.5em', background: '#0d47a166', cursor: 'pointer', color: 'white', fontWeight: 'bold', fontSize: '1em', textAlign: 'center', position: 'relative' }}
            >导出订单{checkedSubproductArray.length > 0 ? ' 选中 ' + checkedSubproductArray.length + ' 个': ''}</Box>
          </Box>
        }
        <TableHead>
          <TableRow>
            {
              props.headers?.map((value: string, value_index: number) => {
                return <TableCell
                  key={value_index}
                  sx={{
                    fontSize: '.4em',
                    minWidth: '1em',
                    maxWidth: '20em',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    borderLeft: 'solid 1px #ccc',
                    borderTop: 'solid 1px #ccc',
                    background: '#DCE6F1',
                  }}
                  size="small"
                >{value as ReactNode}</TableCell>
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>

          {
            products?.map((row: any, row_index: number) => (
              <TableRow
                key={`row_${row_index}`}
                sx={{ background: props.checkboxSelected?.indexOf(row.product_id) >= 0 ? '#feff6b' : 'none' }}
              >
                {
                  props.headers_key.map((key: any, value_index: number) => {
                    let value = undefined
                    let price = undefined
                    if (key === 'order') {
                      value = row_index + 1
                    } else if (key === 'cargo_sent' || key === 'real_status' || key === 'custom_carton') {
                      value = row[key] === '/' || row[key] == undefined || !row[key] ? '/' : row[key]
                    } else {
                      value = row[key]
                    }
                    return <TableCell
                      key={value_index}
                      data-index={row_index}
                      onClick={(event) => {
                        modifyClickedIndex.current = row_index
                        if ((key === 'cargo_sent' || key === 'real_status') && showMore) {
                          handleChooseItemClick(event, key)
                        }
                        if ((key === 'product_code' || key === 'real_pack' || key === 'real_carton' || key === 'real_number' || key === 'order_remark' || key === 'custom_carton') && showMore) {
                          handleModifyClick(event, key)
                        }
                      }}
                      sx={{
                        px: 1,
                        cursor: (key === 'product_code' || key === 'cargo_sent' || key === 'real_pack' || key === 'real_carton' || key === 'real_number' || key === 'real_status' || key === 'order_remark' || key === 'custom_carton') ? 'pointer' : 'inherit',
                        textAlign: 'center',
                        fontSize: '.4em',
                        minWidth: '1em',
                        maxWidth: '20em',
                        maxHeight: '3em',
                        overflow: 'hidden',
                        whiteSpace: 'pre-wrap',
                        borderLeft: 'solid 1px #ccc',
                        borderRight: 'solid 1px #ccc',
                        background: (value > 0 && (key === 'order_number_pack' || key === 'order_number_carton' || key === 'order_remark'))
                          ? row['product_id'] ? '#DAEEF3' : '#91dbee'
                          : row['cargo_sent'] == '已取消' ? '#ffd2cb' : 'none'
                            && (checkedSubproductArray.indexOf(row_index) >= 0 ? '#0d47a120' : 'none')
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {
                          key === 'mainImage'
                            ?
                            <img
                              src={getSmallerImgUrl(value, 64)}
                              style={{ width: '6em', height: '8em', objectFit: 'cover', transformOrigin: 'left center' }}
                            />
                            :
                            value as ReactNode
                        }
                        {
                          value_index === 0 && <Checkbox inputProps={{ 'data-index': row_index } as any} sx={{ p: 0, cursor: 'pointer' }} onClick={handleOrderChecked} checked={checkedSubproductArray.indexOf(row_index) >= 0} color="primary" />
                        }
                      </Box>
                    </TableCell>
                  })
                }
                {/* {
                  <TableCell>{'price'}</TableCell>
                } */}
              </TableRow>
            ))
          }
          <TableRow>
            {/* {
              props.headers.map((value: string, value_index: number) =>
              <TableCell>{'last row'}</TableCell>
              )
              
            }
            {
              <TableCell>{'total price'}</TableCell>
            } */}
          </TableRow>

        </TableBody>
      </Table>
      {
        !showMore && <Box
          sx={{ width: '100%', mt: -9, cursor: 'pointer', clipPath: 'ellipse(60% 100% at 50% 115%)' }}
          onClick={handleMoreClick}
        >
          <Paper
            sx={{ p: 3, background: '#0d47a166', userSelect: 'none', color: 'white', fontWeight: 'bold', fontSize: '1.1em', textAlign: 'center', position: 'relative' }}
          >查看全部订单内容</Paper>
        </Box>
      }
      {
        <Dialog
          fullWidth
          open={chooseDialogShow}
          onClose={() => { setChooseDialogShow(false) }}
        >
          <DialogTitle>{checkedSubproductArray.length > 0 ? `批量修改${checkedSubproductArray.length}个` : ''}</DialogTitle>
          <Container
            sx={{ px: 3, pb: 3 }}
          >
            <TextField
              // onWheel={handleOnWheel}
              fullWidth
              name={clickedDialogKey === 'cargo_sent' ? '发货状态' : '报关进度'}
              type='select'
              select={true}
              label={clickedDialogKey === 'cargo_sent' ? '发货状态' : '报关进度'}
              defaultValue={chooseItemClickedValue}
              onChange={handleChooseItemChange}
            >
              {
                chooseDialogItems.map((item) =>
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                )
              }
            </TextField>
          </Container>
        </Dialog>
      }{
        <Dialog
          fullWidth
          open={realColumnsModifyDialogShow}
          onClose={() => { setRealColumnsModifyDialogShow(false) }}
        >
          <DialogTitle>{checkedSubproductArray.length > 0 ? `批量修改${checkedSubproductArray.length}个` : ''}</DialogTitle>
          <Container
            sx={{ px: 3, pb: 3 }}
          >
            <TextField
              // onWheel={handleOnWheel}
              fullWidth
              onInput={handleRealColumnInput}
              name={props.schema.products?.subSchema[clickedDialogKey]?.label}
              type={clickedDialogKey === 'real_pack' || clickedDialogKey === 'real_carton' || clickedDialogKey === 'real_number' || clickedDialogKey === 'custom_carton' ? 'number' : 'text'}
              defaultValue={realColumnsClickedValue}
              label={props.schema.products?.subSchema[clickedDialogKey]?.label}
            >
            </TextField>
          </Container>
          <DialogActions>
            <Button
              sx={{ m: 1 }}
              onClick={() => setRealColumnsModifyDialogShow(false)}
            >
              {"取消"}
            </Button>

            <Button
              onClick={handleRealColumnModifySubmit}
            >
              {"提交"}
            </Button>
          </DialogActions>
        </Dialog>
      }
      <Backdrop
        sx={{ background: '#00000044', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={exportPercentage > 0}
      >
        <Box sx={{ background: '#fff', display: 'flex', borderRadius: '100%' }}>
          <CircularProgressWithLabel value={exportPercentage} />
        </Box>
      </Backdrop>
    </TableContainer>
  )
}

export default OrderTableArray