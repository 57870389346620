import React from 'react'
import { Backdrop, Box, Button, Card, Chip, Fab, Typography } from "@mui/material"
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ListIcon from '@mui/icons-material/List';
import OrderAddDialog from './OrderAddDialog';
import ExcelJS from 'exceljs'
import CMSApi from '../../utils/opsloth_cms_service_api'
import OrderTableArray from './OrderTableArray';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import savefile from '../../utils/savefile';
import DialogTextfields from '../public/DialogTextfields';
import LogsDialog from '../public/LogsDialog';
import OrdersExportDialog from './OrdersExportDialog';
import axios from 'axios';
import getSmallerImgUrl from '../../utils/getSmallerImgUrl';
import CircularProgressWithLabel from '../public/CircularProgressWithLabel';
import { Category } from '@mui/icons-material';
const ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const minimal_base64_img = 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

function OrdersTableList(props: any) {
  let headers = ['序号', '例图', '品牌', '货号', '日文名称', '中文名称', '日元售价', '代理版(元/个)', '日版(元/个)', '每袋数量', '每箱数量', '订购(袋)', '订购(箱)', '备注', '发货数量', '金额', '发货状态']
  let headers_key = ['order', 'mainImage', 'brand', 'product_code', 'name_jp', 'name_zh', 'price_jpy', 'price_cny_cn', 'price_cny_jp', 'pack_spec', 'carton_spec', 'order_number_pack', 'order_number_carton', 'order_remark', 'real_number', 'order_price', 'cargo_sent']
  let order_headers = ['订单金额￥', '已付定金￥', '物流费用￥', '已付尾款￥', '物流状态', '物流单号', '订单状态', '订单备注', '创建者', '创建时间']
  let order_headers_key = ['price_cny', 'deposit_cny', 'logistic_cny', 'balance_cny', 'cargo_sent', 'cargo_no', 'order_status', 'remark', 'nickname', 'createdAt']

  if (props.category == '周边' || props.category == '手办') {
    headers = ['序号', '例图', '品牌', '货号', '日文名称', '中文名称', '日元售价', '代理版(元/个)', '日版(元/个)', '每盒数量', '每箱数量', '订购(盒)', '订购(箱)', '备注', '发货数量', '金额', '发货状态']
  }

  if (props.category == '海关') {
    headers = ['序号', '例图', '品牌', '货号', '日文名称', '中文名称', '日元售价', '每箱数量', '订购(箱)', '已到数量', '备注']
    headers_key = ['order', 'mainImage', 'brand', 'product_code', 'name_jp', 'name_zh', 'price_jpy', 'carton_spec', 'order_number_carton', 'custom_carton', 'order_remark']
    order_headers = ['物流状态', '物流单号', '订单状态', '订单备注', '创建者', '创建时间']
    order_headers_key = ['cargo_sent', 'cargo_no', 'order_status', 'remark', 'nickname', 'createdAt']
  }


  const [orderAddDialogOpen, setOrderAddDialogOpen] = React.useState(false)
  const [customer, setCustomer] = React.useState<{ _id: string, company: string, customer_types: [] | undefined } | null>(null)

  const [modifyDialogOpen, setModifyDialogOpen] = React.useState(false)
  const [modifyId, setModifyId] = React.useState('')
  const [modifyColumn, setModifyColumn] = React.useState('')
  const [modifyColumnValue, setModifyColumnValue] = React.useState<string | number>()
  const [logsDialogOpen, setLogsDialogOpen] = React.useState(false)
  const [documentLogsId, setDocumentLogsId] = React.useState('')

  const [ordersExportDialogShow, setOrdersExportDialogShow] = React.useState(false)

  const [exportPercentage, setExportPercentage] = React.useState(0)

  const onModifySubmit = async (returnData: any) => {
    if (returnData[modifyColumn] == modifyColumnValue) {
      props.newAlert('数据无变化，取消提交', 'info')
    } else {
      if ((modifyColumn === 'deposit_cny' || modifyColumn === 'balance_cny') && !returnData.mainImage) {
        props.newAlert('未选择图片', 'error')
        return
      }
      const dataModel = new CMSApi(props.schema.TABLE.name + '/' + modifyId)
      const res: any = await dataModel.put(returnData)
      if (res?.data?.modifiedCount === 1) {
        props.newAlert("修改数据成功")
        setModifyDialogOpen(false)
        //TODO update part of table
        props.getttData()
        const logDataModel = new CMSApi('logs')
        logDataModel.post({
          table_name: props.schema.TABLE.name,
          document_id: modifyId,
          column_key: modifyColumn,
          column_name: props.schema[modifyColumn].label,
          old_value: modifyColumnValue,
          new_value: returnData[modifyColumn],
          mainImage: returnData.mainImage,
          log_user: (window as any).user_nickname ?? '无名称'
        })
      }
    }
  }

  const onXLSXSubmit = (dropedWorkbook: ExcelJS.Workbook) => {
    uploadOrder(changeXLSXToOrders(dropedWorkbook))
    // changeXLSXToOrders(dropedWorkbook)
  }

  const changeXLSXToOrders = (dropedWorkbook: ExcelJS.Workbook) => {
    function calcOrderPriceCNY(product: any) {
      let order_price_cny = 0
      if (props.schema.category.label == '海关') {
        product.order_number_pack = 0
      }
      if (!product.customer_type_carton_price_cny && !product.customer_type_pack_price_cny && !product.customer_type_single_price_cny) {
        order_price_cny = Math.round(Math.max(returnNumber(product.price_cny_cn), returnNumber(product.price_cny_jp)) * 100)
          * (
            returnNumber(product.pack_spec)
            * returnNumber(product.order_number_pack)
            + returnNumber(product.carton_spec)
            * returnNumber(product.order_number_carton)
          )
      } else {
        if (Math.max(product.customer_type_carton_price_cny, product.customer_type_pack_price_cny, product.customer_type_single_price_cny) == product.customer_type_single_price_cny) {
          order_price_cny = product.customer_type_single_price_cny * 100 * (product.pack_spec * product.order_number_pack + product.carton_spec * product.order_number_carton)
        } else {
          if (product.customer_type_carton_price_cny > 0 && product.customer_type_pack_price_cny > 0) {
            order_price_cny = product.customer_type_carton_price_cny * 100 * product.order_number_carton + product.customer_type_pack_price_cny * product.order_number_pack
          } else {
            if (product.customer_type_carton_price_cny > 0) {
              order_price_cny = product.customer_type_carton_price_cny * 100 * product.order_number_carton + Math.max(returnNumber(product.price_cny_cn), returnNumber(product.price_cny_jp)) * product.pack_spec * product.order_number_pack
            } else if (product.customer_type_pack_price_cny > 0) {
              order_price_cny = product.customer_type_pack_price_cny * 100 * product.order_number_pack + Math.max(returnNumber(product.price_cny_cn), returnNumber(product.price_cny_jp)) * product.carton_spec * product.order_number_carton
            }
          }
        }
      }
      return order_price_cny / 100
    }

    function calcCustomerType(customer_types_by_brands: any, brand: string) {
      return (customer_types_by_brands[brand]?.single_price_cny > 0 ? customer_types_by_brands[brand]?.single_price_cny + '（元/个）;' : '')
        + (customer_types_by_brands[brand]?.pack_price_cny > 0 ? customer_types_by_brands[brand]?.pack_price_cny + '（元/袋）;' : '')
        + (customer_types_by_brands[brand]?.carton_price_cny > 0 ? customer_types_by_brands[brand]?.carton_price_cny + '（元/箱）;' : '')
    }

    //get customerTypes
    let customer_types_by_brands: any = {}
    customer?.customer_types?.forEach((type: any) => {
      customer_types_by_brands[type.brand] = {}
      customer_types_by_brands[type.brand].single_price_cny = type.single_price_cny
      customer_types_by_brands[type.brand].pack_price_cny = type.pack_price_cny
      customer_types_by_brands[type.brand].carton_price_cny = type.carton_price_cny
    })
    console.log(customer_types_by_brands)
    //analize XLSX
    let order: any = {}
    order.customer_id = customer?._id
    order.company = customer?.company
    order.products = []
    order.price_cny = 0
    order.deposit_cny = 0
    order.balance_cny = 0
    order.logistic_cny = 0
    order.cargo_sent = '未发货'
    order.cargo_no = ''
    order.order_status = '创建订单'
    order.nickname = (window as any).user_nickname ?? '无名称'
    dropedWorkbook.worksheets.forEach(worksheet => {
      worksheet.getRows(2, worksheet.rowCount - 1)?.forEach((row) => {

        let rowValueTemp: any = new Array(17).fill(undefined);

        (row.values as any).forEach((value: any, valueIndex: number) => {
          let valueTemp: string = '';
          if (value && (value as any).richText) {
            (value as any).richText.forEach((text: { text: string }) => {
              valueTemp += text.text
            })
          } else {
            (valueTemp as any) = value
          }
          rowValueTemp[valueIndex] = (valueTemp)
        })

        if (((rowValueTemp as any)[15] ?? 0 > 0) || ((rowValueTemp as any)[16] ?? 0 > 0)) {
          order.products.push({
            product_id: (rowValueTemp as any)[1],
            launch_date: (rowValueTemp as any)[2],
            arrived: (rowValueTemp as any)[3],
            mainImage: (rowValueTemp as any)[5],
            brand: (rowValueTemp as any)[6],
            product_code: (rowValueTemp as any)[7],
            name_jp: (rowValueTemp as any)[8],
            name_zh: (rowValueTemp as any)[9],
            price_jpy: returnNumber((rowValueTemp as any)[10]),
            price_cny_cn: returnNumber((rowValueTemp as any)[11]),
            price_cny_jp: returnNumber((rowValueTemp as any)[12]),
            pack_spec: returnNumber((rowValueTemp as any)[13]),
            carton_spec: returnNumber((rowValueTemp as any)[14]),
            order_number_pack: returnNumber((rowValueTemp as any)[15] ?? 0),
            order_number_carton: returnNumber((rowValueTemp as any)[16] ?? 0),
            order_remark: calcCustomerType(customer_types_by_brands, (rowValueTemp as any)[6]) + ((rowValueTemp as any)[17] ? (rowValueTemp as any)[17] : ''),
            order_price: 0,//calc later
            customer_type_single_price_cny: customer_types_by_brands[(rowValueTemp as any)[6]]?.single_price_cny ?? 0,
            customer_type_pack_price_cny: customer_types_by_brands[(rowValueTemp as any)[6]]?.pack_price_cny ?? 0,
            customer_type_carton_price_cny: customer_types_by_brands[(rowValueTemp as any)[6]]?.carton_price_cny ?? 0
          })
          order.products[order.products.length - 1].order_price = calcOrderPriceCNY(order.products[order.products.length - 1])

          order.price_cny += order.products[order.products.length - 1].order_price
        }
      })
    })
    console.log(order)
    return order
  }

  const uploadOrder = async (order: any) => {
    const Orders = new CMSApi('orders')
    order.category = props.category
    await Orders.post(order)
    setOrderAddDialogOpen(false)
    props.getttData()
  }

  const returnNumber = (value: any) => {
    if (value.result) {
      return Number.isNaN(parseFloat((value.result))) ? 0 : parseFloat((value.result))
    } else {
      return Number.isNaN(parseFloat((value))) ? 0 : parseFloat((value))
    }
  }

  const downloadImgs = (imgUrl: string) => {
    return new Promise(async (resolve) => {
      if (imgUrl && imgUrl != '/') {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = imgUrl
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx: any = canvas.getContext('2d');
          canvas.height = image.naturalHeight;
          canvas.width = image.naturalWidth;
          ctx.drawImage(image, 0, 0);
          const dataUrl = canvas.toDataURL();
          resolve(dataUrl)
        }
      } else {
        resolve(minimal_base64_img)
      }
    })
  }
  const getBase64Image = (imgUrl: string) => {
    return new Promise((resolve, reject) => {
      if (imgUrl && imgUrl != '/') {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = imgUrl
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx: any = canvas.getContext('2d');
          canvas.height = image.naturalHeight;
          canvas.width = image.naturalWidth;
          ctx.drawImage(image, 0, 0);
          const dataUrl = canvas.toDataURL();
          resolve(dataUrl)
        }
      } else {
        resolve(minimal_base64_img)
      }
    })
  }
  // const handleExportClick = (orderIndex: number, order: any) => async () => {
  //   const workbook = new ExcelJS.Workbook()
  //   workbook.creator = 'opsloth-cms'
  //   workbook.created = new Date()
  //   workbook.modified = new Date()
  //   workbook.lastPrinted = new Date()
  //   workbook.properties.date1904 = true

  //   const sheet: any = workbook.addWorksheet(props.array[orderIndex].company)

  //   const columns: { name: string, width?: number, hidden?: boolean, totalsRowLabel?: string, totalsRowFunction?: string, fill?: any }[] = []
  //   headers.forEach((header, header_index) => {
  //     let widthTemp = 16
  //     if (headers_key[header_index] === 'name_jp') {
  //       widthTemp = 46
  //     } else if (headers_key[header_index] === 'order_number_pack' ||
  //       headers_key[header_index] === 'order_number_carton' ||
  //       headers_key[header_index] === 'pack_spec' ||
  //       headers_key[header_index] === 'carton_spec' ||
  //       headers_key[header_index] === 'order_price'
  //     ) {
  //       widthTemp = 12
  //     } else if (headers_key[header_index] === 'mainImage') {
  //       widthTemp = 14
  //     } else if (headers_key[header_index] === 'name_zh') {
  //       widthTemp = 30
  //     } else if (headers_key[header_index] === 'order') {
  //       widthTemp = 5
  //     }
  //     if (
  //       headers_key[header_index] === 'order_number_pack'
  //       || headers_key[header_index] === 'order_number_carton'
  //       || headers_key[header_index] === 'order_price'
  //     ) {
  //       columns.push({ name: header, width: widthTemp, totalsRowFunction: 'sum' })
  //     } else {
  //       columns.push({ name: header, width: widthTemp, totalsRowFunction: 'none' })
  //     }
  //   })

  //   const rows: (string | number | object)[][] = []
  //   let cargo_sent_cancled_count = 0
  //   // props.array[orderIndex].products.forEach((product: any, product_index: number) => {
  //   for (let i = 0; i < props.array[orderIndex].products.length; i++) {
  //     const product = props.array[orderIndex].products[i]
  //     if (product['cargo_sent'] != '已取消') {
  //       setExportPercentage(parseFloat(((i / props.array[orderIndex].products.length) * 100).toFixed(1)))
  //       const product_index = i - cargo_sent_cancled_count
  //       // const imgBase64 = await downloadImgs(product.mainImage.replace('http://', 'https://')) as string
  //       const imgBase64: any = await getBase64Image(getSmallerImgUrl(product.mainImage))
  //       const imageIdTemp = workbook.addImage({
  //         base64: imgBase64 ?? minimal_base64_img,
  //         extension: 'png'
  //       })
  //       sheet.getRow(product_index + 2).height = 120
  //       sheet.getRow(product_index + 2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  //       let row: (string | number | object)[] = []
  //       headers_key.forEach((header_item, cell_index: number) => {
  //         if (cell_index == 0) {
  //           row.push(product_index + 1)
  //         } else {
  //           if ((!isNaN(product[header_item]) && product[header_item] > 0) && (header_item === 'order_number_pack' || header_item === 'order_number_carton' || header_item === 'order_remark')) {
  //             sheet.getCell(`${ALPHABET[row.length]}${product_index + 2}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC5D9F1' } }
  //             sheet.getCell(`${ALPHABET[row.length]}${product_index + 2}`).border = { right: { style: 'thin', color: { argb: 'FFFFFFFF' } } };
  //           }
  //           if (header_item === 'order_price') {
  //             if (product.price_cny_cn === 0 && product.price_cny_jp === 0) {
  //               row.push('*价格')
  //             } else {
  //               row.push({ formula: `O${product_index + 2}*Max(H${product_index + 2}, I${product_index + 2})`, result: product[header_item] })
  //               // row.push(product[header_item])
  //             }
  //           } else if (header_item === 'real_number') {
  //             if (product.real_number) {
  //               row.push(product[header_item])
  //             } else {
  //               // console.log(props.schema.category, product)
  //               if (props.schema.category.label == '海关') {
  //                 row.push(product.order_number_carton * product.carton_spec)
  //               } else {
  //                 row.push(product.order_number_carton * product.carton_spec + product.order_number_pack * product.pack_spec)
  //               }
  //             }
  //           } else if (header_item === 'order_remark') {
  //             if (props.schema.category.label == '周边' || props.schema.category.label == '手办') {
  //               row.push(product[header_item])
  //             } else {
  //               row.push(product[header_item])
  //             }
  //           } else {
  //             row.push(product[header_item])
  //           }

  //         }
  //       })
  //       rows.push(row)
  //       sheet.addImage(imageIdTemp, {
  //         tl: { col: 1, row: product_index + 1 },
  //         br: { col: 2, row: product_index + 2 },
  //         hyperlinks: {
  //           hyperlink: product.mainImage,
  //           tooltip: product.mainImage
  //         }
  //       })
  //     } else {
  //       cargo_sent_cancled_count++
  //     }
  //   }
  //   sheet.getRow(props.array[orderIndex].products.length + 2).style = { font: { size: 16, color: { theme: 4 } }, alignment: { vertical: 'middle', horizontal: 'center' } }
  //   rows.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '物流费用', order.logistic_cny])
  //   let table = {
  //     name: `Sheet1`,
  //     ref: 'A1',
  //     headRow: false,
  //     totalsRow: true,
  //     style: {
  //       theme: 'TableStyleLight9',
  //       showRowStripes: true,
  //     },
  //     columns: columns,
  //     rows: rows,
  //   }
  //   sheet.columns = columns
  //   sheet.getRow(rows.length + 2).style = { font: { size: 16, color: { theme: 4 } }, alignment: { vertical: 'middle', horizontal: 'center' } }
  //   sheet.getCell(`A${rows.length + 2}`).style = { font: { size: 16, color: { theme: 4 } }, alignment: { vertical: 'middle', horizontal: 'left' } }
  //   sheet.getRow(1).height = 40
  //   sheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' }
  //   sheet.getRow(1).border = { left: { style: 'thin', color: { argb: 'FFFFFFFF' } }, };
  //   sheet.addTable(table as any)

  //   const buffer = await workbook.xlsx.writeBuffer()
  //   const blob = new Blob([buffer], { type: "application/vnd.ms-excel" })
  //   savefile(`回单-${props.array[orderIndex].company}-${new Date().toLocaleString().replaceAll('/', '-')}.xlsx`, blob)
  //   setExportPercentage(0)
  // }

  const handleLogsClick = (_id: string) => async () => {
    setDocumentLogsId(_id)
    setLogsDialogOpen(true)
  }

  const handleDeleteClick = (_id: string) => async () => {
    if (window.confirm('作废该订单？')) {
      const dataModel = new CMSApi(props.schema.TABLE.name + '/' + _id)
      const res: any = await dataModel.put({ deleted: true })


      if (res?.data?.modifiedCount === 1) {
        props.newAlert("作废订单成功！")
        const logDataModel = new CMSApi('logs')
        logDataModel.post({
          table_name: props.schema.TABLE.name,
          document_id: _id,
          column_key: 'deleted',
          column_name: '是否已作废',
          old_value: '未作废',
          new_value: '已作废',
          log_user: (window as any).user_nickname ?? '无名称'
        })
        //TODO update part of table
        props.getttData()
      }
    }

  }

  const handleModifyClick = (order_header_key: string, modifyColumnValue: any, _id: string) => () => {
    setModifyColumn(order_header_key)
    setModifyColumnValue(modifyColumnValue ?? '')
    setModifyId(_id)
    setModifyDialogOpen(true)
  }

  const handleExportOrdersClick = () => {
    setOrdersExportDialogShow(true)
  }

  return (
    <Box>
      {
        props.array.length >= 1 && props.array.map(
          (order: any, orderIndex: number) =>
            <Card
              key={orderIndex}
              sx={{ px: 3, py: 1, my: 2, }}
            >
              <Chip
                label={order.deleted ? '已作废 ' + order.company : order.company}
                color={order.deleted ? 'warning' : (order.order_status === '订单已完成' ? 'success' : 'primary')}
                size='small'
                sx={{ mb: -1, ml: -1.5, userSelect: 'text' }}
              />
              <OrderTableArray
                schema={props.schema}
                order={order}
                headers={headers}
                headers_key={headers_key}
              ></OrderTableArray>

              <Grid2
                container
                spacing={.25}
                sx={{ maxWidth: '60%', my: 2 }}
              >
                {order_headers.map((header, header_index) =>
                  <Grid2
                    key={`${orderIndex}_${header_index}`}
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                  >
                    <Typography
                      onClick={handleModifyClick(order_headers_key[header_index], order[order_headers_key[header_index]], order._id)}
                      sx={{ width: 'fit-content', whiteSpace: 'nowrap', cursor: 'pointer', overflow: 'auto' }}
                    >{`${header}: ${order_headers_key[header_index] === 'createdAt'
                      ? new Date(order[order_headers_key[header_index]]).toLocaleString()
                      : (
                        ((order_headers_key[header_index] === 'deposit_cny' || order_headers_key[header_index] === 'price_cny' || order_headers_key[header_index] === 'balance_cny')
                          ? order[order_headers_key[header_index]].toFixed(2)
                          : order[order_headers_key[header_index]])
                        ?? '')
                      }`}</Typography>
                  </Grid2>
                )}
              </Grid2>
              <Grid2
                container
                spacing={2}
                justifyContent='flex-end'
              >
                <Grid2
                >
                  {/* {props.schema.category.label != '海关' && <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleExportClick(orderIndex, order)}
                  >导出订单</Button>} */}
                </Grid2>
                <Grid2
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleLogsClick(order._id)}
                  >
                    查看日志
                  </Button>
                </Grid2>
                <Grid2
                >
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleDeleteClick(order._id)}
                  >
                    作废订单
                  </Button>
                </Grid2>
              </Grid2>
            </Card>
        )
      }

      <Box
        sx={{ position: 'fixed', right: 20, bottom: 50 }}
      >
        {
          <Fab
            sx={{ zIndex: 1000, marginLeft: -1 }}
            color='warning'
            size='medium'
            onClick={() => { setOrderAddDialogOpen(true) }}
          >
            <PlaylistAddIcon></PlaylistAddIcon>
          </Fab >
        }
        {
          <Fab
            sx={{ zIndex: 900, marginLeft: -1 }}
            color='primary'
            size='medium'
            onClick={() => { handleExportOrdersClick() }}
          >
            <ListIcon></ListIcon>
          </Fab >
        }
      </Box>

      <OrderAddDialog
        newAlert={props.newAlert}
        open={orderAddDialogOpen}
        onClose={setOrderAddDialogOpen}
        onSubmit={onXLSXSubmit}
        category={props.category}
        customer={customer}
        setCustomer={setCustomer}
      ></OrderAddDialog>

      <DialogTextfields
        schema={props.schema}
        open={modifyDialogOpen}
        setOpen={setModifyDialogOpen}
        title={`修改${props.schema.TABLE.label}`}
        modifyOrAdd='modify'
        modifyColumn={modifyColumn}
        modifyColumnValue={modifyColumnValue}
        setModifyColumnValue={setModifyColumnValue}
        onSubmit={onModifySubmit}
      >

      </DialogTextfields>
      <LogsDialog
        _id={documentLogsId}
        open={logsDialogOpen}
        setOpen={setLogsDialogOpen}
      ></LogsDialog>

      <OrdersExportDialog
        open={ordersExportDialogShow}
        setOpen={setOrdersExportDialogShow}
        schema={props.schema}
        newAlert={props.newAlert}
        category={props.category}
      ></OrdersExportDialog>
      <Backdrop
        sx={{ background: '#00000044', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={exportPercentage > 0}
      >
        <Box sx={{ background: '#fff', display: 'flex', borderRadius: '100%' }}>
          <CircularProgressWithLabel value={exportPercentage} />
        </Box>
      </Backdrop>
    </Box >
  )
}

export default OrdersTableList
