import React from 'react';
import { Box, Container, Toolbar, Button, Card, Alert, Slide } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Grid2 from '@mui/material/Unstable_Grid2'
import LeftDrawer from './components/public/LeftDrawer'
import Appbar from './components/public/AppBar'
import Login from './pages/Login'
import Customers from './pages/Customers'
import Products from './pages/Products'
import Orders from './pages/Orders'
import customerTheme from './utils/customerTheme'
import Logistics from './pages/Logistics';
import Brands from './pages/Brands';
import Welcome from './pages/Welcome';
import CustomerTypes from './pages/CustomerTypes';
import ProductTags from './pages/ProductTags';
import { useCookies } from 'react-cookie'
import Products_2 from './pages/Products_2';
import Products_3 from './pages/Products_3';
import Dealers from './pages/Dealers';
import CustomCustomers from './pages/CustomCustomers';
import Products_2_2 from './pages/Products_2_2';
import Jobs from './pages/Jobs';

function App() {
  const [tabs, setTabs] = React.useState<string[]>([])
  const [currentTab, setCurrentTab] = React.useState<string>(tabs[0])
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [logined, setLogined] = React.useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(['']);
  const [alertInfo, setAlertInfo] = React.useState({ show: false, severty: 'success', info: '' })
  const newAlert = (info: string, severty?: 'error' | 'success' | 'info' | 'warning', consistent?: boolean) => {
    const hideAlert = () => {
      if ((alertInfo.severty === 'success' || alertInfo.severty === 'info' || alertInfo.severty === 'warning') && !consistent) {
        setTimeout(() => {
          alertInfo.show = false
          setAlertInfo(Object.assign({}, alertInfo))
        }, 1500);
      }
    }

    if (consistent === false) {
      alertInfo.show = false
      setAlertInfo(Object.assign({}, alertInfo))
    } else {
      if (alertInfo.show === false) {
        alertInfo.info = info
        alertInfo.severty = severty ? severty : 'success'
        alertInfo.show = true
        setAlertInfo(Object.assign({}, alertInfo))
        hideAlert()
      } else {
        if (!consistent) {
          alertInfo.show = false
        }
        setAlertInfo(Object.assign({}, alertInfo))
        setTimeout(() => {
          alertInfo.info = info
          alertInfo.severty = severty ? severty : 'success'
          alertInfo.show = true
          setAlertInfo(Object.assign({}, alertInfo))
          hideAlert()
        }, consistent ? 0 : 200);
      }
    }
  }

  React.useEffect(() => {
    if (logined) {
      let tabsTemp = (window as any).user_level == 2 ? ['订单列表（海关）', '客户列表（海关）', '退出登录'] : (window as any).user_level ? ['订单统计', '商品列表（扭蛋）', '商品列表（周边）', '商品列表（周边-现货）', '商品列表（手办）', '工单列表', '订单列表（扭蛋）', '订单列表（周边）', '订单列表（周边-现货）', '订单列表（手办）', '订单列表（海关）', '客户列表', '客户分类', '客户列表（海关）', '经销分类', '快递列表', '品牌列表', '标签列表', '退出登录'] : []
      setTabs(tabsTemp)
      setCurrentTab(tabsTemp[0])
    }
  }, [logined])

  return (
    <ThemeProvider theme={customerTheme}>
      <Appbar
        toggleDrawer={() => setDrawerOpen(!drawerOpen)}
        currentTab={currentTab}
      >
      </Appbar>
      <LeftDrawer
        open={drawerOpen}
        tabs={tabs}
        onClose={() => setDrawerOpen(false)}
        onChosen={(index: number) =>
          () => {
            if (tabs[index] == '退出登录') {
              removeCookie("JWT" as any)
              setLogined(false)
              setCurrentTab(tabs[0])
              setDrawerOpen(false)
              alertInfo.show = false;
              (window as any).user_level = undefined
            } else {
              setCurrentTab(tabs[index])
              setDrawerOpen(false)
              alertInfo.show = false
            }
          }
        }
      />
      {
        <Box
          sx={{ height: 0, width: '100%', maxWidth: '100%', position: "fixed", zIndex: 2100, top: 0, display: 'flex', justifyContent: 'center' }}
        >
          <Slide
            in={alertInfo.show}
          >
            <Alert
              sx={{ height: '40px' }}
              severity={alertInfo.severty as any}
              onClose={alertInfo.severty === 'success' ? undefined : () => {
                alertInfo.show = !alertInfo.show
                setAlertInfo(Object.assign({}, alertInfo))
              }}
            >
              {alertInfo.info}
            </Alert>
          </Slide >
        </Box>
      }
      <Box>
        <Toolbar />
        <Container
          maxWidth='sm'
        >
          {!logined &&
            <Login logined={logined} setLogined={setLogined}></Login>
          }
        </Container>

        <Grid2
          container
        >
          <Grid2
            xs={12}
            sx={{ m: 2 }}
          >
            <Box
            >
              {
                logined && currentTab === '订单统计' &&
                <Welcome
                  newAlert={newAlert}
                ></Welcome>
              }

              {
                logined && currentTab === '商品列表（扭蛋）' &&
                <Products
                  newAlert={newAlert}
                ></Products>
              }

              {
                logined && currentTab === '商品列表（周边）' &&
                <Products_2
                  newAlert={newAlert}
                ></Products_2>
              }

              {
                logined && currentTab === '商品列表（周边-现货）' &&
                <Products_2_2
                  newAlert={newAlert}
                ></Products_2_2>
              }

              {
                logined && currentTab === '商品列表（手办）' &&
                <Products_3
                  newAlert={newAlert}
                ></Products_3>
              }

              {
                logined && currentTab === '商品列表（海关）' &&
                <Products_3
                  newAlert={newAlert}
                ></Products_3>
              }

              {
                logined && currentTab === '工单列表' &&
                <Jobs
                  newAlert={newAlert}
                ></Jobs>
              }

              {
                logined && currentTab === '客户列表' &&
                <Customers
                  newAlert={newAlert}
                ></Customers>
              }
              {
                logined && currentTab === '客户分类' &&
                <CustomerTypes
                  newAlert={newAlert}
                ></CustomerTypes>
              }
              {
                logined && currentTab === '客户列表（海关）' &&
                <CustomCustomers
                  newAlert={newAlert}
                ></CustomCustomers>
              }
              {
                logined && currentTab === '经销分类' &&
                <Dealers
                  newAlert={newAlert}
                ></Dealers>
              }
              {
                logined && currentTab === '订单列表（扭蛋）' &&
                <Orders
                  newAlert={newAlert}
                  category="扭蛋"
                ></Orders>
              }
              {
                logined && currentTab === '订单列表（周边）' &&
                <Orders
                  newAlert={newAlert}
                  category="周边"
                ></Orders>
              }
              {
                logined && currentTab === '订单列表（周边-现货）' &&
                <Orders
                  newAlert={newAlert}
                  category="周边-现货"
                ></Orders>
              }
              {
                logined && currentTab === '订单列表（手办）' &&
                <Orders
                  newAlert={newAlert}
                  category="手办"
                ></Orders>
              }

              {
                logined && currentTab === '订单列表（海关）' &&
                <Orders
                  newAlert={newAlert}
                  category="海关"
                ></Orders>
              }

              {
                logined && currentTab === '快递列表' &&
                <Logistics
                  newAlert={newAlert}
                ></Logistics>
              }

              {
                logined && currentTab === '品牌列表' &&
                <Brands
                  newAlert={newAlert}
                ></Brands>
              }
              {
                logined && currentTab === '标签列表' &&
                <ProductTags
                  newAlert={newAlert}
                ></ProductTags>
              }
            </Box>
          </Grid2>
        </Grid2>

      </Box>
    </ThemeProvider>
  );
}

export default App;