const savefile = (name: string, blob: Blob) => {
  return new Promise((resolve, reject) => {
    var a = document.createElement("a"),
      url = URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      resolve(true)
    }, 0);
  })
}

export default savefile