import React from 'react'
import { Box, Button, CardActions, Checkbox, Chip, Collapse, Container, FormControlLabel, Icon, IconButton, Input, InputAdornment, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Typography } from "@mui/material"
import CMSApi from '../../utils/opsloth_cms_service_api'
import TableList from './TableList'
import select_items from '../../utils/select_items'
import SearchIcon from '@mui/icons-material/Search';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { isArrayBindingPattern } from 'typescript'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

function TableDiv(props: any) {
	const [initPageLimit] = React.useState(20)
	const [pageLimit, setPageLimit] = React.useState(initPageLimit)
	const [currentPage, setCurrentPage] = React.useState(0)
	const [dataDialogShow, setDataDialogShow] = React.useState(false)
	const [modifyOrAdd, setModifyOrAdd] = React.useState('')
	const [dataAddData, setDataAddData] = React.useState({})
	const [data, setData] = React.useState<any[]>([])
	const [orderBy, setOrderBy] = React.useState<string>('createdAt')
	const [order, setOrder] = React.useState<'desc' | 'asc'>('desc')
	const [modifyColumn, setModifyColumn] = React.useState('')
	const [modifyColumnValueBeforeClick, setModifyColumnValueBeforeClick] = React.useState('')
	const [modifyIds, setModifyIds] = React.useState<string[]>([])
	const [modifyIndex, setModifyIndex] = React.useState(-1)
	const [modifyColumnValue, setModifyColumnValue] = React.useState('')

	const [checkboxSelectedLength, setCheckboxSelectedLength] = React.useState(0)
	const [searchKeywordAArrayLength, setSearchKeywordAArrayLength] = React.useState(0)

	const [searchKeyword, setSearchKeyword] = React.useState('')
	const [searchKeywordPlaceholder, setSearchKeywordPlaceholder] = React.useState('')
	const [filterLaunchDate, setFilterLaunchDate] = React.useState('')
	const [filterCutOffDate, setFilterCutOffDate] = React.useState('')
	const [filterCollapse, setFilterCollapse] = React.useState(false)
	const [brandsSelected, setBrandsSelected] = React.useState<string[]>([])
	const [tagsSelected, setTagsSelected] = React.useState<string[]>([])
	const [logisticsStatusSelected, setLogisticsStatusSelected] = React.useState<string[]>([])
	const [ifArrivedChecked, setIfArrivedChecked] = React.useState(false)
	const [ifCustomsCleared, setIfCustomsCleared] = React.useState(false)
	const [ifSoonChecked, setIfSoonChecked] = React.useState(false)
	const [ifNotArrivedChecked, setIfNotArrivedChecked] = React.useState(false)
	const [filterPriceJPMin, setFilterPriceJPMin] = React.useState('')
	const [filterPriceJPMax, setFilterPriceJPMax] = React.useState('')


	const dataModel = new CMSApi(props.schema.TABLE.name)

	const handleDataSubmit = async (returnData: any) => {
		if (modifyOrAdd === 'add') {//创建提交
			returnData.category = props.schema?.category?.default ?? undefined
			const res: any = await dataModel.post(returnData)
			if (!res.data) {//创建不成功
				if (res.response.data.errors) {
					props.newAlert(`有必填项未填，创建失败`, 'error')
				} else if (res.response.data.code === 11000) {
					props.newAlert(`${props.schema[Object.keys(res.response.data.keyValue)[0]].label}已有相同数据：${res.response.data.keyValue[Object.keys(res.response.data.keyValue)[0]]}`, 'error')
				}
			} else {//创建
				let sameProductCodeTempResLength = 1
				if (returnData.product_code && props.schema.TABLE.name == 'products') {
					const sameProductCodeTempRes: any = await dataModel.setFilter({ product_code: { $regex: returnData.product_code.trim() }, deleted: { $ne: true } }).setOptions({ limit: 2 }).get()
					sameProductCodeTempResLength = sameProductCodeTempRes.data.length
				}
				if (sameProductCodeTempResLength == 1) {
					props.newAlert(`创建${props.schema.TABLE.label}成功`)
				} else {
					props.newAlert(`创建${props.schema.TABLE.label}成功，有重复货号！`, 'warning')
				}
				setDataDialogShow(false)
				//TODO update part of table
				getData()
			}
		} else if (modifyOrAdd === 'modify') {//修改提交
			console.log(returnData[modifyColumn], modifyColumnValueBeforeClick, 'returnData[modifyColumn]', JSON.stringify(returnData[modifyColumn]))
			if ((returnData[modifyColumn] === modifyColumnValueBeforeClick || returnData[modifyColumn] === undefined) && modifyColumn != "warehouse_stock_product_name") {
				props.newAlert("数据无变化，取消提交", 'info')
			} else {
				let dataModelModify = dataModel
				if (modifyIds.length === 1) {
					dataModelModify = new CMSApi(props.schema.TABLE.name + '/' + modifyIds[0])
					await generateModifyLog([{ _id: modifyIds[0] }], modifyColumn, returnData, modifyColumnValueBeforeClick)
				} else if (modifyIds.length > 1) {
					dataModelModify = new CMSApi(props.schema.TABLE.name)
					let filterTemp: { _id: string }[] = []
					modifyIds.forEach(_id => {
						filterTemp.push({ _id: _id })
					})
					dataModelModify.setFilter({ $or: filterTemp })
					await generateModifyLog(filterTemp, modifyColumn, returnData, modifyColumnValueBeforeClick)
				} else {
					props.newAlert("修改出错")
					return
				}
				const res: any = await dataModelModify.put(returnData)
				if (res?.data?.modifiedCount >= 1) {
					props.newAlert("修改数据成功")
					onModifySuccess(modifyIndex, modifyColumn, modifyColumnValue)
					setModifyColumnValueBeforeClick(returnData[modifyColumn])
					setDataDialogShow(false)
					//TODO update part of table
					getData()
					setTimeout(() => {
						props.newAlert(`已选中 ${checkboxSelectedLength}`, 'info', checkboxSelectedLength === 0 ? false : true)
					}, 2000);
				}
			}
		} else if (modifyOrAdd === 'batchAdd') {//批量创建
			console.log(returnData)
			const res: any = await dataModel.post(returnData)
			getData()
		}
	}

	const handleProductDelete = async (checkboxSelected: string[]) => {
		const confirm: boolean = window.confirm(`确认删除${checkboxSelected.length}项？`)
		if (confirm && checkboxSelected.length > 0) {
			const dataModel = new CMSApi(props.schema.TABLE.name)
			const res: any = await dataModel.setFilter({ _id: { $in: checkboxSelected } }).put({ deleted: true })
			console.log(res?.data?.modifiedCount, checkboxSelected.length)
			if (res?.data?.modifiedCount === checkboxSelected.length) {
				getData()
				props.newAlert("删除")
			}
		}
	}

	const getData = async () => {
		const res: any = await getProducts()
		setData(res)
	}

	const getProducts = (page?: number) => {
		return new Promise(async (resolve, reject) => {
			let pageTemp = 0
			if (page != undefined) {
				pageTemp = page
			} else {
				pageTemp = currentPage
			}

			let filter: any = {}
			let options = {
				skip: pageTemp * pageLimit,
				limit: pageLimit,
				sort: (orderBy == 'createdAt' ? `${order === 'desc' ? '-' : '+'}_id ` : '') + (order === 'desc' ? '-' : '+') + orderBy
			}
			switch (props.schema.TABLE.name) {
				case 'products': {
					setSearchKeywordPlaceholder("输入货号 / 日文名称 / 中文名称 / 对应库存")

					let arrivedTemp: string[] = []
					
					if (ifArrivedChecked) {
						arrivedTemp.push('已到货')
					}
					if (ifNotArrivedChecked) {
						arrivedTemp.push('未到货')
					}
					if (ifCustomsCleared) {
						arrivedTemp.push('已报关')
					}
					if (ifSoonChecked) {
						arrivedTemp.push('将到货')
					}

					filter = {
						deleted: { $ne: true },
						category: props.schema.category.default,
						launch_date: filterLaunchDate != '' ? filterLaunchDate : undefined,
						cut_off_date: filterCutOffDate != '' ? filterCutOffDate : undefined,
						arrived: arrivedTemp.length > 0 ? { $in: arrivedTemp } : undefined,
						brand: brandsSelected.length > 0 ? { $in: brandsSelected } : undefined,
						tags: tagsSelected.length > 0 ? { $in: tagsSelected } : undefined,
						$and: [{ price_jpy: filterPriceJPMin ? { $gte: filterPriceJPMin } : undefined }, { price_jpy: filterPriceJPMax ? { $lte: filterPriceJPMax } : undefined }],
					}
					let searchKeywordArray:any = searchKeyword.match(/\d{13}/g)
					if (searchKeywordArray && searchKeywordArray.length > 0){
						filter['$or'] = [{product_code: { $regex: `${searchKeywordArray.join('|')}[ ]?`}}, {product_code_box: { $regex: `${searchKeywordArray.join('|')}[ ]?`}}]
					}else{
						filter['$or'] = [{ name_jp: { $regex: searchKeyword } }, { name_zh: { $regex: searchKeyword } }, { product_code: { $regex: searchKeyword } }, { product_code_box: { $regex: searchKeyword } },{ warehouse_stock_product_name: { $regex: searchKeyword } }]
					}
					break
				}
				case 'customers': {
					setSearchKeywordPlaceholder("输入客户名称 / 地址 / 备注")
					filter = {
						deleted: { $ne: true },
						$or: [{ company: { $regex: searchKeyword } }, { address: { $regex: searchKeyword } }, { remark: { $regex: searchKeyword } }]
					}
					break
				}
				case 'customer_types': {
					setOrderBy('name')
					setSearchKeywordPlaceholder("输入分类名称")
					filter = {
						name: { $regex: searchKeyword },
						deleted: { $ne: true }
					}
					break
				}
				case 'logistics': {
					setSearchKeywordPlaceholder("输入物流单号 / 订单号")
					filter = {
						$or: [{ logistics_number: { $regex: searchKeyword } }, { order_no: { $regex: searchKeyword } }],
						logistics_status: logisticsStatusSelected.length > 0 ? { $in: logisticsStatusSelected } : undefined
					}
					break
				}
			}

			const res: any = await dataModel
				.setFilter(filter)
				.setOptions(options)
				.get()
			resolve(res.data)
		})
	}

	const generateModifyLog = (filterTemp: { _id: string }[], modifyColumn: any, returnData: any, modifyColumnValueBeforeClick: any) => {
		return new Promise(async (resolve, reject) => {
			if (props.schema.TABLE.name == 'products') {
				const dataModelModify = new CMSApi('products_logs')
				let dataTemp: { table_name: string, modify_id: string, user: string, remark: string }[] = []
				filterTemp.forEach(item => {
					dataTemp.push({
						table_name: props.schema.TABLE.name,
						modify_id: item._id,
						user: (window as any).user_nickname,
						remark: props.schema[modifyColumn].label + ': ' + modifyColumnValueBeforeClick + ' > ' + returnData[modifyColumn]
					})
				})
				console.log(dataTemp)
				await dataModelModify.post(dataTemp)
			}
			resolve(true)
		})
	}

	const getDataNewPage = async (newPage: number) => {
		return new Promise(async (resolve, reject) => {
			const res: any = await getProducts(newPage)
			setData(res)
			resolve(true)
		})
	}

	const onModifySuccess = (index: number, modifyColumn: string, modifyColumnValue: string) => {
		//TODO 多选修改时 只有选中的会修改
		data[index].base64ed = 0
		data[index][modifyColumn] = modifyColumnValue
	}

	const handleOrderChange = (schema: string) => {
		if (orderBy === schema) {
			setOrder(order === 'desc' ? 'asc' : 'desc')
		} else {
			setOrderBy(schema)
		}
	}

	React.useEffect(() => {
		getData()
	}, [order, orderBy, pageLimit])


	return (
		<>
			<Paper
				sx={{ mb: 1 }}
			>
				<CardActions>
					<TextField
						fullWidth
						size='small'
						placeholder={searchKeywordPlaceholder}
						onInput={(event: any) => { setSearchKeyword(event.target.value.replace(/[\#\%\&\*\\\(\)\[\]]/g, '')) }}
						onKeyDown={async (event: any) => {
							if (event.keyCode === 13) {
								let searchKeywordArray:any = searchKeyword.match(/\d{13}/g)
								setSearchKeywordAArrayLength(searchKeywordArray? searchKeywordArray.length : 1)
								if (searchKeywordAArrayLength > 200) {
									alert('超过最大200搜索限制')
								} else {
									await getDataNewPage(0);
									setCurrentPage(0)
								}
							}
						}}
						value={searchKeyword}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
					<IconButton
						sx={{ mx: 1 }}
						size='large'
						onClick={() => { setFilterCollapse(!filterCollapse) }}
					>
						{filterCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</IconButton>
				</CardActions >
				<Typography sx={{mx:1}}>{searchKeywordAArrayLength > 1 ? `输入框货号数量：${searchKeywordAArrayLength}` : ''}</Typography>

				{
					<Collapse
						in={filterCollapse}
						timeout="auto"
						sx={{ px: 1, py: .5 }}

					>
						{
							props.schema.TABLE.name === 'products' && <Box>
								{
									<Typography
										variant='body2'
									>品牌选择</Typography>
								}

								<Box
									sx={{ mx: 2 }}
								>
									{
										select_items['brand'].map((item, index) => {
											return (
												<Chip
													key={index}
													clickable={false}
													onClick={() => {
														if (brandsSelected.indexOf(item.label) == -1) {
															brandsSelected.push(item.label)
														} else {
															brandsSelected.splice(brandsSelected.indexOf(item.label), 1)
														}
														setBrandsSelected([...brandsSelected])
													}}
													color={brandsSelected.indexOf(item.label) >= 0 ? 'primary' : 'default'}
													label={item.label}
													size="small"
													sx={{ m: .4, cursor: 'pointer' }}
												/>
											)
										})
									}
								</Box>

								{
									<Typography
										variant='body2'
									>标签选择</Typography>
								}

								<Box
									sx={{ mx: 2 }}
								>
									{
										select_items['tag'].map((item, index) => {
											return (
												<Chip
													key={index}
													clickable={false}
													onClick={() => {
														if (tagsSelected.indexOf(item.label) == -1) {
															tagsSelected.push(item.label)
														} else {
															tagsSelected.splice(tagsSelected.indexOf(item.label), 1)
														}
														setTagsSelected([...tagsSelected])
													}}
													color={tagsSelected.indexOf(item.label) >= 0 ? 'primary' : 'default'}
													label={item.label}
													size="small"
													sx={{ m: .4, cursor: 'pointer' }}
												/>
											)
										})
									}
								</Box>

								{
									<Grid2 container sx={{ display: 'flex' }}>
										<Grid2
											lg={3}
											xs={12}
											sx={{ my: 2 }}
										>
											<Typography
												variant='body2'
											>到货时间</Typography>
											<TextField
												size="small"
												type='date'
												sx={{ mx: 2 }}
												value={filterLaunchDate}
												onChange={(event) => {
													setFilterLaunchDate(event.target.value)
												}}
											></TextField>
										</Grid2>
										<Grid2
											lg={3}
											xs={12}
											sx={{ my: 2 }}
										>
											<Typography
												variant='body2'
											>截单时间</Typography>
											<TextField
												size="small"
												type='date'
												sx={{ mx: 2 }}
												value={filterCutOffDate}
												onChange={(event) => {
													setFilterCutOffDate(event.target.value)
												}}
											></TextField>
										</Grid2>
										<Grid2
											lg={3}
											xs={12}
											sx={{ my: 2, ml: 0 }}
										>
											<Typography
												variant='body2'
											>日元售价</Typography>

											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<TextField
													size="small"
													sx={{ width: '6em', ml: 2 }}
													type='number'
													value={filterPriceJPMin}
													onChange={(event) => { setFilterPriceJPMin(event.target.value) }}
												/>
												<Typography sx={{ px: 1 }} variant='h5'>~</Typography>
												<TextField
													size="small"
													sx={{ width: '6em' }}
													type='number'
													value={filterPriceJPMax}
													onChange={(event) => { setFilterPriceJPMax(event.target.value) }}
												/>
											</Box>
										</Grid2>
										<Grid2
											lg={3}
											xs={12}
											sx={{ my: 2, ml: 0 }}
										>
											<Typography
												variant='body2'
											>是否到货</Typography>
											<FormControlLabel
												labelPlacement='end'
												label='已到货'
												sx={{ mx: 0 }}
												control={
													<Checkbox
														onClick={
															(event) => { setIfArrivedChecked((event.target as any).checked) }}
														checked={ifArrivedChecked}
													/>
												}
											/>
											<FormControlLabel
												labelPlacement='end'
												label='已报关'
												sx={{ mx: 0 }}
												control={
													<Checkbox
														onClick={
															(event) => { setIfCustomsCleared((event.target as any).checked) }}
														checked={ifCustomsCleared}
													/>
												}
											/>
											<FormControlLabel
												labelPlacement='end'
												label='将到货'
												sx={{ mx: 0 }}
												control={
													<Checkbox
														onClick={
															(event) => { setIfSoonChecked((event.target as any).checked) }}
														checked={ifSoonChecked}
													/>
												}
											/>
											<FormControlLabel
												labelPlacement='end'
												label='未到货'
												sx={{ mx: 0 }}
												control={
													<Checkbox
														onClick={
															(event) => { setIfNotArrivedChecked((event.target as any).checked) }}
														checked={ifNotArrivedChecked}
													/>
												}
											/>
										</Grid2>
									</Grid2>
								}
							</Box>
						}
						{
							props.schema.TABLE.name === 'logistics' && <Box>
								<Typography
									variant='body2'
								>物流状态</Typography>
							</Box>
						}
						{
							<Box
								sx={{ mx: 2 }}
							>
								{
									props.schema.TABLE.name === 'logistics' && select_items['logistics_status'].map(item => {
										return (
											<Chip
												key={item.label}
												clickable={false}
												onClick={() => {
													if (logisticsStatusSelected.indexOf(item.label) == -1) {
														logisticsStatusSelected.push(item.label)
													} else {
														logisticsStatusSelected.splice(logisticsStatusSelected.indexOf(item.label), 1)
													}
													setLogisticsStatusSelected([...logisticsStatusSelected])
												}}
												color={logisticsStatusSelected.indexOf(item.label) >= 0 ? 'primary' : 'default'}
												label={item.label}
												size="small"
												sx={{ m: .4, cursor: 'pointer' }}
											/>
										)
									})
								}
							</Box>
						}
						{
							<Box
								sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
							>
								<Button
									onClick={() => {
										setBrandsSelected([])
										setTagsSelected([])
										setLogisticsStatusSelected([])
										setSearchKeyword('')
										setFilterLaunchDate('')
										setFilterCutOffDate('')
										setIfArrivedChecked(false)
										setIfNotArrivedChecked(false)
										setIfCustomsCleared(false)
										setIfSoonChecked(false)
										setFilterPriceJPMin('')
										setFilterPriceJPMax('')
										setSearchKeywordAArrayLength(0)
									}}
									variant='contained'
									color='warning'
									sx={{ m: 1, position: 'relative', right: 0 }}
								>重置过滤</Button>
								<Button
									onClick={async () => {
										await getDataNewPage(0);
										setCurrentPage(0)
									}}
									variant='contained'
									sx={{ m: 1, mr: 8, position: 'relative', right: 0 }}
								>搜索</Button>
							</Box>
						}

					</Collapse>
				}
			</Paper>
			<Paper>
				<TableList
					pageLimit={pageLimit}
					setPageLimit={setPageLimit}
					initPageLimit={initPageLimit}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					schema={props.schema}
					array={data}
					orderBy={orderBy}
					handleOrderChange={handleOrderChange}
					order={order}
					newAlert={props.newAlert}
					getDataNewPage={getDataNewPage}
					setModifyIds={setModifyIds}
					setModifyIndex={setModifyIndex}
					modifyColumn={modifyColumn}
					setCheckboxSelectedLength={setCheckboxSelectedLength}
					setModifyColumn={setModifyColumn}
					modifyColumnValue={modifyColumnValue}
					setModifyColumnValue={setModifyColumnValue}
					setModifyColumnValueBeforeClick={setModifyColumnValueBeforeClick}
					dataDialogShow={dataDialogShow}
					setDataDialogShow={setDataDialogShow}
					title={`${modifyOrAdd === 'add' ? '新建' : '修改'}${props.schema.TABLE.label}`}
					modifyOrAdd={modifyOrAdd}
					setModifyOrAdd={setModifyOrAdd}
					returnData={dataAddData}
					setReturnData={setDataAddData}
					onSubmit={handleDataSubmit}
					handleProductDelete={handleProductDelete}
				/>
			</Paper>
		</>
	)
}

export default TableDiv