import { Box, Container } from "@mui/material"
import TableDiv from '../components/Products/TableDiv'

const schema = {
  TABLE: { name: 'customer_types', label: '客户分类', display: false },
  name: { label: '分类名称', addable: true, editable: true },
  brand: { label: '品牌', addable: true, editable: true, type: 'select' },
  single_price_cny: { label: '单个价格', addable: true, editable: true },
  pack_price_cny: { label: '一袋价格', addable: true, editable: true },
  carton_price_cny: { label: '一箱价格', addable: true, editable: true },
  remark: { label: '备注', addable: true, editable: true },
  createdAt: { label: '创建时间', addable: false, editable: false }
}

function CustomerTypes(props: any) {
  return (
    <Box>
      <TableDiv
        schema={schema}
        newAlert={props.newAlert}
      >
      </TableDiv>
    </Box>
  )
}

export default CustomerTypes