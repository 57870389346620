import { createTheme } from '@mui/material/styles';
import { zhCN } from '@mui/material/locale';
const customerTheme = createTheme({}, zhCN)

customerTheme.components!.MuiDialogTitle = {
    styleOverrides: {
        root: {
            color: customerTheme.palette.primary.main
        }
    }
}

customerTheme.components!.MuiTypography = {
    styleOverrides: {
        root: {
            color: customerTheme.palette.primary.main
        }
    }
}

customerTheme.components!.MuiTablePagination!.styleOverrides = {
    toolbar: {
        flexWrap: 'wrap'
    }
}

export default customerTheme