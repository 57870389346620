import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Box, Typography, Fab } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import uploadImage from '../../utils/uploadImage'
import DeleteIcon from '@mui/icons-material/Delete'
import FolderIcon from '@mui/icons-material/PermMedia'
import select_items from '../../utils/select_items'
import getSmallerImgUrl from "../../utils/getSmallerImgUrl";
import DragAndDrop from './DragAndDrop';
import WarehouseStocksChooseList from '../Products/WarehouseStocksChooseList'
import TagSelect from '../Products/TagSelect'
import ImagesManageDialog from './ImagesManageDialog'
interface dataType {
	[key: string]: string | string[];
}

function DialogTextfields(props: any) {
	const [returnData, setReturnData] = React.useState<dataType>({})
	const [images, setImages] = React.useState<string[]>([])
	const [modifyColumnName, setModifyColumnName] = React.useState('')
	const [dropedMainImageFiles, setDropedMainImageFiles] = React.useState<File[]>([])
	const [dropedImagesFiles, setDropedImagesFiles] = React.useState<File[]>([])
	const [mainImageBase64, setMainImageBase64] = React.useState<string[] | ArrayBuffer[]>()
	const [imagesBase64, setImagesBase64] = React.useState<string[] | ArrayBuffer[]>()
	const [mainImageUrl, setMainImageUrl] = React.useState<string[]>([])
	const [imagesUrl, setImagesUrl] = React.useState<string[]>([])
	const [imagesManageDialogOpen, setImagesManageDialogOpen] = React.useState(false)
	const imagesManageSelectMultiple = React.useRef(false)
	const imagesManageSelectColumn = React.useRef('')

	const handleSubmit = async () => {
		try {
			if ((props.modifyColumn === 'deposit_cny' || props.modifyColumn === 'balance_cny') && dropedMainImageFiles.length > 0) {
				const resultTempArray: string = await uploadImage(dropedMainImageFiles.slice(0, 1)) as string
				returnData.mainImage = resultTempArray[0]
			}

			// if ((props.modifyColumn === 'images' && dropedImagesFiles.length > 0) || (dropedImagesFiles.length > 0 && props.modifyOrAdd === 'add')) {
			// 	let resultTempArray: string = await uploadImage(dropedImagesFiles) as string
			// 	returnData.images = images.concat(resultTempArray)
			// }

			console.log(returnData)
			props.onSubmit(returnData)
		} catch (e) {
			alert('出错了')
			console.log(e)
			props.onSubmit(returnData)
		}
	}

	const handleImagesManageSubmit = (chosenImages: string[]) => {
		try {
			if (imagesManageSelectColumn.current === 'mainImage') {
				returnData.mainImage = chosenImages[0]
				console.log('mainImage')
				setMainImageUrl([...chosenImages])
			}

			if (imagesManageSelectColumn.current === 'images') {
				returnData.images = images.concat(chosenImages)
				console.log('images')
				setImagesUrl([...chosenImages])
			}
			handleSubmit()
			setImagesManageDialogOpen(false)
		} catch (e) {
			alert('出错了')
			console.log(e)
		}
	}

	const handleImagesDeleteClick = (clickIndex: number) => () => {
		images.splice(clickIndex, 1)
		setImages([...images])
		returnData.images = [...images]
	}

	const handleInput = (event: any) => {
		returnData[event.target.name] = event.target.value.trim()
		if (props.modifyOrAdd === 'modify') {
			// props.setModifyColumnValue(event.target.value)
		}
	}

	const handleKeyUp = (event: any)=>{
		if (event.key == "Enter"){
			handleSubmit()
		}
	}

	const handleOnWheel = (event: any) => {
		event.target.blur()
	}

	const handleFilesChange = (files: File[]) => {
		setDropedMainImageFiles(files)
		if (files.length > 0) {
			imagesPreview(files, 'mainImage')
		} else {
			setMainImageBase64([])
		}
	}

	const handleFilesImagesChange = (files: File[]) => {
		console.log('!')
		setDropedImagesFiles(files)
		if (files.length > 0) {
			imagesPreview(files, 'images')
		} else {
			setImagesBase64([])
		}
	}

	const handleGetWarehouseStockProduct = (product?: any) => {
		if (product) {
			returnData.warehouse_stock_product_id = product._id
			returnData.warehouse_stock_product_name = product.name
		} else {
			returnData.warehouse_stock_product_id = ''
			returnData.warehouse_stock_product_name = ''
		}
		setReturnData(JSON.parse(JSON.stringify(returnData)))
		// props.setModifyColumnValue(product?.name)
	}

	React.useEffect(() => {
		setDropedMainImageFiles([])
		setDropedImagesFiles([])
		setImages([])
		setMainImageBase64([])
		setImagesBase64([])
		setMainImageUrl([])
		setImagesUrl([])
		let returnDataTemp: any = {}
		Object.keys(props.schema).forEach((key: string) => { // initialize select item default value
			if (props.open && props.modifyOrAdd === 'add' && props.schema[key].type === 'select') {
				returnDataTemp[key] = (select_items as any)[key][0].value
			}
		})

		if (props.open && props.modifyOrAdd === 'modify') {
			returnDataTemp[props.modifyColumn] = props.modifyColumnValue
			if (props.modifyColumn == 'mainImage') {
				setMainImageUrl([props.modifyColumnValue])
			}
			if (props.modifyColumn == 'images') {
				setImages([...props.modifyColumnValue])
			}
		}
		setReturnData(JSON.parse(JSON.stringify(returnDataTemp)))
	}, [props.open])

	async function imagesPreview(files: Blob[], type: 'mainImage' | 'images') {
		let imagesBase64Temp: any[] = []
		for (let i = 0; i < files.length; i++) {
			let base64Temp = await getBase64(files[i])
			imagesBase64Temp.push(base64Temp)
		}
		if (type === 'mainImage') {
			setMainImageBase64(imagesBase64Temp)
		}
		if (type === 'images') {
			setImagesBase64(imagesBase64Temp)
		}
	}

	function getBase64(file: Blob) {
		return new Promise((resolve, reject) => {
			var reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				resolve(reader.result)
			}
			reader.onerror = function (error) {
				alert('出错了')
				reject(error)
			}
		})
	}

	return (
		props.open && <Dialog
			open={props.open}
			onClose={() => props.setOpen(false)}
			fullWidth={true}
			maxWidth='md'
		>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<Grid2 container spacing={2} sx={{ my: 1 }}>
					{
						Object.keys(props.schema).map((key: any, index: number) => {
							return (
								(
									props.modifyOrAdd === 'add' // 判断修改还是添加
										? props.schema[key].addable
										: props.schema[key].editable && props.modifyColumn === key // 修改时显示的表单类型
								)
								&&
								<Grid2
									key={index}
									xs={12}
									md={props.modifyOrAdd === 'add' ? 6 : 12}
									lg={props.modifyOrAdd === 'add' ? 4 : 12}
								>

									{
										// 仓库, 标签, 子图 时不显示输入框
										(key !== 'warehouse_stock_product_name' && key !== 'tags')
										&& <TextField
											onWheel={handleOnWheel}
											fullWidth
											name={key}
											type={props.schema[key].type}
											select={props.schema[key].type == 'select' ? true : false}
											label={props.schema[key].label}
											defaultValue={
												props.modifyOrAdd === 'add'
													? (
														props.schema[key].type === 'select'
															? (select_items as any)[key][0].value
															: undefined
													)
													: (
														key == 'launch_date' || key === 'arrive_date'
															? (
																props.modifyColumnValue
																	? new Date(props.modifyColumnValue).toISOString().split('T')[0]
																	: new Date()
															)
															: props.modifyColumnValue
													)}
											onInput={handleInput}
											onChange={handleInput}
											onKeyUp={handleKeyUp}
											inputProps={{ max: 99999999, min: -1 }}
										>
											{
												props.schema[key].type === 'select' && (select_items as any)[key].map((option: any, optionIndex: number) => (
													<MenuItem
														key={optionIndex}
														value={option.value}
													>
														{option.label}
													</MenuItem>
												))
											}
										</TextField>
									}
									{
										(key === 'mainImage' || key === 'deposit_cny' || key === 'balance_cny')
										&&
										<Grid2
											sx={{ my: .5 }}
											container
										>
											<Grid2
												xs={6}
											>
												{(key === 'deposit_cny' || key === 'balance_cny') &&
													<DragAndDrop
														customer_id='dropedMainImageFiles'
														dropedFiles={dropedMainImageFiles}
														setDropedFiles={handleFilesChange}
														accept='.jpg, .png, .jpeg, .webp'
													></DragAndDrop>
												}
												{
													key === 'mainImage' &&
													<Button
														onClick={() => {
															setImagesManageDialogOpen(true)
															imagesManageSelectMultiple.current = false
															imagesManageSelectColumn.current = 'mainImage'
														}}
													><FolderIcon sx={{ fontSize: '2.5em' }} /></Button>
												}
											</Grid2>
											<Grid2
												xs={6}
											>
												{/* {
													mainImageBase64?.map((base64, index) =>
														< img
															key={index}
															style={{ maxHeight: '12em', width: '12em', margin: 5 }}
															src={base64 as any} />
													)
												} */}
												{
													mainImageUrl?.map((url, index) =>
														<div
															key={index}
															style={{ maxHeight: '12em', width: '11em', height: '11em', margin: '.2em', border: '2px solid #5cd25c' }}
														>
															< img
																style={{ width: '100%', height: '100%', objectFit: 'contain' }}
																src={getSmallerImgUrl(url)} />
														</div>
													)
												}
											</Grid2>
										</Grid2>
									}
									{
										key === 'images'
										&& <Grid2
											sx={{ my: .5 }}
											container
										><Grid2
											xs={2}
										>
												{/* <DragAndDrop
													customer_id='dropedImagesFiles'
													dropedFiles={dropedImagesFiles}
													setDropedFiles={handleFilesImagesChange}
													accept='.jpg, .png, .jpeg, .webp'
													multiple={true}
												></DragAndDrop> */}
												<Button
													onClick={() => {
														setImagesManageDialogOpen(true);
														imagesManageSelectMultiple.current = true
														imagesManageSelectColumn.current = 'images'
													}}
												><FolderIcon sx={{ fontSize: '2.5em' }} /></Button>
											</Grid2>
											<Grid2
												xs={10}
												sx={{ display: 'flex', flexWrap: 'wrap' }}
											>
												{
													images?.map((url: string, index: number) =>
														<div
															key={index}
															style={{ maxHeight: '12em', width: '11em', margin: '.2em', border: '2px solid #ccc' }}
														>
															<img
																style={{ width: '100%', height: '100%', objectFit: 'contain' }}
																src={getSmallerImgUrl(url)}
															/>
															<DeleteIcon
																sx={{ cursor: 'pointer', background: 'red', borderRadius: '10em', color: 'white', p: '.2em', transform: 'translate(5.6em, -2em)' }}
																onClick={handleImagesDeleteClick(index)}
															/>
														</div>
													)
												}
												{/* {
													imagesBase64?.map((base64, index) =>
														<div
															key={index}
															style={{ maxHeight: '12em', width: '11em', margin: '.2em', border: '2px solid #5cd25c' }}
														>
															< img
																style={{ width: '100%', height: '100%' }}
																src={base64 as any} />
														</div>
													)
												} */}
												{
													imagesUrl?.map((url, index) =>
														<div
															key={index}
															style={{ maxHeight: '12em', width: props.modifyOrAdd == 'add' ? '5.5em' : '11em', margin: '.2em', border: '2px solid #5cd25c' }}
														>
															< img
																style={{ width: '100%', height: '100%', objectFit: 'contain' }}
																src={getSmallerImgUrl(url)} />
														</div>
													)
												}
											</Grid2>
										</Grid2>
									}
									{
										key === 'warehouse_stock_product_name'
										&&
										<WarehouseStocksChooseList
											setWarehouseStockProduct={handleGetWarehouseStockProduct}
										>
										</WarehouseStocksChooseList>
									}
									{
										key === 'tags'
										&&
										<TagSelect
											tags={props.modifyColumnValue}
											setTags={props.setModifyColumnValue}
											setReturnData={setReturnData}
										></TagSelect>
									}

								</Grid2>
							)
						})
					}
				</Grid2>
			</DialogContent>
			<DialogActions>
				<Button
					sx={{ m: 1 }}
					onClick={() => props.setOpen(false)}
				>
					{"取消"}
				</Button>

				<Button
					onClick={handleSubmit}
				>
					{"提交"}
				</Button>
			</DialogActions>

			<ImagesManageDialog
				schema={props.schema}
				open={imagesManageDialogOpen}
				onClose={setImagesManageDialogOpen}
				imagesManageSelectMultiple={imagesManageSelectMultiple.current}
				submit={handleImagesManageSubmit}
				folderCache={props.folderCache}
				setFolderCache={props.setFolderCache}
				subFolderCache={props.subFolderCache}
				setSubFolderCache={props.setSubFolderCache}
				folderPageCache={props.folderPageCache}
				setFolderPageCache={props.setFolderPageCache}
			>
			</ImagesManageDialog>

		</Dialog>
	)
}

export default DialogTextfields