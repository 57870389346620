import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'

function Appbar(props: any) {
	return (
		<AppBar
			sx={{ background: 'white', color: 'black' }}
		>
			<Toolbar>
				<IconButton
					size="small"
					edge="start"
					color="primary"
					onClick={props.toggleDrawer}
				>
					<MenuIcon></MenuIcon>
				</IconButton>
				<Typography
					color="primary"
					variant='h6'
				>
					{props.currentTab}
				</Typography>
			</Toolbar>
		</AppBar>
	)
}

export default Appbar