import CMSApi from '../utils/opsloth_cms_service_api'
const OSS = require('ali-oss')
const client = new OSS({
  region: 'oss-cn-shanghai',
  accessKeyId: 'LTAI5tJT7zbHi9sRvdFn6z3g',
  accessKeySecret: 'FcnTyEN8q3u3NsQku60zPg3j4CQWe1',
  bucket: 'cms-service'
})

const uploadImage = (dropedFiles: any, folder: string = '/',type: string = '/') => {
  return new Promise(async (resolve, reject) => {
    let resultArray = []
    let postData = []
    for (let i = 0; i < dropedFiles.length; i++) {
      let dropedFile = dropedFiles[i]
      const result: { url: string } = await client.put(
        folder + '/' + (new Date().getTime()) + dropedFile.name,
        dropedFile,
        {
          headers: {
            'Content-Disposition': 'inline'
          }
        }
      )
      resultArray.push(result.url)
      postData.push({
        url: result.url,
        folder: folder.lastIndexOf('/') == folder.length - 1 ? folder : folder + '/',
        type: type
      })
    }
    if (type != "job_payment_imgs"){
      const dataModel = new CMSApi('image_metas')
      await dataModel.post(postData)
    }
    resolve(resultArray)
  })
}

export default uploadImage