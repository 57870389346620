import { Box, Button, Chip, Container, Fab, Paper } from "@mui/material"
import React from "react"
import OrdersTableList from "../components/Orders/OrdersTableList"
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ListIcon from '@mui/icons-material/List';
import OrderAddDialog from "../components/Orders/OrderAddDialog";
import ExcelJS from 'exceljs'
import JobAddDialog from "../components/Orders/JobAddDialog";
import opsloth_warehouse_stock_api from '../../src/utils/opsloth_warehouse_stock_api'

const ROW_HEADER = [null, "序号", "例图", "品牌", "货号", "日文名称", "中文名称", "日元售价", "代理版(元/个)", "日版(元/个)", "每袋数量", "每箱数量", "订购(袋)", "订购(箱)", "备注", "发货数量", "金额", "发货状态"]

const ROW_HEADER_2 = [null, "序号", "例图", "品牌", "货号", "日文名称", "中文名称", "日元售价", "代理版(元/个)", "日版(元/个)", "每盒数量", "每箱数量", "订购(盒)", "订购(箱)", "备注", "发货数量", "金额", "发货状态"]

const ROW_HEADER_3 = [null, "序号", "例图", "品牌", "货号", "日文名称", "中文名称", "日元售价", "代理版(元/个)", "代理版(元/箱)", "每盒数量", "每箱数量", "订购(盒)", "订购(箱)", "备注", "发货数量", "金额", "发货状态"]
function Jobs(props: any) {
  const [initPageLimit] = React.useState(20)
  const [pageLimit, setPageLimit] = React.useState(initPageLimit)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [jobs, setJobs] = React.useState<any[]>([])
  const [jobAddDialogOpen, setJobAddDialogOpen] = React.useState(false)
  const [customer, setCustomer] = React.useState('')
  const [jobName, setJobName] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [logistics, setLogistics] = React.useState('')
  const [paymentImgs, setPaymentImgs] = React.useState([])

  const onXLSXSubmit = (dropedWorkbook: ExcelJS.Workbook, uploaded_img_urls: string[]) => {
    const row_header = dropedWorkbook?.getWorksheet(1)?.getRow(1)
    let if_header_correct = true
    row_header?.eachCell((cell: ExcelJS.Cell, colNumber: number) => {
      if (cell.value != ROW_HEADER[colNumber] && cell.value != ROW_HEADER_2[colNumber] && cell.value != ROW_HEADER_3[colNumber]) {
        if_header_correct = false
      }
    })

    if (if_header_correct) {
      if (!jobName || !address || !logistics) {
        props.newAlert('有必填项未填', 'warning')
      } else {
        console.log(paymentImgs)
        createJob(dropedWorkbook.getWorksheet(1), uploaded_img_urls)
        setJobAddDialogOpen(false)
        setAddress('')
        setCustomer('')
        setJobName('')
        setLogistics('')
        setPaymentImgs([])
      }
    } else {
      props.newAlert('表格格式不正确', 'warning')
    }
  }

  const createJob = async (worksheet: ExcelJS.Worksheet | any, uploaded_img_urls:string[]) => {

    const job = {
      company: "zoome",
      address: address,
      customer: customer,
      items: [],
      payment_imgs: uploaded_img_urls,
      logistics: logistics,
      name: jobName,
      type: "批发",
      "logs": [{
        log_user: (window as any).user_nickname, type: "创建"
      }]
    }
    let job_items_temp: any = []
    worksheet.eachRow((row: ExcelJS.Row, rowNumber: number) => {
      console.log(row.getCell(15).value, !isNaN(Number(row.getCell(15).value)))
      if (rowNumber > 1 && !isNaN(Number(row.getCell(15).value)) && row.getCell(15).value != null) {
        job_items_temp.push({
          allocateStocks: [],
          allocateStocksLogged: false,
          logs: [],
          real_out_number: -1,
          retail_number: -1,
          pic: row.getCell(2).value,
          brand: row.getCell(3).value,
          jancode: (row.getCell(4).value as string)?.split('\n')[0],//TODO 需处理两个JANCODE 情况
          name_origin: row.getCell(5).value,
          name: row.getCell(6).value,
          price_jpy: row.getCell(7).value,
          price_cny_cn: row.getCell(8).value,
          price_cny_jpy: row.getCell(9).value,
          pack_spec: row.getCell(10).value,
          carton_spec: row.getCell(11).value,
          order_number_pack: isNaN(Number(row.getCell(12).value)) ? (row.getCell(12).value as any).result : row.getCell(12).value,
          order_number_carton: isNaN(Number(row.getCell(13).value)) ? (row.getCell(13).value as any).result : row.getCell(13).value,
          total_number: isNaN(Number(row.getCell(15).value)) ? (row.getCell(15).value as any).result : row.getCell(15).value,
          log_price: isNaN(Number(row.getCell(16).value)) ? (row.getCell(16).value as any).result : row.getCell(16).value
        })
      }
    })
    job.items = job_items_temp
    const jobs_model = new opsloth_warehouse_stock_api('jobs')
    let job_create_res:any = await jobs_model.post(job)
    if (job_create_res.data._id) {
      props.newAlert(`创建成功共${job_items_temp.length}条，请去小程序检查`, 'success')
      const send_template_message_res = jobs_model.send_template_message({
        template_id: "AIS2QgPl48ope03YK0S-G4jUwSTud2zablJXGJ5mnr8",
        data: {
          thing2: {
            value: "新的批发工单"
          },
          thing15: {
            value: "批发" + job.name
          },
          thing21: {
            value: job?.customer ? job?.customer : "未定义"
          }
        }
      })
    // console.log(send_template_message_res)
    }else{
      props.newAlert('创建出错，请检查', 'success')
    }
    getJobsbyPage(currentPage)
  }

  const getJobsbyPage = async (page: number) => {
    const jobs_model = new opsloth_warehouse_stock_api('jobs')
    let jobsTemp: any = await jobs_model
      .setFilter({ "status": { "$ne": "已作废" }, "type": "批发" })
      .setOptions({ sort: '-createdAt', limit: initPageLimit, skip: initPageLimit * currentPage })
      .get()
    setJobs(jobsTemp.data)
  }

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPageClick = () => {
    if (jobs.length == pageLimit) {
      setCurrentPage(currentPage + 1)
    } else {
      props.newAlert('已是最后一页', 'warning')
    }
  }

  React.useEffect(() => {
    getJobsbyPage(currentPage)
    setJobName('')
    setAddress('')
    setCustomer('')
    setLogistics('')
    setPaymentImgs([])
  }, [currentPage])

  return (<Box>
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <Box>
        <Button
          onClick={handlePrevPageClick}
        >上一页</Button>
        <Button
          variant="text"
        >{currentPage + 1}</Button>
        <Button
          onClick={handleNextPageClick}
        >下一页</Button>
      </Box>
    </Box>

    <Box
      sx={{ position: 'fixed', right: 20, bottom: 50 }}
    >
      {
        <Fab
          sx={{ zIndex: 1000, marginLeft: -1 }}
          color='warning'
          size='medium'
          onClick={() => {
            setAddress('')
            setJobName('')
            setLogistics('')
            setPaymentImgs([])
            setJobAddDialogOpen(true)
          }}
        >
          <PlaylistAddIcon></PlaylistAddIcon>
        </Fab >
      }
    </Box>

    <Box>
      {
        jobs.map((item: any) => {
          return <Paper
            sx={{ p: 2, m: 2, cursor: 'pointer' }}
          >
            <Box>{item.name}</Box>
            <Box>{new Date(item.createdAt).toLocaleString()}</Box></Paper>
        })
      }
    </Box>

    <Box>
      <Button
        onClick={handlePrevPageClick}
      >上一页</Button>
      <Button
        variant="text"
      >{currentPage + 1}</Button>
      <Button
        onClick={handleNextPageClick}
      >下一页</Button>
    </Box>

    <JobAddDialog
      newAlert={props.newAlert}
      open={jobAddDialogOpen}
      onClose={setJobAddDialogOpen}
      onSubmit={onXLSXSubmit}
      category={props.category}
      customer={customer}
      setCustomer={setCustomer}
      address={address}
      setAddress={setAddress}
      jobName={jobName}
      setJobName={setJobName}
      logistics={logistics}
      setLogistics={setLogistics}
      paymentImgs={paymentImgs}
      setPaymentImgs={setPaymentImgs}
    ></JobAddDialog>

  </Box>
  )
}

export default Jobs