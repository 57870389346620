import React, { FormEventHandler } from "react"
import { Backdrop, Box, Button, Container, Dialog, DialogActions, Pagination, TextField, Tooltip, Typography } from "@mui/material"
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import DragAndDrop from "./DragAndDrop"
import CheckIcon from '@mui/icons-material/CheckCircle'
import getSmallerImgUrl from "../../utils/getSmallerImgUrl";
import uploadImage from "../../utils/uploadImage"
import CMSApi from '../../utils/opsloth_cms_service_api'
import Grid2 from "@mui/material/Unstable_Grid2"
import CircularProgressWithLabel from "./CircularProgressWithLabel"
const css = `
.imagesManageDialogLeftText:hover {
    text-decoration: underline;
    cursor: pointer;
}`

const formatDate = (date: string): string => {
  let dateTemp = new Date(date)
  return dateTemp.getFullYear() + '-' + (dateTemp.getMonth() + 1 < 10 ? '0' + (dateTemp.getMonth() + 1) : (dateTemp.getMonth() + 1))
}

function ImagesManageDialog(props: any) {
  const today = new Date()
  const pageSize = 18

  const [currentFolder, setCurrentFolder] = React.useState<string>(props.folderCache ? props.folderCache : formatDate(new Date().toString()))

  const [currentPage, setCurrentPage] = React.useState(props.folderPageCache)
  const [totalImagesMetasCount, setTotalImagesMetasCount] = React.useState(1)
  const [images, setImages] = React.useState<string[]>([])
  const [chosenYear, setChosenYear] = React.useState(today.getFullYear())
  const [imageManageFiles, setImageManageFiles] = React.useState<File[]>([])

  const [chosenImages, setChosenImages] = React.useState<string[]>([])
  const [uploadProgress, setUploadProgress] = React.useState(0)

  const [createFolderDialogOpen, setCreateFolderDialogOpen] = React.useState(false)
  const [createFolderInput, setCreateFolderInput] = React.useState('')
  const [subFolders, setSubFolders] = React.useState<string[]>([])
  const [currentSubFolder, setCurrentSubFolder] = React.useState(props.subFolderCache)

  const handleLastYearClick = () => {
    setChosenYear(chosenYear - 2)
  }

  const handleNextYearClick = () => {
    setChosenYear(chosenYear + 2)
  }

  const handleFolderClick = (folder: string) => () => {
    setCurrentFolder(folder)
    props.setFolderCache(folder)
    setCurrentSubFolder('')
    props.setSubFolderCache('')
    setCurrentPage(1)
    props.setFolderPageCache(1)
    getImagesByFolder(folder)
  }

  const getImagesByFolder = async (folder: string, currentPage: number = 1, subFolder: string = '') => {
    const image_metas_modal = new CMSApi('image_metas')
    const res: any = await image_metas_modal
      .setFilter({ folder: folder + '/' + (subFolder ? subFolder + '/' : ''), type: props.schema.category.default })
      .setOptions({ limit: pageSize, skip: (currentPage - 1) * pageSize, sort: '-created -_id' })
      .get()
    const countRes = await image_metas_modal.count() as { data: number }
    getSubfoldersByFolder(folder)
    setTotalImagesMetasCount(countRes?.data ?? 1)
    setImages([...res.data])
  }

  const handleImageManageFilesChange = async (files: File[]) => {
    setUploadProgress(99)
    await uploadImage(files, currentFolder + '/' + (currentSubFolder ? currentSubFolder + '/' : ''), props.schema.category.default) as string[]
    getImagesByFolder(currentFolder, 1, currentSubFolder)
    setUploadProgress(0)
  }

  const handlePaginationChange = (event: any, page: number) => {
    if (page != currentPage) {
      getImagesByFolder(currentFolder, page, currentSubFolder)
      setCurrentPage(page)
      props.setFolderPageCache(page)
    }
  }

  const handleImageClick = (url: string) => () => {
    if (chosenImages.indexOf(url) == -1) {
      if (props.imagesManageSelectMultiple) {
        chosenImages.push(url)
      } else {
        chosenImages.splice(0, 1, url)
      }
    } else {
      chosenImages.splice(chosenImages.indexOf(url), 1)
    }
    setChosenImages([...chosenImages])
  }

  const handleCreateFolderChange = (event: any) => {
    setCreateFolderInput(event.target.value)
  }

  const handleCreateFolderClick = async () => {

    const image_floders_modal = new CMSApi('image_folders')
    const res: any = await image_floders_modal.post({
      parent_folder: currentFolder + '/',
      type: props.schema.category.default,
      name: createFolderInput.trim()
    })

    setCreateFolderDialogOpen(false)
    setCurrentSubFolder(createFolderInput.trim())
    props.setSubFolderCache(createFolderInput.trim())
    getImagesByFolder(currentFolder, 1, createFolderInput.trim())
  }

  const getSubfoldersByFolder = async (parentFolder: string) => {
    const image_floders_modal = new CMSApi('image_folders')
    const subFolders = await image_floders_modal
      .setFilter({
        parent_folder: parentFolder + "/",
        type: props.schema.category.default
      })
      .setOptions({ sort: 'name' })
      .get() as any
    setSubFolders([...subFolders.data])
  }

  const handleSubFolderClick = async (subFolder: string) => {
    setCurrentSubFolder(subFolder)
    props.setSubFolderCache(subFolder)
    setCurrentPage(1)
    props.setFolderPageCache(1)
    getImagesByFolder(currentFolder, 1, subFolder)
  }

  const handleSubmit = () => {
    if (chosenImages.length > 0) {
      props.submit(chosenImages)
    } else {
      alert('未选中图片')
    }
  }

  const handleKeyUp = (event:any) => {
    console.log(event.key)
    if (event.key == 'Enter'){
      handleSubmit()
    }
  }

  React.useEffect(() => {
    setChosenImages([])
    getImagesByFolder(currentFolder, currentPage, currentSubFolder)
  }, [props.open])

  React.useEffect(() => {
    setCreateFolderInput('')
  }, [createFolderDialogOpen])

  return <Dialog
    fullWidth={true}
    maxWidth='lg'
    open={props.open}
    onClose={() => props.onClose(false)}
    onKeyUp={handleKeyUp}
  >
    <style>{css}</style>
    <Box
      sx={{ display: 'flex', height: '70vh', width: '100%' }}
    >
      <Box sx={{ display: 'flex' }}>
        <Button onClick={handleLastYearClick} size="small" sx={{ minWidth: '.1em', background: '#ebf4ff' }}><ArrowLeftIcon /></Button>
        <Box sx={{ display: 'flex', flexDirection: 'column', background: '#ebf4ff', overflowY: 'scroll', pl: '6px' }}>
          {
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, itemIndex) =>
              <Box
                key={itemIndex}
                sx={{ whiteSpace: 'nowrap', width: 'fit-content' }}
              >
                <Typography
                  className="imagesManageDialogLeftText"
                  sx={{
                    textAlign: 'left',
                    py: .2,
                    textDecoration: currentFolder === formatDate(chosenYear + (itemIndex > 11 ? 1 : 0) + '-' + item) ? 'underline' : 'none',
                    fontWeight: currentFolder === formatDate(chosenYear + (itemIndex > 11 ? 1 : 0) + '-' + item) ? 'bold' : 'none'
                  }}
                  onClick={handleFolderClick(formatDate(chosenYear + (itemIndex > 11 ? 1 : 0) + '-' + item))}
                >
                  {
                    formatDate(chosenYear + (itemIndex > 11 ? 1 : 0) + '-' + item)
                  }

                </Typography>
                {
                  subFolders.map((subFolder: any) => {
                    return (subFolder.parent_folder == formatDate(chosenYear + (itemIndex > 11 ? 1 : 0) + '-' + item) + '/') &&
                      <Tooltip
                        title={subFolder.name}
                        placement='right'
                        key={subFolder._id}
                      >
                        <Typography
                          className="imagesManageDialogLeftText"
                          onClick={() => handleSubFolderClick(subFolder.name)}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '12em',
                            textDecoration: currentSubFolder === subFolder.name ? 'underline' : 'none'
                          }}
                        >- {subFolder.name}</Typography>
                      </Tooltip>
                  })
                }
              </Box>
            )
          }
        </Box>
        <Button onClick={handleNextYearClick} size="small" sx={{ minWidth: '.1em', background: '#ebf4ff' }}><ArrowRightIcon /></Button>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column-reverse', width: '100%', height: '100%' }}>
        <DialogActions>
          <Container
            maxWidth="lg"
            sx={{ width: '100%', my: 2 }}
          >
            <DragAndDrop
              customer_id='imageManageFiles'
              dropedFiles={imageManageFiles}
              setDropedFiles={handleImageManageFilesChange}
              accept='.jpg, .png, .jpeg, .webp'
              multiple={true}
            ></DragAndDrop>
          </Container>
          <Button
            sx={{ m: 1 }}
            onClick={() => props.onClose(false)}
          >
            {props.showSubmitButton !== false ? "取消" : "关闭"}
          </Button>
          {
            (props.showSubmitButton !== false) &&
            <Button
              onClick={handleSubmit}
            >
              {"提交"}
            </Button>
          }
        </DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Pagination
            count={Math.floor(totalImagesMetasCount / pageSize) + 1}
            onChange={handlePaginationChange}
            page={currentPage}
            showFirstButton
            showLastButton
          />
        </Box>
        <Box sx={{ display: 'flex', height: '70%', width: 'calc(100% - 1em)', p: '.5em', flexWrap: 'wrap', overflow: 'scroll', alignContent: 'flex-start' }}>
          <Grid2
            container
            spacing={1}
            width='100%'
          >
            {
              images.map((item: any) =>
                <Grid2
                  key={item.url}
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2}
                  xl={2}
                  onClick={handleImageClick(item.url)}
                >
                  <img
                    style={{ display: 'block', width: '100%', aspectRatio: '1/1', cursor: 'pointer', objectFit: 'cover' }}
                    src={getSmallerImgUrl(item.url)}
                  />
                  {
                    chosenImages.indexOf(item.url) >= 0 &&
                    <Box sx={{ position: 'relative', cursor: 'pointer' }}>
                      <CheckIcon sx={{ position: 'absolute', lineHeight: 0, fontSize: '1.5em', transform: 'translate(10%, -110%)', color: '#99ee99', background: 'white', borderRadius: '20em' }} />
                    </Box>

                  }
                </Grid2>
              )
            }
          </Grid2>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', p: 1, pb: 0 }}>
          <Typography sx={{ mx: 2 }}>已选中 {chosenImages.length} 张图片</Typography>
          <Backdrop
            sx={{ background: '#00000044', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={uploadProgress > 0}
          >
            <Box sx={{ background: '#fff', display: 'flex', borderRadius: '100%' }}>
              <CircularProgressWithLabel value={uploadProgress} />
            </Box>
          </Backdrop>
          <Button onClick={() => { setCreateFolderDialogOpen(true) }} size='small' sx={{ background: '#ebf4ff' }}>创建文件夹</Button>
        </Box>
      </Box>
    </Box>

    <Dialog
      open={createFolderDialogOpen}
      onClose={() => { setCreateFolderDialogOpen(false) }}
      maxWidth="xs"
      fullWidth
    >
      <TextField onChange={handleCreateFolderChange} sx={{ m: 2, mb: 0 }}></TextField>
      <DialogActions>
        <Button
          sx={{ m: 1 }}
          onClick={() => setCreateFolderDialogOpen(false)}
        >
          {props.showSubmitButton !== false ? "取消" : "关闭"}
        </Button>

        <Button
          onClick={handleCreateFolderClick}
        >
          {"提交"}
        </Button>

      </DialogActions>
    </Dialog>
  </Dialog>
}

export default ImagesManageDialog