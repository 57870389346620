import React, { ReactNode } from 'react'
import { Badge, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import DragAndDrop from '../public/DragAndDrop'
import ExcelJS from 'exceljs'
import CustomerChooseList from '../Customers/CustomerChooseList'
import OrderTableXLSX from './OrderTableXLSX'

function OrderAddDialog(props: any) {
  const [dropedFiles, setDropedFiles] = React.useState<File[]>([])
  const [dropedWorkbook, setDropedWorkbook] = React.useState<ExcelJS.Workbook>(new ExcelJS.Workbook())

  const handleFilesChange = (files: File[]) => {
    setDropedFiles(files)
    readXLSX(files[0])
  }

  const readXLSX = async (file: File) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = async (evt: any) => {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(evt.currentTarget.result)
        analyzeXLSX(workbook)
      }
      reader.readAsArrayBuffer(file);
    } else {
      setDropedWorkbook(new ExcelJS.Workbook())
    }
  }

  const analyzeXLSX = (workbook: ExcelJS.Workbook) => {
    if (workbook) {
      workbook.worksheets.forEach((sheet:any)=> {
        if (sheet.getRow(1).values.indexOf('定量') >= 0){
          const order_number_carton_index = sheet.getRow(1).values.indexOf('订购(箱)')
          const order_number_final_index = sheet.getRow(1).values.indexOf('定量')
          sheet.getRows(2, sheet._rows.length - 1).forEach((row:any) => {
            row.getCell(order_number_carton_index - 1).value = 0
            row.getCell(order_number_carton_index).value = row.getCell(order_number_final_index).value
            row.getCell(order_number_final_index).value = '/'
          })
        }
      })
      setDropedWorkbook(workbook)
    }
  }

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth='xl'
      onClose={() => { props.onClose(false) }}
    >
      <DialogTitle>{'添加订单'}</DialogTitle>
      <DialogContent>
        <DragAndDrop
          dropedFiles={dropedFiles}
          setDropedFiles={handleFilesChange}
          accept='.xlsx, .xls'
        ></DragAndDrop>
        <CustomerChooseList
          category={props.category}
          returnData={props.customer}
          setReturnData={props.setCustomer}
        >
        </CustomerChooseList>
        {
          <Box>
            {
              dropedWorkbook.worksheets.map((sheet, sheet_index) => (
                <Box key={sheet_index}>
                  <Chip
                    label={sheet.name}
                    color="primary"
                    size='small'
                    sx={{mb:-1.5, ml: -1.5}}
                  />
                  <OrderTableXLSX
                    sx={{ my: .5 }}
                    key={sheet_index}
                    sheet={sheet}
                  ></OrderTableXLSX>
                </Box>
              ))
            }
          </Box>
        }

      </DialogContent>
      <DialogActions>
        <Button
          sx={{ mx: 1 }}
          onClick={() => { props.onClose(false) }}
        >
          {"取消"}
        </Button>

        <Button
          onClick={() => {
            if (props.customer) {
              if (window.confirm('确认创建订单？')) {
                //TODO generate orders by dropedworkbook
                props.onSubmit(dropedWorkbook)
                setDropedWorkbook(new ExcelJS.Workbook())
                setDropedFiles([])
              }
            } else {
              props.newAlert('未选择客户无法创建订单!', 'warning')
            }
          }}
        >
          {"提交"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default OrderAddDialog