import { Box, Chip, Typography } from "@mui/material"
import select_items from "../../utils/select_items"
import React from 'react'
function TagSelect(props: any) {
  const [tags, setTags] = React.useState(Object.assign([],props.tags))
  const onTagClick = (event: React.MouseEvent<HTMLElement>) => {
    if (tags.indexOf((event.target as HTMLElement).innerText) >= 0) {
      tags.splice(tags.indexOf((event.target as HTMLElement).innerText), 1)
    } else {
      tags.push((event.target as HTMLElement).innerText)
    }
    tags.sort()
    props.setReturnData({tags: tags})
  }

  return (
    <Box>
      {
        select_items.tag.map(item => 
           <Chip
            key={item.value}
            onClick={onTagClick}
            sx={{ mx: .5, cursor: 'pointer'}}
            color={tags.indexOf(item.value) >= 0 ? 'primary' : 'default'}
            label={item.value}
          />
        )
      }
    </Box>
  )
}

export default TagSelect