import React, { ChangeEvent, DragEvent } from 'react'

import { Box, Chip } from '@mui/material'
import '../../css/DragAndDrop.css'

function DragAndDrop(props: any) {
  const [dragActive, setDragActive] = React.useState(false)
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const handleDrag = (event: DragEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true)
    } else if (event.type === "dragleave") {
      setDragActive(false)
    }
  }

  const handleDrop = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    console.log(event.dataTransfer.files, event.target.files)
    setDragActive(false);
    props.setDropedFiles([...event.dataTransfer.files])
  }
  
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    console.log(event)
    if (event.target.files && event.target.files[0]) {
      props.setDropedFiles([...event.target.files])
    }
  }

  const handleFileDelete = (index: number) => (event: ChangeEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    props.dropedFiles.splice(index, 1)
    props.setDropedFiles([...props.dropedFiles])
    fileInputRef.current!.value = ''
  }

  return (
    <Box>
        <form id={`${props.customer_id??''}_form-file-upload`} onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} >
        {
          props.multiple
          ? <input multiple type="file" id={`${props.customer_id??''}_input-file-upload`} ref={fileInputRef} onChange={handleChange} accept={props.accept ?? '.xlsx, .xls'} /> 
          : <input type="file" id={`${props.customer_id??''}_input-file-upload`} ref={fileInputRef} onChange={handleChange} accept={props.accept ?? '.xlsx, .xls'} />
        }
        <label id={`${props.customer_id??''}_label-file-upload`} htmlFor={`${props.customer_id??''}_input-file-upload`} className={dragActive ? "drag-active" : ""}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
            <p>{props.title ?? "拖拽文件到此处"}</p>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
              {
                props.dropedFiles.map((file: any, index: any) => {
                  return <Chip
                    sx={{ m: .5, maxWidth: '100%'}}
                    label={file.name}
                    key={file.name}
                    onClick={(event) => { event.preventDefault(); event.stopPropagation() }}
                    onDelete={handleFileDelete(index)}
                  />
                })
              }
            </div>
          </div>
        </label>
      </form>
    </Box>
  )
}
export default DragAndDrop