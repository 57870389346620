import { Box, Button, Chip, Container, Dialog, Typography, Checkbox, CircularProgress, Backdrop } from "@mui/material"
import CMSApi from '../../utils/opsloth_cms_service_api'
import React from "react"
import getSmallerImgUrl from "../../utils/getSmallerImgUrl"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import ExcelJS from 'exceljs'
import savefile from '../../utils/savefile'
import axios from "axios"
import CircularProgressWithLabel from "../public/CircularProgressWithLabel"
import select_items from '../../utils/select_items'

import CircularStatic from "../public/CircularProgressWithLabel"
import DragAndDrop from "../public/DragAndDrop"
const minimal_base64_img = 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
const ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const headers = ['序号', '例图', '品牌', '货号', '日文名称', '中文名称', '日元售价', '代理版(元/个)', '日版(元/个)', '每袋数量', '每箱数量', '订购(袋)', '订购(箱)', '备注']
const headers_key = ['order', 'mainImage', 'brand', 'product_code', 'name_jp', 'name_zh', 'price_jpy', 'price_cny_cn', 'price_cny_jp', 'pack_spec', 'carton_spec', 'order_number_pack', 'order_number_carton', 'order_remark']

function OrdersExportDialog(props: any) {
  const dataModel = new CMSApi(props.schema.TABLE.name)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [pageLimit] = React.useState(18)
  const [orders, setOrders] = React.useState<any[]>([])
  const [ordersChecked, setOrdersChecked] = React.useState<string[]>([])
  const [exportPercentage, setExportPercentage] = React.useState(0)
  const [dropedFiles, setDropedFiles] = React.useState<File[]>([])
  const [dropedFilesLoaded, setDropedFilesLoaded] = React.useState(false)
  const ordersCheckedItems: any = React.useRef([])
  const currentOverElementId = React.useRef('')
  const lastClickedIndex = React.useRef(0)
  const productWorkbook = React.useRef({})
  const ordersByTotal = React.useRef({})
  const ordersByCompany = React.useRef({})
  const lastGetOrdersCurrentPage = React.useRef(0)
  const imgUrls = React.useRef<Array<string>>(new Array())

  const [brandsChooseOpen, setBrandsChooseOpen] = React.useState(false)
  const [brandsChosen, setBrandsChosen] = React.useState<string[]>([])

  const getOrders = async (isInit?: boolean) => {
    if (isInit || lastGetOrdersCurrentPage.current != currentPage) {
      const res: any = await dataModel
        .setFilter({ deleted: { $ne: true }, category: props.category })
        .setField('-cargo_no -logistic_cny -nickname -products.arrived -launch_date')
        .setOptions({
          skip: currentPage * pageLimit,
          limit: pageLimit,
          sort: '-createdAt'
        })
        .get()
      lastGetOrdersCurrentPage.current = currentPage
      setOrders(res.data)
    }
  }

  const handleOnBoxMouseOver = (event: any) => {
    if (event.target.id) {
      currentOverElementId.current = event.target.id
      event.target.style.background = 'rgb(238,238,255)'
    }
  }

  const handleOnBoxMouseLeave = (event: any) => {
    document.getElementById(currentOverElementId.current)!.style.background = 'none'
  }

  const handleCancelAllCheckedClick = () => {
    ordersCheckedItems.current = []
    setOrdersChecked([])
    mergeOrdersByTotal()
  }

  const handleBoxClick = (index: number) => (event: any) => {
    function add(shiftKey: boolean, index: number, lastClickedIndex: number) {
      if (!shiftKey) {
        lastClickedIndex = index
      }
      for (let i = Math.min(index, lastClickedIndex); i < Math.min(index, lastClickedIndex) + Math.abs(lastClickedIndex - index) + 1; i++) {
        if (ordersChecked.indexOf(orders[i]._id) == -1) {
          ordersCheckedItems.current.push(orders[i])
          ordersChecked.push(orders[i]._id)
        }
      }
    }

    function remove(shiftKey: boolean, index: number, lastClickedIndex: number) {
      ordersChecked.splice(ordersChecked.indexOf(orders[index]._id), 1)
      ordersCheckedItems.current.forEach((item: any, item_index: number) => {
        if (orders[index]._id === item._id) {
          ordersCheckedItems.current.splice(item_index, 1)
        }
      })
    }
    if (ordersChecked.indexOf(orders[index]._id) == -1) {
      add(event.shiftKey, index, lastClickedIndex.current)
    } else {
      remove(event.shiftKey, index, lastClickedIndex.current)
    }
    mergeOrdersByTotal()
    setOrdersChecked([...ordersChecked])
    lastClickedIndex.current = index
  }

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPageClick = () => {
    if (orders.length == pageLimit) {
      setCurrentPage(currentPage + 1)
    } else {
      props.newAlert('已是最后一页', 'warning')
    }
  }

  const mergeOrdersByTotal = () => {
    console.log('generateOrdersByTotal', ordersCheckedItems)
    let ordersByCompanyTemp: { [name: string]: any[] } = {}
    let ordersByTotalTemp: { [name: string]: { [name: string]: any[] } } = {}
    let imgUrlsTemp: string[] = [];
    ordersCheckedItems.current.forEach((order: any) => {//sortProductsByCompany
      order.products.forEach((productWaitToAdd: any) => {
        let hasSameProductId = false
        if (!ordersByCompanyTemp[order.company]) {
          ordersByCompanyTemp[order.company] = []
        }
        ordersByCompanyTemp[order.company].forEach((productAdded: any, productAddedIndex: number) => {
          if (productAdded.product_id === productWaitToAdd.product_id) {
            if (!((ordersByCompanyTemp[order.company][productAddedIndex] as any).name_zh === productWaitToAdd.name_zh
              && (ordersByCompanyTemp[order.company][productAddedIndex] as any).name_jp === productWaitToAdd.name_jp
              && (ordersByCompanyTemp[order.company][productAddedIndex] as any).product_code === productWaitToAdd.product_code)
            ) {
              (ordersByCompanyTemp[order.company][productAddedIndex] as any).name_zh += '【可能不是正确的商品】'
            }
            (ordersByCompanyTemp[order.company][productAddedIndex] as any).order_number_pack += productWaitToAdd.order_number_pack;
            (ordersByCompanyTemp[order.company][productAddedIndex] as any).order_number_carton += productWaitToAdd.order_number_carton;
            hasSameProductId = true;
          }
        })
        if (!hasSameProductId) {
          ordersByCompanyTemp[order.company].push(JSON.parse(JSON.stringify(productWaitToAdd)))
          imgUrlsTemp.push(productWaitToAdd.mainImage)
        }
        if (!ordersByTotalTemp[productWaitToAdd.product_id]) {
          ordersByTotalTemp[productWaitToAdd.product_id] = JSON.parse(JSON.stringify(productWaitToAdd))
        } else {
          if (!((ordersByTotalTemp[productWaitToAdd.product_id] as any).name_zh === productWaitToAdd.name_zh
            && (ordersByTotalTemp[productWaitToAdd.product_id] as any).name_jp === productWaitToAdd.name_jp
            && (ordersByTotalTemp[productWaitToAdd.product_id] as any).product_code === productWaitToAdd.product_code)
          ) {
            (ordersByTotalTemp[productWaitToAdd.product_id] as any).name_zh += '【可能不是正确的商品】'
          }
          (ordersByTotalTemp[productWaitToAdd.product_id] as any).order_number_pack += productWaitToAdd.order_number_pack;
          (ordersByTotalTemp[productWaitToAdd.product_id] as any).order_number_carton += productWaitToAdd.order_number_carton;
          (ordersByTotalTemp[productWaitToAdd.product_id] as any).real_number += productWaitToAdd.real_number ?? 0;
        }
        if (productWaitToAdd.order_number_pack != 0) {
          ordersByTotalTemp[productWaitToAdd.product_id][order.company + '_order_number_pack'] = productWaitToAdd.order_number_pack
        }
        if (productWaitToAdd.order_number_carton != 0) {
          ordersByTotalTemp[productWaitToAdd.product_id][order.company + '_order_number_carton'] = productWaitToAdd.order_number_carton
        }
      })
    });

    ordersByTotal.current = ordersByTotalTemp
    ordersByCompany.current = ordersByCompanyTemp;
    imgUrls.current = imgUrlsTemp
    // console.log(imgUrlsTemp)
  }
  const handleExportClick = () => {
    generateXLSX(ordersByTotal.current, ordersByCompany.current, imgUrls.current)
  }

  const handleProductsExportClick = async () => {
    setBrandsChooseOpen(true)
    setBrandsChosen([])

  }

  const handleExportInvoiceClick = () => {

    console.log(ordersCheckedItems)
    exportInvoice()
  }

  const handleProductsExport = async () => {
    if (dropedFiles.length > 0 && brandsChosen.length > 0) {
      const worksheetsTemp = (productWorkbook.current as any).worksheets
      worksheetsTemp.forEach((sheet: any) => {
        if (brandsChosen.indexOf(sheet.name) == -1) {
          (productWorkbook.current as any).removeWorksheet(sheet.id)
        }
      });

      let order_number_pack_count = 0;
      (productWorkbook.current as any).worksheets.forEach((sheet: any) => {
        sheet.unprotect()
        sheet.getRow(1).getCell(17).value = '定量'
        sheet.getRow(1).getCell(18).value = '余量'
        sheet.getRow(1).eachCell(function (cell: ExcelJS.Cell, colNumber: number) {
          if (colNumber <= 18) {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '4F81BD' } }
            cell.font = { color: { argb: 'FFFFFF' }, bold: true }
          }
        });

        // sheet.getRow(1).getCell(18).fill = { type: 'pattern', pattern: 'solid', color: { argb: 'FFFFFF' }, fgColor: { argb: '4F81BD' } }
        // sheet.getRow(1).getCell(18).font = { color: { argb: 'FFFFFF' }, bold: true }
        sheet.columns[17].width = 12
        sheet.getRows(2, sheet.rowCount - 1).forEach((row: any) => {
          row.getCell(15).value = (ordersByTotal.current as any)[row.getCell(1).value]?.order_number_pack ?? 0
          row.getCell(16).value = (ordersByTotal.current as any)[row.getCell(1).value]?.order_number_carton ?? 0

          row.getCell(18).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C5D9F1' } }
          row.getCell(18).value = 0
          row.getCell(18).border = { right: { style: 'thin', color: { argb: 'FFFFFFFF' } }, bottom: { style: 'thin', color: { argb: '4F81BD' } } };
          row.getCell(18).protection = { locked: false, hidden: true, };
          // if (!isNaN((ordersByTotal.current as any)[row.getCell(1).value]?.order_number_pack)) {
          //   order_number_pack_count++
          // }
        })
      })
      // if (order_number_pack_count < Object.keys(ordersByTotal.current).length) {
      //   props.newAlert('商品统计未包含全部已下订单商品', 'error')
      // } else {
      const buffer = await (productWorkbook.current as any).xlsx.writeBuffer()
      const blob = new Blob([buffer], { type: "application/vnd.ms-excel" })
      savefile(`商品-订单-统计${new Date().toLocaleString().replaceAll('/', '-')}.xlsx`, blob)
      setBrandsChooseOpen(false)
      handleFilesChange(dropedFiles)
      // }
    } else {
      alert('未选择商品表格或品牌')
    }
  }

  const exportInvoice = async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'opsloth-cms';
    workbook.created = new Date();
    workbook.modified = workbook.created;
    workbook.lastPrinted = workbook.created;
    workbook.properties.date1904 = true;
    let sheet1 = workbook.addWorksheet('Sheet1')
    sheet1.getColumn('N').width = 14
    sheet1.getColumn('O').width = 14
    sheet1.getColumn('P').width = 22
    sheet1.getColumn('Q').width = 30
    sheet1.addRow(['INVOICE'])
    sheet1.getRow(1).height = 25
    sheet1.mergeCells('A1:M1')
    sheet1.getCell('A1').style = { font: { name: 'ＭＳ Ｐゴシック', size: 16, bold: true }, alignment: { vertical: 'middle', horizontal: 'center' } }
    sheet1.addRow(['INVOICE NO. 合同会社（Capy Monster）カピモンスター', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'DATE :', new Date(workbook.created).toDateString().substring(new Date(workbook.created).toDateString().indexOf(' ') + 1, new Date(workbook.created).toDateString().length).replaceAll(' ', '-')], 'i')
    for (let i = 0; i < 20; i++) {
      sheet1.getCell(`${String.fromCharCode(i + 65)}${2}`).style = { font: { name: 'ＭＳ Ｐゴシック', size: 11 } };
    }
    sheet1.getRow(2).height = 18
    sheet1.addRow(['INVOICE OF TOY',], 'i')
    sheet1.addRow(['CNSGNEE : 合同会社（Capy Monster）カピモンスター',], 'i')
    sheet1.addRow(['ADDRESS : 〒352-0006埼玉県新座市新座１丁目３番２号',], 'i')
    sheet1.addRow(['               TEL 080 4192 6407',], 'i')
    sheet1.addRow(['SHIPPER : Matching World Inc. ',], 'i')
    sheet1.addRow(['ADDRESS : 1-11-6 Bakuro-cho,Nihon bashi , Chuou-ku, Tokyo 103-0002 ,Japan',], 'i')
    sheet1.addRow(['               TEL +81-3-6803-5603/ FAX +81-3-6803-5610',], 'i')
    sheet1.addRow(['FROM : TOKYO     TO : TOKYO',], 'i')
    sheet1.addRow(['PAYMENT : 100% T/T REMITTANCE  in advance   *Please see the reference below.',], 'i')
    sheet1.addRow(['',], 'i')
    sheet1.addRow(['MARKS', 'JAN', '', 'Description of GOODS', '', '', '', '', '', '', '箱/个', 'AMOUNT', '总数', '@JPY', '单价', 'TOTAL', '中文名', '材质', '品类', '品牌'], 'i');
    for (let k = 0; k < 16; k++) {
      sheet1.getCell(`${String.fromCharCode(k + 65)}${13}`).style = {
        border: {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } }
        },
        font: { name: 'ＭＳ Ｐゴシック', size: 11 }
      };
    }
    sheet1.mergeCells('B13:C13')
    sheet1.mergeCells('D13:J13')
    Object.entries(ordersByTotal.current).forEach((item: any, index: number) => {
      sheet1.addRow([
        index + 1, //A14
        item[1].product_code.trim(), //B14
        '', //C14
        item[1].name_jp.trim(), //D14
        '', //E14
        '', //F14
        '', //G14
        '', //H14
        '', //I14
        '', //J14
        item[1].carton_spec, //K14
        item[1].order_number_carton - (item[1].custom_carton ?? 0), //L14
        item[1].carton_spec * item[1].order_number_carton, //M14
        '', //N14
        { formula: `P${13 + index + 1}/M${13 + index + 1}` }, //O14
        { formula: `L${13 + index + 1}*N${13 + index + 1}` }, //P14
        item[1].name_zh.trim(), //Q14
        '', //R14
        '', //S14
        item[1].brand //T14
      ]);
      for (let i = 0; i < 16; i++) {
        sheet1.getCell(`${String.fromCharCode(i + 65)}${13 + 1 + index}`).style = {
          border: {
            top: { style: 'thin', color: { argb: '00000000' } },
            left: { style: 'thin', color: { argb: '00000000' } },
            bottom: { style: 'thin', color: { argb: '00000000' } },
            right: { style: 'thin', color: { argb: '00000000' } }
          },
          font: { name: 'ＭＳ Ｐゴシック', size: 11 }
        }
      }
      sheet1.getCell(`N${13 + index + 1}`).numFmt = '"¥"#,##0;[Red]\-"¥"#,##0'
      sheet1.getCell(`O${13 + index + 1}`).numFmt = '"¥"#,##0;[Red]\-"¥"#,##0'
      sheet1.getCell(`P${13 + index + 1}`).numFmt = '"¥"#,##0;[Red]\-"¥"#,##0'
      sheet1.mergeCells(`B${13 + index + 1}:C${13 + index + 1}`)
      sheet1.mergeCells(`D${13 + index + 1}:J${13 + index + 1}`)
    })
    sheet1.addRow('')
    sheet1.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Total Invoice Value'])
    sheet1.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', { formula: `SUM(P14:p${13 + Object.entries(ordersByTotal.current).length})` }])
    sheet1.getCell(`P${13 + 3 + Object.entries(ordersByTotal.current).length}`).numFmt = '"¥"#,##0;[Red]\-"¥"#,##0'
    sheet1.addRow(['', 'BANK : The Bank of Tokyo-Mitsubishi UFJ, Ltd'])
    sheet1.addRow(['', 'BRANCH : Asakusa'])
    sheet1.addRow(['', 'A/C NO. : 4599358'])
    sheet1.addRow(['', 'A/C NAME : MATCHING WORLD INC.'])

    for (let j = 0; j < 3; j++) {
      for (let i = 0; i < 16; i++) {
        sheet1.getCell(`${String.fromCharCode(i + 65)}${13 + 1 + j + Object.entries(ordersByTotal.current).length}`).border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } }
        };
      }
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.ms-excel" })
    savefile(`INVOICE ${new Date().toLocaleString().replaceAll('/', '-')}.xlsx`, blob)
    setExportPercentage(0)
  }

  const handleFilesChange = (File: any) => {
    setDropedFiles(File)
    setDropedFilesLoaded(false)
    if (File.length > 0) {
      var reader = new FileReader();
      reader.readAsArrayBuffer(File[0]);
      reader.onload = async (evt: any) => {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(evt.currentTarget.result)
        productWorkbook.current = workbook
        setDropedFilesLoaded(true)
      }
    } else {
      productWorkbook.current = {}
      setDropedFilesLoaded(false)
    }
  }

  const downloadImgs = (imgUrl: string) => {
    return new Promise(async (resolve) => {
      if (imgUrl && imgUrl != '/') {
        const res = await axios.get(getSmallerImgUrl(imgUrl), { responseType: "blob" })
        var reader = new window.FileReader();
        reader.readAsDataURL(res.data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          resolve(imageDataUrl)
        }
      } else {
        resolve(minimal_base64_img)
      }
    })
  }

  const generateXLSX = async (ordersByTotal: {}, ordersByCompany: any, imgUrls: Array<string>) => {
    //获取图片
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'opsloth-cms';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.properties.date1904 = true;
    const sheets: { [brand: string]: any } = {}
    sheets['Total'] = workbook.addWorksheet('Total')

    let imgIds: { [url: string]: number } = {}
    for (let i = 0; i < imgUrls.length; i++) {
      const url: string = imgUrls[i]
      const base64 = await downloadImgs(url.replace('http://', 'https://'))
      imgIds[url] = workbook.addImage({
        base64: base64 as string,
        extension: 'png',
      })
      setExportPercentage(parseFloat((i / imgUrls.length * 100).toFixed(1)))
    }
    //根据客户区分
    const totalColumns: { name: string, width?: number, hidden?: boolean, totalsRowLabel?: string, fill?: any, schema_key?: any }[] = [{ name: '_id', hidden: true, totalsRowLabel: 'none' }]
    const columns: { name: string, width?: number, hidden?: boolean, totalsRowLabel?: string, fill?: any }[] = [{ name: '_id', hidden: true, totalsRowLabel: 'none' }]
    headers_key.forEach((schema: string, schemaIndex: number) => {
      let widthTemp = 16
      if (schema === 'name_jp') {
        widthTemp = 46
      } else if (schema === 'order_number_pack' ||
        schema === 'order_number_carton' ||
        schema === 'order_remark' ||
        schema === 'pack_spec' ||
        schema === 'carton_spec' ||
        schema === 'order_remark' ||
        schema === 'order_price'
      ) {
        widthTemp = 12
      } else if (schema === 'mainImage') {
        widthTemp = 14
      } else if (schema === 'name_zh') {
        widthTemp = 30
      } else if (schema === 'order') {
        widthTemp = 5
      }
      totalColumns.push({ name: headers[schemaIndex], width: widthTemp, schema_key: schema })
      columns.push({ name: headers[schemaIndex], width: widthTemp })
    })
    //根据客户区分
    Object.keys(ordersByCompany).forEach((company: string) => {

      sheets[company] = workbook.addWorksheet(company)
      sheets[company].getRow(1).height = 40
      sheets[company].getRow(1).alignment = { vertical: 'middle', horizontal: 'center' }
      sheets[company].getRow(1).wrapText = true
      sheets[company].getRow(1).border = { left: { style: 'thin', color: { argb: 'FFFFFFFF' } }, };

      let table = {
        name: `${company.replaceAll(' ', '_')}`, ref: 'A1', headRow: false, totalsRow: false, style: { theme: 'TableStyleLight9', showRowStripes: true }, columns: [{}], rows: [{}], commit: () => { }
      }
      let rows: (string | number)[][] = []
      ordersByCompany[company].forEach((product: any, product_index: number) => {
        sheets[company].getRow(product_index + 2).height = 120
        sheets[company].getRow(product_index + 2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        let row = [product.product_id]
        headers_key.forEach(schema => {
          if (schema === 'order_number_pack' || schema === 'order_number_carton' || schema === 'order_remark') {
            row.push(product[schema] ?? 0)
            if (product[schema] > 0) {
              sheets[company].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC5D9F1' } }
              sheets[company].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).border = { right: { style: 'thin', color: { argb: 'FFFFFFFF' } } };
              sheets[company].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).protection = { locked: false, hidden: true, };
            }
          } else if (schema === 'mainImage') {
            sheets[company].addImage(imgIds[product[schema]], {
              tl: { col: 2, row: product_index + 1 },
              br: { col: 3, row: product_index + 2 },
              hyperlinks: {
                hyperlink: product[schema],
                tooltip: product[schema]
              }
            })
            row.push(product[schema])
          } else {
            row.push(schema === 'order' ? product_index + 1 : product[schema])
          }
        })
        rows.push(row)
      })
      totalColumns.push(...[{ name: company + '(袋)', width: 18, schema_key: company + '_order_number_pack' }, { name: company + '(箱)', width: 18, schema_key: company + '_order_number_carton' }])
      table.rows = rows
      table.columns = columns
      sheets[company].columns = columns
      sheets[company].addTable(table)
    })

    //总计
    sheets['Total'].getRow(1).height = 40
    sheets['Total'].getRow(1).alignment = { vertical: 'middle', horizontal: 'center' }
    sheets['Total'].getRow(1).wrapText = true
    sheets['Total'].getRow(1).border = { left: { style: 'thin', color: { argb: 'FFFFFFFF' } }, };
    let tableTotal = {
      name: `${'Total'.replaceAll(' ', '_')}`, ref: 'A1', headRow: false, totalsRow: false, style: { theme: 'TableStyleLight9', showRowStripes: true }, columns: [{}], rows: [{}], commit: () => { }
    }
    let rows: (string | number)[][] = []
    //总计
    Object.keys(ordersByTotal).forEach((product_id: any, product_index: number) => {
      const product = (ordersByTotal as any)[product_id]

      sheets['Total'].getRow(product_index + 2).height = 120
      sheets['Total'].getRow(product_index + 2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      let row = [product.product_id]
      totalColumns.forEach((column, columnIndex: number) => {
        const schema = column.schema_key
        if (schema === 'order_number_pack' || schema === 'order_number_carton' || schema === 'order_remark') {
          row.push(product[schema] ?? 0)
          if (product[schema] > 0) {
            sheets['Total'].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC5D9F1' } }
            sheets['Total'].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).border = { right: { style: 'thin', color: { argb: 'FFFFFFFF' } } };
            sheets['Total'].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).protection = { locked: false, hidden: true, };
          }
        } else if (schema === 'price_cny_cn' || schema === 'price_cny_jp') {
          row.push('/')
        } else if (schema === 'mainImage') {
          sheets['Total'].addImage(3, {
            tl: { col: 2, row: product_index + 1 },
            br: { col: 3, row: product_index + 2 },
            hyperlinks: {
              hyperlink: product[schema],
              tooltip: product[schema]
            }
          })
          row.push(product[schema])
        } else {
          if (schema) {
            row.push(schema === 'order' ? product_index + 1 : product[schema])
            if (schema.indexOf('_order_number_pack') >= 0) {
              sheets['Total'].getRow(product_index + 2).getCell(columnIndex + 1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DAEEF3' } }
              sheets['Total'].getRow(product_index + 2).getCell(columnIndex + 1).border = { right: { style: 'thin', color: { argb: 'FFFFFFFF' } } };
              sheets['Total'].getRow(product_index + 2).getCell(columnIndex + 1).protection = { locked: false, hidden: true, };
            }
            if (schema.indexOf('_order_number_carton') >= 0) {
              sheets['Total'].getRow(product_index + 2).getCell(columnIndex + 1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FDE9D9' } }
              sheets['Total'].getRow(product_index + 2).getCell(columnIndex + 1).border = { right: { style: 'thin', color: { argb: 'FFFFFFFF' } } };
              sheets['Total'].getRow(product_index + 2).getCell(columnIndex + 1).protection = { locked: false, hidden: true, };
            }
          }
        }
      })
      rows.push(row)
    })
    tableTotal.rows = rows
    tableTotal.columns = totalColumns
    sheets['Total'].columns = totalColumns
    sheets['Total'].addTable(tableTotal)

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.ms-excel" })
    savefile(`订单统计${new Date().toLocaleString().replaceAll('/', '-')}.xlsx`, blob)
    setExportPercentage(0)
  }

  React.useEffect(() => {
    getOrders()
  }, [currentPage])

  React.useEffect(() => {
    if (props.open){
      getOrders(true)
    }
  }, [props.open])

  return (
    <Dialog
      open={props.open}
      maxWidth="xl"
      onClose={() => { props.setOpen(false) }}
    >
      <Container
        sx={{ p: 1 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', py: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mr: 3 }}>{`已选中${ordersChecked.filter(v => v).length}个订单`}</Typography>
            {
              <Backdrop
                sx={{ background: '#00000044', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={exportPercentage > 0}
              >
                <Box sx={{ background: '#fff', display: 'flex', borderRadius: '100%' }}>
                  <CircularStatic value={exportPercentage} />
                </Box>
              </Backdrop>
            }
          </Box>
          {
            props.category != '海关' && <Box>
              <Button color="warning" variant="contained" size="small" onClick={handleCancelAllCheckedClick}>取消选择</Button>
              <Button color="primary" variant="contained" size="small" onClick={handleExportClick} sx={{ mx: 2 }}>订单统计</Button>
              <Button color="primary" variant="contained" size="small" onClick={handleProductsExportClick} disabled={!dropedFilesLoaded}>商品统计</Button>
            </Box>
          }
          {
            props.category == '海关' && <Box sx={{ pl: 40 }}>
              <Button color="primary" variant="contained" size="small" onClick={handleExportInvoiceClick}>生成 INVOICE</Button>
            </Box>
          }
          {
            props.category != '海关' && <DragAndDrop
              dropedFiles={dropedFiles}
              setDropedFiles={handleFilesChange}
              accept='.xlsx, .xls'
            ></DragAndDrop>
          }
        </Box>

        <Grid2 container spacing={1}>
          {
            orders.map((order: any, index: number) =>
              <Grid2
                key={order._id}
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
              >
                <Container
                  id={`OrdersExportDialog_orders_container_${order._id}`}
                  sx={{ border: 'solid 1px', p: 1, cursor: 'pointer', userSelect: 'none' }}
                  onMouseOver={handleOnBoxMouseOver}
                  onMouseLeave={handleOnBoxMouseLeave}
                  onClick={handleBoxClick(index)}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Chip size="small" label={order.company} />
                    <Checkbox sx={{ p: 0 }} color="primary" checked={ordersChecked.indexOf(order._id) >= 0} disableRipple />
                  </Box>
                  {
                    props.schema.category.label != '海关' && <Typography variant="body2">订单金额：{order.price_cny}</Typography>
                  }

                  <Typography variant="body2">{order.order_status}-{order.cargo_sent}</Typography>
                  <Typography variant="body2">备注：{order.remark}</Typography>
                  <Typography variant="body2">{new Date(order.createdAt).toLocaleString()}</Typography>
                </Container>
              </Grid2>
            )
          }
        </Grid2>
      </Container>
      <Box sx={{ m: 'auto', p: 1, display: 'flex', }}>
        <Button
          onClick={handlePrevPageClick}
        >上一页</Button>
        <Button
          variant="text"
        >{currentPage + 1}</Button>
        <Button
          onClick={handleNextPageClick}
        >下一页</Button>
      </Box>
      <Dialog
        open={brandsChooseOpen}
        onClose={() => { setBrandsChooseOpen(false) }}
        maxWidth='xl'
      >
        <Box
          sx={{ display: 'flex', flexWrap: 'wrap', p: 2 }}
        >
          {
            dropedFilesLoaded && (productWorkbook.current as any).worksheets.map((item: { name: string }) => <Chip
              size='small'
              key={item.name}
              sx={{ m: .5, cursor: 'pointer' }}
              label={item.name}
              color={brandsChosen.includes(item.name) ? 'primary' : 'default'}
              onClick={() => {
                if (!brandsChosen.includes(item.name)) {
                  brandsChosen.push(item.name)
                } else {
                  brandsChosen.splice(brandsChosen.indexOf(item.name), 1)
                }
                setBrandsChosen([...brandsChosen])
              }}
            />)
          }
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 2 }}>
          <Button onClick={handleProductsExport}>确定</Button>
          <Button onClick={() => { setBrandsChosen((productWorkbook.current as any).worksheets.map((sheet: { name: any }) => sheet.name)) }}>全选</Button>
          <Button onClick={() => { setBrandsChooseOpen(false) }}>取消</Button>
        </Box>
      </Dialog>
    </Dialog>
  )
}

export default OrdersExportDialog