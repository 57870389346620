import React from 'react'
import { Box, Button, Chip, Container, Dialog } from "@mui/material"
import select_items from '../../utils/select_items'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

function TagsChooseRule(props: any) {
  const ruleLabels = ['标签', '价格', '品牌']

  const ruleColumns = { "标签": 'tag', '价格': 'price_jpy', '品牌': 'brand' }
  const [rulesClicked, setRulesClicked] = React.useState('标签')
  const [ruleGenerated, setRuleGenerated] = React.useState<{ [key: string]: [] }>({ tag: [], price_jpy: [], brand: [] })


  const handleChipClicked = (value: string | number) => (event: any) => {
    if (!(ruleGenerated as any)[(ruleColumns as any)[rulesClicked]].includes(value)) {
      (ruleGenerated as any)[(ruleColumns as any)[rulesClicked]].push(value)
    } else {
      (ruleGenerated as any)[(ruleColumns as any)[rulesClicked]].splice((ruleGenerated as any)[(ruleColumns as any)[rulesClicked]].indexOf(value), 1)
    }
    setRuleGenerated({ ...ruleGenerated })
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
    >
      <Container
        maxWidth='xl'
        sx={{ p: 2 }}
      >
        <Grid2 container>
          {
            ruleLabels.map((item) =>
              <Grid2
                key={item}
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
              >
                <Button
                  variant={item == rulesClicked ? 'contained' : 'outlined'}
                  sx={{ width: '10em', mb: 2 }}
                  onClick={() => {
                    setRulesClicked(item)
                  }}
                >{item}{(ruleGenerated as any)[(ruleColumns as any)[item]].length > 0 ? '（' + (ruleGenerated as any)[(ruleColumns as any)[item]].length + '）' : ''}</Button>

              </Grid2>
            )
          }
          {
            ((select_items as any)[(ruleColumns as any)[rulesClicked]]).map((item: any) =>
              <Chip
                key={item.label ?? 'UNKNOWN'}
                size="small"
                sx={{ m: .5, cursor: 'pointer' }}
                label={item.label}
                onClick={handleChipClicked(item.value)}
                color={(ruleGenerated as any)[(ruleColumns as any)[rulesClicked]].includes(item.value) ? 'primary' : 'default'}
              />
            )
          }
        </Grid2>
      </Container>
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 2 }}>
        <Button onClick={() => {
          props.onRuleChosen(ruleGenerated);
          setRuleGenerated({ tags: [], price_jpy: [], brand: [] });
          return props.onClose()
        }}
        >确定</Button>
        <Button onClick={() => { setRuleGenerated({ tags: [], price_jpy: [], brand: [] }) }}>清空</Button>
        <Button onClick={props.onClose}>取消</Button>
      </Box>
    </Dialog >
  )
}

export default TagsChooseRule