import React from 'react'
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import DragAndDrop from "../public/DragAndDrop"
import ExcelTableXLSX from './ExcelTableXLSX'
import ExcelJS from 'exceljs'


function ExcelAddDialog(props: any) {
  const [dropedFiles, setDropedFiles] = React.useState<File[]>([])
  const [dropedWorkbook, setDropedWorkbook] = React.useState<ExcelJS.Workbook>(new ExcelJS.Workbook())

  const handleFilesChange = (files: File[]) => {
    setDropedFiles(files)
    readXLSX(files[0])
  }

  const readXLSX = async (file: File) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = async (evt: any) => {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(evt.currentTarget.result)
        analyzeXLSX(workbook)
      }
      reader.readAsArrayBuffer(file);
    } else {
      setDropedWorkbook(new ExcelJS.Workbook())
    }
  }

  const analyzeXLSX = (workbook: ExcelJS.Workbook) => {
    if (workbook) {
      setDropedWorkbook(workbook)
    }
  }

  return <Dialog
    fullWidth={true}
    maxWidth='xl'
    open={props.open}
    onClose={props.onClose}
  >
    <DialogTitle>{'批量添加商品'}</DialogTitle>
    <DialogContent>
      <DragAndDrop
        dropedFiles={dropedFiles}
        setDropedFiles={handleFilesChange}
        accept='.xlsx, .xls'
        multiple={false}
      ></DragAndDrop>

      <ExcelTableXLSX
        sheet={dropedWorkbook.worksheets[0]}
        sx={{ my: 2 }}
      />
    </DialogContent>
    <DialogActions>
      <Button
        sx={{ mx: 1 }}
        onClick={() => { props.onClose(false) }}
      >
        {"取消"}
      </Button>

      <Button
        onClick={() => {
          if (window.confirm('确认创建订单？')) {
            if (JSON.stringify(dropedWorkbook.worksheets[0].getRow(1).values) != '[null,"货号","日文名称","中文名称","成本(元/个)","成本(日元/个)","日元售价","代理版(元/个)","日版(元/个)","每盒数量","每箱数量","截单日期","发售日期"]') {
              props.newAlert('模板错误', 'error')
            }else{
              props.onSubmit(dropedWorkbook.worksheets[0])
              setDropedWorkbook(new ExcelJS.Workbook())
              setDropedFiles([])
              props.onClose(false)
            }
          }
        }}
      >
        {"提交"}
      </Button>
    </DialogActions>
  </Dialog>
}

export default ExcelAddDialog