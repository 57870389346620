import React, { } from 'react'
import { Dialog, Container, Chip, Button, Box, Typography, Checkbox } from "@mui/material"
import CMSApi from '../../utils/opsloth_cms_service_api'
import OrderTableArray from "../Orders/OrderTableArray"
import { CheckBox, Warning } from '@mui/icons-material'
const headers = ['序号', '例图', '品牌', '货号', '日文名称', '中文名称', '日元售价', '代理版(元/个)', '日版(元/个)', '每袋数量', '每箱数量', '订购(袋)', '订购(箱)', '备注', '金额', '发货状态']
const headers_key = ['order', 'mainImage', 'brand', 'product_code', 'name_jp', 'name_zh', 'price_jpy', 'price_cny_cn', 'price_cny_jp', 'pack_spec', 'carton_spec', 'order_number_pack', 'order_number_carton', 'order_remark', 'order_price', 'cargo_sent']
const headers_statistics = ['序号', '例图', '品牌', '货号', '日文名称', '中文名称', '日元售价', '代理版(元/个)', '日版(元/个)', '每袋数量', '每箱数量', '订购(袋)', '订购(箱)', '备注', '金额', '发货状态']
const headers_key_statistics = ['order', 'mainImage', 'brand', 'product_code', 'name_jp', 'name_zh', 'price_jpy', 'price_cny_cn', 'price_cny_jp', 'pack_spec', 'carton_spec', 'order_number_pack', 'order_number_carton', 'order_remark', 'order_price', 'cargo_sent']
const dataModel = new CMSApi('orders')
const pageSize = 10

type product = {
  product_id: string,
  mainImage: string,
  brand: string,
  product_code: string,
  name_jp: string,
  name_zh: string,
  price_jpy: number,
  price_cny_cn: number,
  price_cny_jp: number,
  pack_spec: number,
  carton_spec: number,
  launch_date: string,
  arrived: string,
  order_number_pack: number,
  order_number_carton: number,
  order_remark: string,
  cargo_sent: string
}
type order = {
  products: product[],
  customer_id: string,
  category: string,
  company: string,
  price_cny: number,
  deposit_cny: number,
  balance_cny: number,
  logistic_cny: number,
  cargo_sent: string,
  cargo_no: string,
  order_status: string,
  remark: string,
  createdAt: string
}

function TableListOrdersByProduct(props: any) {
  let [orderTableRes, setOrderTableRes] = React.useState<order[]>([])
  let [orderTableResFiltered, setOrderTableResFiltered] = React.useState<order[]>([])
  let [orderTableResStatistics, setOrderTableResStatistics] = React.useState<object>()
  let [currentPage, setCurrentPage] = React.useState(0)
  let [companys, setCompanys] = React.useState<string[]>([])
  let [companysChosen, setCompanysChosen] = React.useState<string[]>([])

  const handleGetProductOrderedList = async (currentPage?: number) => {
    const ordersRes: any = await getOrdersByProducts(currentPage ?? 0)
    setOrderTableRes([...ordersRes.data])
    sortOrdersTable(ordersRes.data)
  }

  const getOrdersByProducts = (currentPage: number) => {
    return new Promise(async (resolve, reject) => {
      resolve(
        await dataModel
          .setFilter({ deleted: { $ne: true }, 'products.product_id': { $in: props.checkboxSelected } })
          // .setOptions({ limit: pageSize, skip: currentPage * pageSize })
          .setField("-cargo_sent -cargo_no -nickname -updatedAt -customer_id")
          .get()
      )
    })
  }

  const sortOrdersTable = (orders: order[]) => {
    let products: product[] = []
    let productsFinal: any[] = []
    let companysTemp:string[] = []
    orders.forEach((order: order, order_index: number) => {
      if (companysTemp.indexOf(order.company) == -1) {
        companysTemp.push(order.company)
      }
      if (order.order_status && order.order_status != "创建订单"){
        order.products.forEach((product: any, product_index:number) => {
          orders[order_index].products[product_index].cargo_sent = (orders[order_index].products[product_index].cargo_sent ?? '') +  " " + order.order_status
        })
      }
      let orderTemp: order = JSON.parse(JSON.stringify(order))
      orderTemp.products.forEach(product => {
        product.order_remark = order.company + '\n' + new Date(order.createdAt).toLocaleString() + '\n' + product.order_remark
      })
      products.push(...orderTemp.products)
    })
    setCompanys([...companysTemp])
    setCompanysChosen([...companysTemp])
    products = products.filter(a => props.checkboxSelected.indexOf(a.product_id) >= 0)
    products.sort((a: product, b: product) => a.product_id.localeCompare(b.product_id))

    let lastProductIdTemp = products[0]?.product_id ?? ""
    let lastProductPacks = 0
    let lastProductCartons = 0
    products.forEach((product, index) => {
      if (product.product_id == lastProductIdTemp) {
        productsFinal.push(product)
        lastProductPacks += product.order_number_pack
        lastProductCartons += product.order_number_carton
      } else {
        productsFinal.push({ order_number_pack: lastProductPacks, order_number_carton: lastProductCartons })
        productsFinal.push(product)
        lastProductPacks = product.order_number_pack
        lastProductCartons = product.order_number_carton
      }
      if (index == products.length - 1) {
        productsFinal.push({ order_number_pack: lastProductPacks, order_number_carton: lastProductCartons })
      }
      lastProductIdTemp = product.product_id

    })
    setOrderTableResStatistics({ products: [...productsFinal] })
  }

  const handleCompanyChosenChange = () => {
    let orderTableResFiltered: order[] = []
    orderTableResFiltered = orderTableRes?.filter(item => companysChosen.indexOf(item.company) >= 0)
    setOrderTableResFiltered([...orderTableResFiltered])
  }

  const handlePrePageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPageClick = () => {
    if (orderTableRes.length == pageSize && orderTableRes.length > 0) {
      setCurrentPage(currentPage + 1)
    }
  }

  React.useEffect(() => {
    if (props.ordersTableShow) {
      handleGetProductOrderedList()
      setCompanys([])
      setCompanysChosen([])
      setOrderTableResFiltered([])
    } else {
      setCurrentPage(0)
    }
  }, [props.ordersTableShow])

  React.useEffect(() => {
    handleCompanyChosenChange()
  }, [companysChosen])

  React.useEffect(() => {
    handleGetProductOrderedList(currentPage)
  }, [currentPage])

  return (
    <Dialog
      maxWidth='xl'
      open={props.ordersTableShow}
      onClose={() => { props.setOrdersTableShow(false) }}
    >
      <Container
        maxWidth='xl'
        sx={{ my: 2 }}
      >
        <Box
          sx={{ background: "#d32f2f", py: .5, px: 1, fontSize: '1em', color: "white" }}
        >
          统计数据
        </Box>
        <OrderTableArray
          schema={props.schema}
          order={orderTableResStatistics}
          headers={headers}
          headers_key={headers_key}
          checkboxSelected={props.checkboxSelected}
        ></OrderTableArray>
        <Box sx={{ background: '#d9e4ff', mt: 2, p: 2 }}>
          {
            companys.map((item) =>
              <Chip
                size='small'
                key={item}
                sx={{ m: .5, cursor: 'pointer' }}
                label={item}
                color={companysChosen.includes(item) ? 'primary' : 'default'}
                onClick={() => {
                  if (!companysChosen.includes(item)) {
                    companysChosen.push(item)
                  } else {
                    companysChosen.splice(companysChosen.indexOf(item), 1)
                  }
                  setCompanysChosen([...companysChosen])
                }}
              />

            )
          }
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button size="small" variant="outlined" sx={{ mx: 2 }} onClick={() => {
              setCompanysChosen([...companys])
            }}>全选</Button>
            <Button size="small" variant="outlined" onClick={() => {
              setCompanysChosen([])
            }}>清除所有</Button>
          </Box>
        </Box>
      </Container>

      {
        orderTableResFiltered.length >= 1 && orderTableResFiltered.map((order: any, orderIndex: number) =>
          <Container
            maxWidth='xl'
            key={order._id}
            sx={{ my: 2 }}
          >
            <Chip
              label={order.deleted ? '已作废 ' + currentPage * pageSize + orderIndex + 1 : currentPage * pageSize + orderIndex + 1}
              color='error'
              size='small'
              sx={{ mb: -1, ml: -1.5, userSelect: 'text' }}
            ></Chip>
            <Chip
              label={order.deleted ? '已作废 ' + order.company : order.company}
              color={order.deleted ? 'warning' : (order.order_status === '订单已完成' ? 'success' : 'primary')}
              size='small'
              sx={{ mb: -1, ml: 1.5, userSelect: 'text' }}
            ></Chip>
            <Chip
              label={'订单金额：' + order.price_cny + ' 元'}
              color={order.deleted ? 'warning' : (order.order_status === '订单已完成' ? 'success' : 'primary')}
              size='small'
              sx={{ mb: -1, ml: 1.5, userSelect: 'text' }}
            ></Chip>
            <Chip
              label={'已付定金：' + order.deposit_cny + ' 元'}
              color={order.deleted ? 'warning' : (order.order_status === '订单已完成' ? 'success' : 'primary')}
              size='small'
              sx={{ mb: -1, ml: 1.5, userSelect: 'text' }}
            ></Chip>
            <Chip
              label={'已付尾款：' + order.balance_cny + ' 元'}
              color={order.deleted ? 'warning' : (order.order_status === '订单已完成' ? 'success' : 'primary')}
              size='small'
              sx={{ mb: -1, ml: 1.5, userSelect: 'text' }}
            ></Chip>
            <Chip
              label={'创建时间：' + new Date(order.createdAt).toLocaleString()}
              color={order.deleted ? 'warning' : (order.order_status === '订单已完成' ? 'success' : 'primary')}
              size='small'
              sx={{ mb: -1, ml: 1.5, userSelect: 'text' }}
            ></Chip>
            <OrderTableArray
              schema={props.schema}
              order={order}
              headers={headers}
              headers_key={headers_key}
              checkboxSelected={props.checkboxSelected}
            ></OrderTableArray>
          </Container>
        )
      }
      {
        orderTableRes.length === 0 && <div style={{ margin: '2em auto 2em auto' }}>无订单</div>
      }
      {/* {
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Button onClick={handlePrePageClick} sx={{ m: '1em' }}>上一页</Button>
          <Typography sx={{ mx: '2em' }}>{currentPage + 1}</Typography>
          <Button onClick={handleNextPageClick} sx={{ m: '1em' }}>下一页</Button>
        </Box>
      } */}

    </Dialog>
  )
}

export default TableListOrdersByProduct