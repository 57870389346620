import React from 'react'
import OrdersTableList from "./OrdersTableList"
import CMSApi from '../../utils/opsloth_cms_service_api'
import { Box, Button, CardActions, Chip, Collapse, FormControlLabel, IconButton, InputAdornment, Paper, Switch, TextField, Typography } from '@mui/material'
import select_items from '../../utils/select_items'
import SearchIcon from '@mui/icons-material/Search';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

function OrdersTableDiv(props: any) {
  const [initPageLimit] = React.useState(10)
  const [pageLimit, setPageLimit] = React.useState(initPageLimit)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [orderBy, setOrderBy] = React.useState<string>('createdAt')
  const [order, setOrder] = React.useState<'desc' | 'asc'>('desc')
  const [data, setData] = React.useState<any[]>([])
  const [showAllOrders, setShowAllOrders] = React.useState(false)
  const dataModel = new CMSApi(props.schema.TABLE.name)

  const [filterCollapse, setFilterCollapse] = React.useState(false)
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [logisticsStatusSelected, setLogisticsStatusSelected] = React.useState<string[]>([])
  const [orderStatusStatusSelected, setOrderStatusStatusSelected] = React.useState<string[]>([])

  const generateFilter = () => {
    let orderStatusStatusSelectedKage = JSON.parse(JSON.stringify(orderStatusStatusSelected))
    orderStatusStatusSelectedKage.splice(orderStatusStatusSelectedKage.indexOf('定金已付'), 1)
    orderStatusStatusSelectedKage.splice(orderStatusStatusSelectedKage.indexOf('尾款已付'), 1)
    orderStatusStatusSelectedKage.splice(orderStatusStatusSelectedKage.indexOf('定金未付'), 1)
    orderStatusStatusSelectedKage.splice(orderStatusStatusSelectedKage.indexOf('尾款未付'), 1)

    let deposit_cny_filter = undefined
    let balance_cny_filter = undefined
    
    if (orderStatusStatusSelected.indexOf('定金已付') >= 0 && orderStatusStatusSelected.indexOf('定金未付') >= 0) {
      deposit_cny_filter = { $gt: 0, $eq: 0 }
    } else if (orderStatusStatusSelected.indexOf('尾款已付') >= 0 && orderStatusStatusSelected.indexOf('尾款未付') >= 0) {
      balance_cny_filter = { $gt: 0, $eq: 0 }
    } else if (orderStatusStatusSelected.indexOf('定金已付') >= 0) {
      deposit_cny_filter = { $gt: 0 }
    } else if (orderStatusStatusSelected.indexOf('尾款已付') >= 0) {
      balance_cny_filter = { $gt: 0 }
    } else if (orderStatusStatusSelected.indexOf('定金未付') >= 0) {
      deposit_cny_filter = { $eq: 0 }
    } else if (orderStatusStatusSelected.indexOf('尾款未付') >= 0) {
      balance_cny_filter = { $eq: 0 }
    }

    let filter = {
      category: props.category,
      $or:[{company: { $regex: searchKeyword }}, {"products.product_code": searchKeyword}, {"products.name_zh": {$regex: searchKeyword}}, {"products.name_jp": {$regex: searchKeyword}}],
      deposit_cny: deposit_cny_filter,
      balance_cny: balance_cny_filter,
      cargo_sent: logisticsStatusSelected.length > 0 ? { $in: logisticsStatusSelected } : undefined,
      order_status: orderStatusStatusSelectedKage.length > 0 ? { $in: orderStatusStatusSelectedKage } : undefined,
      deleted: { $ne: true }
    }
    return filter
  }

  const getData = async () => {
    const res: any = await dataModel
    .setFilter(showAllOrders ? {category: props.category} : generateFilter())
    .setOptions({
      skip: currentPage * pageLimit,
      limit: pageLimit,
      sort: (order === 'desc' ? '-' : '+') + orderBy
    }).get()
    console.log(res.data)
    setData(res.data)
  }

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPageClick = () => {
    if (data.length == pageLimit) {
      setCurrentPage(currentPage + 1)
    } else {
      props.newAlert('已是最后一页', 'warning')
    }
  }

  const handleAllOrdersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAllOrders(event.target.checked)
  }

  React.useEffect(() => {
    getData()
  }, [order, orderBy, currentPage, showAllOrders])

  return (
    <Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Box>
          <Button
            onClick={handlePrevPageClick}
          >上一页</Button>
          <Button
            variant="text"
          >{currentPage + 1}</Button>
          <Button
            onClick={handleNextPageClick}
          >下一页</Button>
        </Box>
        <FormControlLabel
          label="显示全部订单"
          control={
            <Switch
              onChange={handleAllOrdersChange}
            />
          } />

      </Box>


      <Paper
        sx={{ mb: 1 }}
      >
        <CardActions>
          <TextField
            fullWidth
            size='small'
            placeholder="输入商家名称"
            onInput={(event: any) => { setSearchKeyword(event.target.value.replace(/[\#\%\&\*\\\(\)\[\]]/g, '')) }}
            onKeyDown={(event: any) => { if (event.keyCode === 13) { getData(); setCurrentPage(0) } }}
            value={searchKeyword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            sx={{ mx: 1 }}
            size='large'
            onClick={() => { setFilterCollapse(!filterCollapse) }}
          >
            {filterCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </CardActions >

        <Collapse in={filterCollapse} timeout="auto" sx={{ px: 1, py: .5 }}>
          <Typography variant='body2'>物流状态选择</Typography>
          <Box sx={{ mx: 2 }}>
            {
              select_items['logistics_status'].map(item => {
                return (
                  <Chip
                    key={item.label}
                    clickable={false}
                    onClick={() => {
                      if (logisticsStatusSelected.indexOf(item.label) == -1) {
                        logisticsStatusSelected.push(item.label)
                      } else {
                        logisticsStatusSelected.splice(logisticsStatusSelected.indexOf(item.label), 1)
                      }
                      setLogisticsStatusSelected([...logisticsStatusSelected])
                    }}
                    color={logisticsStatusSelected.indexOf(item.label) >= 0 ? 'primary' : 'default'}
                    label={item.label}
                    size="small"
                    sx={{ m: .4, cursor: 'pointer' }}
                  />
                )
              })
            }
          </Box>
          <Typography variant='body2'>订单状态选择</Typography>
          <Box sx={{ mx: 2 }}>
            {
              select_items['order_status'].map(item => {
                return (
                  <Chip
                    key={item.label}
                    clickable={false}
                    onClick={() => {
                      if (orderStatusStatusSelected.indexOf(item.label) == -1) {
                        orderStatusStatusSelected.push(item.label)
                      } else {
                        orderStatusStatusSelected.splice(orderStatusStatusSelected.indexOf(item.label), 1)
                      }
                      setOrderStatusStatusSelected([...orderStatusStatusSelected])
                    }}
                    color={orderStatusStatusSelected.indexOf(item.label) >= 0 ? 'primary' : 'default'}
                    label={item.label}
                    size="small"
                    sx={{ m: .4, cursor: 'pointer' }}
                  />
                )
              })
            }
          </Box>
          {
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  setSearchKeyword('')
                  setLogisticsStatusSelected([])
                  setOrderStatusStatusSelected([])
                }}
                variant='contained'
                color='warning'
                sx={{ m: 1, position: 'relative', right: 0 }}
              >重置过滤</Button>
              <Button
                onClick={async () => {
                  getData()
                  setCurrentPage(0)
                }}
                variant='contained'
                sx={{ m: 1, mr: 8, position: 'relative', right: 0 }}
              >搜索</Button>
            </Box>
          }
        </Collapse>
      </Paper>

      <OrdersTableList
        newAlert={props.newAlert}
        schema={props.schema}
        category={props.category}
        array={data}
        getttData={getData}
      ></OrdersTableList>

      <Box>
        <Button
          onClick={handlePrevPageClick}
        >上一页</Button>
        <Button
          variant="text"
        >{currentPage + 1}</Button>
        <Button
          onClick={handleNextPageClick}
        >下一页</Button>
      </Box>
    </Box>
  )
}

export default OrdersTableDiv