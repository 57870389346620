import { Box } from "@mui/material"
import TableDiv from '../components/Products/TableDiv'

const schema = {
  TABLE: { name: 'logistics', label: '快递', display: false },
  logistics_number: { label: '物流单号', addable: true, editable: true },
  store_name: { label: '店铺名称', addable: true, editable: true, type: 'select'},
  order_no: { label: '订单号', addable: true, editable: true, },
  nick_name: { label: '买家昵称', addable: true, editable: true },
  logistics_company: { label: '物流公司', addable: true, editable: true, type: 'select'},
  logistics_status: { label: '物流状态', addable: true, editable: true, type: 'select'},
  price: {label: '金额', addable: true, editable: true, type: 'number'},
  // company_id: { label: '客户Id', addable: false, editable: false, display: false },
  // company: { label: '客户名称', addable: false, editable: false, display: false },
  // type: { label: '类型', addable: false, editable: false, display: false },
  // intention: { label: '处理方式', addable: false, editable: false, display: false },
  remark: { label: '备注', addable: true, editable: true },
  createdAt: { label: '创建时间', addable: false, editable: false }
}

function Logistics(props: any) {
  return (
    <Box>
      <TableDiv
        schema={schema}
        newAlert={props.newAlert}
      >
      </TableDiv>
    </Box>
  )
}

export default Logistics