import { Box } from "@mui/material"
import TableDiv from '../components/Products/TableDiv'

const schema = {
	TABLE: { name: 'custom_customers', label: '客户', display: false },
	company: { label: '客户名称 *', addable: true, editable: false },
	customer_type: { label: '客户分类', addable: true, editable: true },
	address: { label: '地址', addable: true, editable: true },
	remark: { label: '备注', addable: true, editable: true },
	createdAt: { label: '创建时间', addable: false, editable: false }
}

function CustomCustomers(props: any) {
	return (
		<Box>
			<TableDiv
				schema={schema}
				newAlert={props.newAlert}
			>
			</TableDiv>
		</Box>
	)
}

export default CustomCustomers