import { Box, Card } from "@mui/material"
import OrdersTableDiv from "../components/Orders/OrdersTableDiv"

let schema = {
  TABLE: { name: 'orders', label: '订单', display: false, xlsx: false },
  products: {
    label: '产品列表', addable: true, editable: true, xlsx: false, type: 'table',
    subSchema: {
      product_id: { label: '产品_id', display: false },
      mainImage: { label: '例图' },
      brand: { label: '品牌' },
      product_code: { label: '货号' },
      name_jp: { label: '日文名称' },
      name_zh: { label: '中文名称' },
      price_jpy: { label: '日元售价', },
      price_cny_cn: { label: '代理版(元/个)', },
      price_cny_jp: { label: '日版(元/个)' },
      pack_spec: { label: '每袋数量' },
      carton_spec: { label: '每箱数量' },
      custom_carton: { label: '已到数量(箱)' },
      launch_date: { label: '发售日期' },
      arrived: { label: '是否到货' },
      order_number_pack: { label: '订购(袋)' },
      order_number_carton: { label: '订购(箱)' },
      order_remark: { label: '备注' },
      real_number: { label: '发货数量' }
    }
  },
  customer_id: { label: '客户_id', editable: false, display: false, xlsx: false },
  category: { label: '分类', editable: false, display: true, xlsx: false },
  company: { label: '客户名称', addable: true, editable: true, xlsx: false },
  price_cny: { label: '订单总金额', addable: true, editable: true, xlsx: false, type: 'number' },
  deposit_cny: { label: '已付定金', addable: true, editable: true, xlsx: false, type: 'number', pic: true },
  balance_cny: { label: '已付尾款', addable: true, editable: true, xlsx: false, type: 'number', pic: true },
  logistic_cny: { label: '物流费用', addable: true, editable: true, xlsx: false, type: 'number' },
  cargo_sent: { label: '物流状态', addable: true, editable: true, xlsx: false, type: 'select' },
  cargo_no: { label: '物流单号', addable: true, editable: true, xlsx: false },
  order_status: { label: '订单状态', addable: true, editable: true, xlsx: false, type: 'select' },
  remark: { label: '备注', addable: true, editable: true, xlsx: false },
  createdAt: { label: '创建时间', addable: false, editable: false, xlsx: false }
}



function Products(props: any) {
  console.log(props.category)
  switch (props.category) {
    case '海关':
      schema.products.subSchema.real_number.label = '报关(个)';
      schema.category.label = '海关';
      break;
    case '扭蛋':
      schema.category.label = '扭蛋';
      break;
    case '周边':
      schema.category.label = '周边';
      break;
    case '周边-现货':
      schema.category.label = '周边-现货';
      break;
    case '手办':
      schema.category.label = '手办';
      break;
    default:
      schema.category.label = '分类';
  }

  if (props.category == '海关') {

  }
  return (
    <Box>
      <OrdersTableDiv
        schema={schema}
        category={props.category}
        newAlert={props.newAlert}
      >
      </OrdersTableDiv>
    </Box>
  )
}

export default Products