import React, { } from 'react'
import JSZip, { folder } from 'jszip'
import { Backdrop, Button, Card, Checkbox, Chip, Container, Dialog, Fab, FormControlLabel, Grid, IconButton, LabelDisplayedRowsArgs, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography } from '@mui/material'
import DialogTextfields from '../public/DialogTextfields';
import CMSApi from '../../utils/opsloth_cms_service_api'
import AddIcon from '@mui/icons-material/Add';
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import FolderIcon from '@mui/icons-material/PermMedia'
import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';
import ImageIcon from '@mui/icons-material/Image'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentsIcon from '@mui/icons-material/Payments';
import GridOnIcon from '@mui/icons-material/GridOn'

import { Box } from '@mui/system';
import savefile from '../../utils/savefile'
import axios from 'axios';
import ExcelJS from 'exceljs'
import TableListRow from './TableListRow';
import { pink } from '@mui/material/colors';
import OrderTableArray from '../Orders/OrderTableArray';
import TableListOrdersDialog from './TableListOrdersDialog';
import CircularProgressWithLabel from '../public/CircularProgressWithLabel';
import getSmallerImgUrl from '../../utils/getSmallerImgUrl';
import TagsGenerate from '../public/TagsGenerate';
import ExcelAddDialog from './ExcelAddDialog';
import ImagesManageDialog from '../public/ImagesManageDialog';
import ExportProductsConfig from './ExportProductsConfig';
import { CheckBox } from '@mui/icons-material';
const minimal_base64_img = 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
const ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

const MemorizedListRow = React.memo(TableListRow)

function TableList(props: any) {
	const [checkboxSelected, setCheckboxSelected] = React.useState<string[]>([])
	const [checkedItems, setCheckedItems] = React.useState<object[]>([])
	const [excelAddDialogOpen, setExcelAddDialogOpen] = React.useState(false)
	const [ordersTableShow, setOrdersTableShow] = React.useState(false)
	const [warehouseFilterOpen, setWarehouseFilterOpen] = React.useState(false)
	const [imagesManageDialogOpen, setImagesManageDialogOpen] = React.useState(false)
	const [tagsOpen, setTagsOpen] = React.useState(false)
	const imgBase64Cache = React.useRef<any>({})
	const [exportPercentage, setExportPercentage] = React.useState(0)
	let lastSubCheckboxClikedIndex = -1
	const [warehouse_stock_price_ratio, setWarehouse_stock_price_ratio] = React.useState(1)
	const [warehouse_stock_product_highlight_distance_day, setWarehouse_stock_product_highlight_distance_day] = React.useState(0)
	const [warehouse_stock_generate_stock_above, setWarehouse_stock_generate_stock_above] = React.useState(0)
	const [warehouse_stock_generate_stock_below, setWarehouse_stock_generate_stock_below] = React.useState(0)
	const [warehouse_stock_max_show_count, setWarehouse_stock_max_show_count] = React.useState(800)
	const [warehouse_stock_export_protect, setWarehouse_stock_export_protect] = React.useState(true)

	const [folderCache, setFolderCache] = React.useState('')
	const [subFolderCache, setSubFolderCache] = React.useState('')
	const [folderPageCache, setFolderPageCache] = React.useState(1)

	const [exportProductsConfigShow, setExportProductsConfigShow] = React.useState(false)
	const [exportProductsConfig, setExportProductsConfig] = React.useState({
		show_jp_price: { label: '日元售价', checked: true },
		show_price_cny_cn: { label: '代理版(元/个)', checked: true },
		show_price_cny_jp: { label: '日本(元/个)', checked: true },
		show_launch_date: { label: '日本出荷', checked: true },
		file_protect: { label: '文件保护', checked: true }
	})
	const exportProductsConfigSubmited = React.useRef(exportProductsConfig)

	const handleSortClick = (key: string) => () => {
		props.handleOrderChange(key)
	}

	const handleEditClick = React.useCallback((
		_id: string,
		key: string,
		value: any,
		index: number,
		editable: boolean
	) => () => {
		if (editable) {
			props.setDataDialogShow(true)
			props.setModifyOrAdd('modify')
			if (checkboxSelected.length === 0) {
				props.setModifyIds([_id])
			}
			props.setCheckboxSelectedLength(checkboxSelected.length)
			props.setModifyIndex(index)
			props.setModifyColumn(key)
			props.setModifyColumnValue(value ?? '')
			props.setModifyColumnValueBeforeClick(value)
		} else {
			props.newAlert('该值无法修改', 'info')
		}
	}, [props.array])

	const handleAllCheckChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			props.array.forEach((item: { _id: string, mainImageBase64: string }) => {
				if (checkboxSelected.indexOf(item._id) < 0) {
					checkedItems.push(item)
					checkboxSelected.push(item._id)
				}
			})
		} else {
			for (let i = props.array.length - 1; i >= 0; i--) {
				if (checkboxSelected.indexOf(props.array[i]._id) >= 0) {
					checkedItems.splice(checkboxSelected.indexOf(props.array[i]._id), 1)
					checkboxSelected.splice(checkboxSelected.indexOf(props.array[i]._id), 1)
				}
			}
		}
		setCheckboxSelected([...checkboxSelected])
		setCheckedItems([...checkedItems])
	}, [props.array, props.item])

	const handleSubCheckboxClick = React.useCallback((event: React.MouseEvent<HTMLInputElement>, clickIndex: number) => {
		function add(clickIndex: number[]) {
			let _ids: string[] = []
			clickIndex.forEach(index => {
				_ids.push(props.array[index]._id)
			})
			for (let i = 0; i < _ids.length; i++) {
				const _id = _ids[i]
				if (checkboxSelected.indexOf(_id) < 0) {
					checkedItems.push(props.array[clickIndex[i]])
					checkboxSelected.push(_id)
				}
			}
		}

		function remove(clickIndex: number[]) {
			let _ids: string[] = []
			clickIndex.forEach(index => {
				_ids.push(props.array[index]._id)
			})

			for (let i = 0; i < _ids.length; i++) {
				const _id = _ids[i]
				if (checkboxSelected.indexOf(_id) >= 0) {
					checkedItems.splice(checkboxSelected.indexOf(_id), 1)
					checkboxSelected.splice(checkboxSelected.indexOf(_id), 1)
				}
			}
		}

		function getClickIndexArray(clickIndex: number, lastSubCheckboxClikedIndex: number) {
			return Array.from(new Array(Math.abs(clickIndex - lastSubCheckboxClikedIndex) + 1).fill(Math.min(clickIndex, lastSubCheckboxClikedIndex)), (value, index) => {
				return value + index
			})
		}

		if ((event.target as any).checked) {
			if (event.shiftKey) {
				if (lastSubCheckboxClikedIndex >= 0) {
					add(getClickIndexArray(clickIndex, lastSubCheckboxClikedIndex))
				}
			} else {
				add([clickIndex])
			}
		} else {
			if (event.shiftKey) {
				if (lastSubCheckboxClikedIndex >= 0) {
					remove(getClickIndexArray(clickIndex, lastSubCheckboxClikedIndex))
				}
			} else {
				remove([clickIndex])
			}
		}
		setCheckboxSelected([...checkboxSelected])
		setCheckedItems([...checkedItems])
		lastSubCheckboxClikedIndex = clickIndex
	}, [props.array, props.item])

	const clearAllChecked = React.useCallback(() => {
		checkboxSelected.splice(0, checkboxSelected.length)
		checkedItems.splice(0, checkedItems.length)
		setCheckboxSelected([...checkboxSelected])
		setCheckedItems([...checkedItems])
	}, [props.array, props.item])

	const selectedNewAlert = () => {
		props.newAlert(`已选中 ${checkboxSelected.length}`, 'info', checkboxSelected.length === 0 ? false : true)
	}

	const handlePageChange = async (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		//getDataNewPage solves checkbox flash when currentPage changes first
		await props.getDataNewPage(newPage)
		props.setCurrentPage(newPage)
	}

	const handleRowsPerChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		props.setPageLimit(parseInt(event.target.value))
	}

	const handleGenerateCheckedXLSX = () => {
		if (checkedItems.length <= 0) {
			alert('未选中！')
		} else {
			setExportProductsConfigShow(true)
		}
	}


	const handleExportProductsConfigSubmit = (config: any) => {
		exportProductsConfigSubmited.current = config
		if (warehouseFilterOpen) {
			getProductsByWarehouseStocks()
		} else {
			processChosenProducts(checkedItems)
		}
	}

	const processChosenProducts = async (checkedItems: any) => {
		const showJPPrice = exportProductsConfigSubmited.current.show_jp_price.checked
		const showPriceCN = exportProductsConfigSubmited.current.show_price_cny_cn.checked
		const showPriceJP = exportProductsConfigSubmited.current.show_price_cny_jp.checked
		const showLaunchDate = exportProductsConfigSubmited.current.show_launch_date.checked
		const fileProtect = exportProductsConfigSubmited.current.file_protect.checked
		let checkedItemTemp = [...checkedItems]
		checkedItemTemp.sort((a: any, b: any) => {
			if (a.brand && b.brand) {
				return a.brand.localeCompare(b.brand)
			} else {
				return false
			}
		})

		let sortedProductsByBrand: object[][] = []
		let pushedProductsMap = new Map()
		checkedItemTemp.forEach((item: any) => {
			if (sortedProductsByBrand[item.brand] === undefined) {
				sortedProductsByBrand[item.brand] = []
			}
			if (!pushedProductsMap.get(item._id)) {
				sortedProductsByBrand[item.brand].push(item)
				pushedProductsMap.set(item._id, true)
			}
		})

		const workbook = new ExcelJS.Workbook();
		workbook.creator = 'opsloth-cms';
		workbook.lastModifiedBy = 'opsloth-cms';
		workbook.created = new Date();
		workbook.modified = new Date();
		workbook.lastPrinted = new Date();
		workbook.properties.date1904 = true;

		const sheets: { [brand: string]: any } = {}
		const columns: { name: string, width?: number, hidden?: boolean, totalsRowLabel?: string, fill?: any }[] = [
			{ name: '_id', hidden: true, totalsRowLabel: 'none' },
			{ name: 'launch_date', hidden: true, totalsRowLabel: 'none' },
			{ name: 'arrived', hidden: true, totalsRowLabel: 'none' },
			{ name: '序号', width: 5 }
		]

		Object.keys(props.schema).forEach((schema) => {
			if (props.schema[schema].xlsx) {
				let widthTemp = 16
				if (schema === 'name_jp') {
					widthTemp = 46
				} else if (schema === 'order_number_pack' ||
					schema === 'order_number_carton' ||
					schema === 'pack_spec' ||
					schema === 'carton_spec' ||
					schema === 'order_price'
				) {
					widthTemp = 12
				} else if (schema === 'mainImage') {
					widthTemp = 14
				} else if (schema === 'name_zh') {
					widthTemp = 30
				} else if (schema === 'product_code') {
					widthTemp = 20
				} else if (schema == 'order_remark') {
					widthTemp = 25
				}
				columns.push({ name: props.schema[schema].label, width: widthTemp })
			}
		})

		// Object.keys(sortedProductsByBrand).forEach((brand: string) => {
		for (let j = 0; j < Object.keys(sortedProductsByBrand).length; j++) {
			let percentageTemp = parseFloat(((j / Object.keys(sortedProductsByBrand).length) * 100).toFixed(1))
			setExportPercentage(parseFloat(((j / Object.keys(sortedProductsByBrand).length) * 100).toFixed(1)))

			const brand = Object.keys(sortedProductsByBrand)[j]
			sheets[brand] = workbook.addWorksheet(brand)
			sheets[brand].getRow(1).height = 40
			sheets[brand].getRow(1).alignment = { vertical: 'middle', horizontal: 'center' }
			sheets[brand].getRow(1).wrapText = true
			sheets[brand].getRow(1).border = { left: { style: 'thin', color: { argb: 'FFFFFFFF' } }, };

			let table = {
				name: `${brand.replaceAll(' ', '_')}`,
				ref: 'A1',
				headRow: false,
				totalsRow: false,
				style: {
					theme: 'TableStyleLight9',
					showRowStripes: true,
				},
				columns: [{}],
				rows: [{}],
				commit: () => { }
			}

			let rows: (string | number)[][] = []
			// sortedProductsByBrand[brand as any].forEach(async (product: any, product_index: number) => {//rows
			for (let i = 0; i < sortedProductsByBrand[brand as any].length; i++) {
				const product: any = sortedProductsByBrand[brand as any][i]
				const product_index = i
				//rows
				sheets[brand].getRow(product_index + 2).height = 120
				sheets[brand].getRow(product_index + 2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
				const base64Temp = await handleGetImgWithCache(product.mainImage, product._id, percentageTemp)
				const imageIdTemp = workbook.addImage({
					base64: base64Temp,
					extension: 'png',
				})
				let row: any = [product._id, product.launch_date, product.arrived, product_index + 1]

				Object.keys(props.schema).forEach(schema => {//row
					if (props.schema[schema].xlsx) {
						if (schema === 'order_number_pack' || schema === 'order_number_carton' || schema === 'order_remark') {

							let now = new Date().getTime()
							let updatedAt = new Date(product['updatedAt']).getTime()
							if (schema === 'order_remark') {
								let remark_temp = product['remark'] ?? ''
								// console.log(remark_temp)
								if ((now - updatedAt) <= warehouse_stock_product_highlight_distance_day * 60 * 60 * 24 * 1000) {
									remark_temp += "新到货\n"
								}
								if (product['cut_off_date'] && props.schema.category.default == "周边") {
									let cut_off_date_remark = '截单日期：' + new Date(product['cut_off_date']).toLocaleDateString() + ';\n'
									remark_temp += cut_off_date_remark
								}
								if (product['launch_date'] && showLaunchDate) {
									let launch_date_remark = '日本出荷：' + new Date(product['launch_date']).toLocaleDateString() + ';\n'
									remark_temp += launch_date_remark
								}
								row.push(remark_temp)
							} else {
								row.push(product[schema] ?? 0)

							}
							if ((now - updatedAt) <= warehouse_stock_product_highlight_distance_day * 60 * 60 * 24 * 1000) {//最后更新日子差距
								sheets[brand].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6E0B4' } }
							} else {
								sheets[brand].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFC5D9F1' } }
							}
							sheets[brand].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).border = { right: { style: 'thin', color: { argb: 'FFFFFFFF' } } };
							sheets[brand].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).protection = { locked: false, hidden: true, };
							if (schema === 'order_number_pack' || schema === 'order_number_carton') {
								sheets[brand].getCell(`${ALPHABET[row.length - 1]}${product_index + 2}`).dataValidation = {
									type: 'whole',
									operator: 'greaterThanOrEqual',
									showErrorMessage: true,
									formulae: [0],
									errorStyle: 'error',
									errorTitle: '输入值错误',
									error: '必须输入大于等于零的整数'
								}
							}

						} else {
							if (schema === 'price_cny_cn' || schema === 'price_cny_jp' || schema === 'price_jpy') {
								// console.log(schema, showJPPrice, showPriceCN, showPriceJP)
								if (
									!showJPPrice && schema === 'price_jpy' ||
									!showPriceCN && schema === 'price_cny_cn' ||
									!showPriceJP && schema === 'price_cny_jp'
								) {
									row.push('/')
								} else {
									if (schema === 'price_cny_cn' || schema === 'price_cny_jp') {
										row.push(product[schema] === undefined || product[schema] === 0 || product[schema] === null || product[schema] === '' ? '/' : (product[schema] * warehouse_stock_price_ratio).toFixed(1))
									} else {
										row.push(product[schema] === undefined || product[schema] === 0 || product[schema] === null || product[schema] === '' ? '/' : product[schema])
									}
								}
							} else if (schema === 'product_code') {

								row.push(product[schema] == product['product_code_box'] && (product[schema] === undefined || product[schema] === 0 || product[schema] === null || product[schema] === '') ? '/' : (product[schema] ?? product['product_code_box']))
							} else {
								row.push(product[schema] === undefined || product[schema] === 0 || product[schema] === null || product[schema] === '' ? '/' : product[schema])
							}
						}
					}
				})

				// const imageSize = getBase64ImageSize(product.mainImageBase64)
				sheets[brand].addImage(imageIdTemp, {
					tl: { col: 4, row: product_index + 1 },
					br: { col: 5, row: product_index + 2 },
					hyperlinks: {
						hyperlink: product.mainImage,
						tooltip: product.mainImage
					}
				})
				rows.push(row)
				table.rows = rows
				if (fileProtect) {
					await sheets[brand].protect('aoyama', { spinCount: 2, formatColumns: true, formatRows: true });
				}
			}
			table.columns = columns
			table.commit()
			sheets[brand].columns = columns
			sheets[brand].addTable(table)
		}
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], { type: "application/vnd.ms-excel" })
		savefile(`商品列表-${props.schema.category.default}-${new Date().toLocaleString().replaceAll('/', '-')}-ratio_${warehouse_stock_price_ratio}.xlsx`, blob)
		setExportPercentage(0)
	}

	const handleGenerateImageZip = async () => {
		if (checkedItems.length > 0) {
			const zip = new JSZip()
			for (let i = 0; i < checkedItems.length; i++) {
				setExportPercentage(parseFloat(((i / checkedItems.length) * 100).toFixed(1)))
				const item: any = checkedItems[i]
				const img = zip.folder((item.product_code?.trim() ?? '') + '_' + (item.name_jp ?? (item.name_zh ?? '')))
				const imgDataUrl = (await getBase64Image(item.mainImage ?? minimal_base64_img)) as string
				const imgSuffix = imgDataUrl.slice(11, imgDataUrl.indexOf(';'))
				const imgBase64 = imgDataUrl.replace(/^data:image\/?[A-z]*;base64,/, '')
				img?.file(`${item.product_code?.trim() ?? ""}_1.${imgSuffix}`, imgBase64, { base64: true })
				for (let j = 0; j < item?.images?.length; j++) {
					const imgDataUrl_sub = (await getBase64Image(item.images[j] ?? minimal_base64_img)) as string
					const imgSuffix_sub = imgDataUrl_sub.slice(11, imgDataUrl_sub.indexOf(';'))
					const imgBase64_sub = imgDataUrl_sub.replace(/^data:image\/?[A-z]*;base64,/, '')
					img?.file(`${item.product_code?.trim() ?? ""}_${j + 2}.${imgSuffix_sub}`, imgBase64_sub, { base64: true })
				}
			}
			zip.generateAsync({ type: "blob" }).then(function (content) {
				savefile(`图片-${props.schema.category.default}_${new Date().toLocaleString().replaceAll('/', '-')}.zip`, content)
				setExportPercentage(0)
			});
		} else {
			alert('未选中！')
		}
	}

	const getBase64Image = (imgUrl: string) => {
		return new Promise((resolve, reject) => {
			if (imgUrl) {
				const image = new Image();
				image.crossOrigin = 'anonymous';
				image.src = imgUrl
				image.onload = () => {
					const canvas = document.createElement('canvas');
					const ctx: any = canvas.getContext('2d');
					canvas.height = image.naturalHeight;
					canvas.width = image.naturalWidth;
					ctx.drawImage(image, 0, 0);
					const dataUrl = canvas.toDataURL();
					resolve(dataUrl)
				}
			} else {
				resolve(minimal_base64_img)
			}
		})
	}

	const handleGetImgWithCache = async (imgUrl: string, _id: string, percentageTemp: number) => {
		if (!imgBase64Cache.current[_id]) {
			imgBase64Cache.current[_id] = {}
		}
		if (percentageTemp > 0) {//导出中
			imgBase64Cache.current[_id][imgUrl] = await getBase64Image(getSmallerImgUrl(imgUrl))
		} else {
			if (!imgBase64Cache.current[_id][imgUrl]) {//无缓存时
				imgBase64Cache.current[_id][imgUrl] = await getBase64Image(getSmallerImgUrl(imgUrl, 400))
			}
		}
	return imgBase64Cache.current[_id][imgUrl]
}

const getProductsByWarehouseStocks = async () => {
	const productModal = new CMSApi(props.schema.TABLE.name)
	const productsRes: any = await productModal
		.setFilter({
			arrived: '已到货',
			category: props.schema.category.default,
			deleted: { $ne: true },
			warehouse_stock_product_id: { $nin: ['', undefined] }
		})
		.setField("-warehouse_stock_product_name -createdAt -deleted -images -tags -category")
		.get()

	let warehouse_stock_product_ids = new Array()
	productsRes.data.forEach((product: any) => {
		if (warehouse_stock_product_ids.indexOf(product.warehouse_stock_product_id) == -1) {
			warehouse_stock_product_ids.push({ $toObjectId: product.warehouse_stock_product_id })
		}
	})
	const stocksModal = new CMSApi('', 'https://opsloth.com/warehouse_stock/stocks/aggregate')
	const stocksRes: any = await stocksModal
		.post([
			{
				$match: {
					$expr: { $in: ['$product_id', warehouse_stock_product_ids] },
					deleted: { $ne: true },
					stock: { $gte: 0 },
					$and: [{ sku_id: { $nin: ['', undefined] } }, { warehouse_name: { $nin: ["代购房间-零售预售", "打包室-预售批发区", "办公室-零售预售区", "零售预售", "预售+批发", "瑕疵破损", "囤货箱"] } }],
					// sku_id: { $nin: ['', undefined] },
				}
			},
			{
				$group: {
					// _id: "$sku_id",
					_id: {
						"product_id": "$product_id",
						"sku_id": "$sku_id"
					},
					product_id: { $push: "$product_id" },
					stock: { $sum: '$stock' }
				}
			},
			{
				$group: {
					_id: { $arrayElemAt: ["$product_id", 0] },
					sum: { $sum: '$stock' },
					min: { $min: '$stock' },
					count: { $sum: 1 }
				}
			},
			{
				$match: {
					sum: { $gt: warehouse_stock_generate_stock_above, $lt: warehouse_stock_generate_stock_below <= 0 ? undefined : warehouse_stock_generate_stock_below }
				}
			}
		])

	let sumStockMap = new Map()
	let minStockMap = new Map()
	let countStockMap = new Map()
	stocksRes.data.forEach((item: any) => {
		sumStockMap.set(item._id, item.sum)
		minStockMap.set(item._id, item.min)
		countStockMap.set(item._id, item.count)
	})
	for (let i = productsRes.data.length - 1; i >= 0; i--) {
		if (sumStockMap.get(productsRes.data[i].warehouse_stock_product_id) == undefined || sumStockMap.get(productsRes.data[i].warehouse_stock_product_id) <= warehouse_stock_generate_stock_above) {
			productsRes.data.splice(i, 1)
		} else {
			productsRes.data[i].remark = (productsRes.data[i].remark ? productsRes.data[i].remark + ';' : '')
				+ (sumStockMap.get(productsRes.data[i].warehouse_stock_product_id)
					? '现货：'
					+ (
						sumStockMap.get(productsRes.data[i].warehouse_stock_product_id) < warehouse_stock_max_show_count
							? sumStockMap.get(productsRes.data[i].warehouse_stock_product_id)
							: warehouse_stock_max_show_count
					)
					+ (
						(countStockMap.get(productsRes.data[i].warehouse_stock_product_id)
							* minStockMap.get(productsRes.data[i].warehouse_stock_product_id)
						) < (productsRes.data[i].pack_spec ?? 60)
							? '; 不足一袋(平均配比);'
							: ''
					)
					+ "\n"
					: '现货: 0'
				)
		}
	}
	setWarehouseFilterOpen(false)
	processChosenProducts(productsRes.data)
}

const handleExcelAddSubmit = (dropedSheet: any) => {
	let returnDataTemp: any = []
	let error = false
	dropedSheet.eachRow((row: any, rowNumber: number) => {
		if (row.values[3]) {
			let productTemp: any = {}
			productTemp.product_code = (row.values[1] ?? '').toString()
			productTemp.name_jp = (row.values[2] ?? '').toString()
			productTemp.name_zh = (row.values[3] ?? '').toString()
			productTemp.cost_cny = Number(row.values[4])
			productTemp.cost_jpy = Number(row.values[5])
			productTemp.price_jpy = Number(row.values[6])
			productTemp.price_cny_cn = Number(row.values[7])
			productTemp.price_cny_jp = Number(row.values[8])
			productTemp.pack_spec = Number(row.values[9])
			productTemp.carton_spec = Number(row.values[10])
			let cut_off_date_temp = new Date(JSON.stringify(row.values[11] ?? '').replaceAll("\"", '')).toLocaleDateString()
			productTemp.cut_off_date = cut_off_date_temp == 'Invalid Date' ? undefined : cut_off_date_temp
			let launch_date_temp = new Date(JSON.stringify(row.values[12] ?? '').replaceAll("\"", '')).toLocaleDateString()
			productTemp.launch_date = launch_date_temp == 'Invalid Date' ? undefined : launch_date_temp
			productTemp.category = props.schema.category.default
			if (rowNumber > 1) {
				returnDataTemp.push(productTemp)
			}
		} else {
			error = true
			props.newAlert('中文名称必填！', 'error')
		}
	});
	if (!error) {
		// console.log(returnDataTemp)
		props.onSubmit(returnDataTemp)
	}
}

React.useEffect(() => {
	props.setModifyIds(checkboxSelected)
	setWarehouse_stock_price_ratio(1)
	setWarehouse_stock_generate_stock_above(0)
	setWarehouse_stock_generate_stock_below(0)
	setWarehouse_stock_max_show_count(800)
	selectedNewAlert()
}, [checkboxSelected])

return (
	<TableContainer
		sx={{ height: 'calc(100vh - 180px)' }}
	>
		<Table stickyHeader size="small">
			<TableHead>
				<TableRow
					role="checkbox"
					aria-checked={true}
				>
					<TableCell
						padding="checkbox"
					>
						<Box display={'flex'}>
							<FormControlLabel
								labelPlacement='start'
								label=""
								control={
									<Checkbox
										onChange={handleAllCheckChange}
										checked={props.array.filter(((item: { _id: string; }) => { return checkboxSelected.indexOf(item._id) >= 0 })).length === props.array.length}
									/>
								}
							/>
							{
								checkboxSelected.length >= 1 && <IconButton
									onClick={clearAllChecked}
								>
									<CloseIcon sx={{ color: pink[500] }} />
								</IconButton>
							}
						</Box>
					</TableCell>
					{Object.keys(props.schema).map((key: string, index: number) => {
						return (props.schema[key].display !== false && <TableCell
							key={index}
							sx={{ px: 1 }}
						>
							<TableSortLabel
								active={key === props.orderBy ? true : false}
								direction={props.order}
								onClick={handleSortClick(key)}
								sx={
									key === 'createdAt' || key === 'updatedAt'
										? { whiteSpace: 'nowrap', minWidth: '10em' }
										: { whiteSpace: 'nowrap' }
								}
							>
								{props.schema[key].label}
							</TableSortLabel>
						</TableCell>)
					})}
				</TableRow>
			</TableHead>
			<TableBody>
				{
					props.array.map((item: any, index: number) =>
						<MemorizedListRow
							key={item._id}
							item={item}
							index={index}
							schema={props.schema}
							pageLimit={props.pageLimit}
							currentPage={props.currentPage}
							handleSubCheckboxClick={handleSubCheckboxClick}
							checkboxSelected={checkboxSelected?.indexOf(item._id) >= 0}
							handleEditClick={handleEditClick}
							handleGetImgWithCache={handleGetImgWithCache}
						></MemorizedListRow>
					)
				}
			</TableBody>
			<TableFooter>
				<TableRow>
					<TablePagination
						sx={{ flexWrap: 'wrap' }}
						rowsPerPageOptions={[props.initPageLimit, parseInt(props.initPageLimit) * 2, parseInt(props.initPageLimit) * 5]}
						showFirstButton
						count={-1}
						labelDisplayedRows={(paginationInfo: LabelDisplayedRowsArgs) => { return `第 ${paginationInfo.page + 1} 页`; }}
						rowsPerPage={props.pageLimit}
						page={props.currentPage}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerChange}
					/>
				</TableRow>
			</TableFooter>
		</Table>

		<Box
			sx={{ position: 'fixed', right: 40, bottom: 50 }}
		>
			<Fab
				sx={{ zIndex: 1500 }}
				color='primary'
				size='medium'
				onClick={() => {
					props.setModifyOrAdd('add')
					props.setDataDialogShow(!props.dataDialogShow)
				}}
			>
				<AddIcon></AddIcon>
			</Fab >

			{
				props.schema.TABLE.name == 'products' && <Fab
					sx={{ zIndex: 1400, marginLeft: -1 }}
					color='primary'
					size='medium'
					onClick={() => {
						props.setModifyOrAdd('batchAdd')
						setExcelAddDialogOpen(!excelAddDialogOpen)
					}}
				>
					<NoteAddIcon></NoteAddIcon>
				</Fab >
			}

			{
				props.schema.TABLE.name == 'products' && <Fab
					sx={{ zIndex: 1300, marginLeft: -1 }}
					color='primary'
					size='medium'
					onClick={() => {
						setImagesManageDialogOpen(!imagesManageDialogOpen)
					}}
				>
					<FolderIcon></FolderIcon>
				</Fab >
			}

			{
				props.schema.TABLE.name == 'products' && <Fab
					sx={{ zIndex: 1200, marginLeft: -1 }}
					color='secondary'
					size='medium'
					onClick={handleGenerateCheckedXLSX}
				>
					<ListIcon></ListIcon>
				</Fab >
			}

			{
				props.schema.TABLE.name == 'products' && <Fab
					sx={{ zIndex: 1100, marginLeft: -1 }}
					color='secondary'
					size='medium'
					onClick={handleGenerateImageZip}
				>
					<ImageIcon></ImageIcon>
				</Fab >
			}

			{
				((window as any).user_level != 2 && props.schema.TABLE.name == 'products') && <Fab
					sx={{ zIndex: 1000, marginLeft: -1 }}
					color='warning'
					size='medium'
					onClick={() => { if (checkboxSelected.length > 200) { alert('超过最大选择数量200') } else { setOrdersTableShow(true) } }}
				>
					<PaymentsIcon></PaymentsIcon>
				</Fab >
			}

			{
				((window as any).user_level != 2 && props.schema.TABLE.name == 'products') && <Fab
					sx={{ zIndex: 900, marginLeft: -1 }}
					color='success'
					size='medium'
					onClick={() => { setWarehouseFilterOpen(true) }}
				>
					<WarehouseIcon></WarehouseIcon>
				</Fab >
			}

			{
				((window as any).user_level != 2 && props.schema.TABLE.name == 'products') && <Fab
					sx={{ zIndex: 800, marginLeft: -1 }}
					color='info'
					size='medium'
					onClick={() => { setTagsOpen(true) }}
				>
					<GridOnIcon></GridOnIcon>
				</Fab >
			}

			{
				(props.schema.TABLE.name == 'products' || props.schema.TABLE.name == 'customers' || props.schema.TABLE.name == 'customer_types') && <Fab
					sx={{ zIndex: 400, marginLeft: -1 }}
					color='error'
					size='medium'
					onClick={() => {
						const tempCheckboxSelected = [...checkboxSelected]
						checkboxSelected.splice(0, checkboxSelected.length)
						checkedItems.splice(0, checkboxSelected.length)
						setCheckboxSelected([...checkboxSelected])
						setCheckedItems([...checkedItems])
						props.handleProductDelete(tempCheckboxSelected)
					}}
				>
					<DeleteIcon></DeleteIcon>
				</Fab >
			}

		</Box>

		<TableListOrdersDialog
			schema={props.schema}
			checkboxSelected={checkboxSelected}
			ordersTableShow={ordersTableShow}
			setOrdersTableShow={setOrdersTableShow}
		></TableListOrdersDialog>

		<DialogTextfields
			schema={props.schema}
			open={props.dataDialogShow}
			setOpen={props.setDataDialogShow}
			title={`${checkboxSelected.length > 0 ? `批量 (${checkboxSelected.length}个) ` : ''}${props.modifyOrAdd === 'add' ? '新建' : '修改'}${props.schema.TABLE.label}`}
			modifyOrAdd={props.modifyOrAdd}
			modifyColumn={props.modifyColumn}
			modifyColumnValue={props.modifyColumnValue}
			setModifyColumnValue={props.setModifyColumnValue}
			returnData={props.dataAddData}
			setReturnData={props.setDataAddData}
			onSubmit={props.onSubmit}
			folderCache={folderCache}
			setFolderCache={setFolderCache}
			subFolderCache={subFolderCache}
			setSubFolderCache={setSubFolderCache}
			folderPageCache={folderPageCache}
			setFolderPageCache={setFolderPageCache}
		>
		</DialogTextfields>

		<ExcelAddDialog
			newAlert={props.newAlert}
			open={excelAddDialogOpen}
			onClose={() => { setExcelAddDialogOpen(false) }}
			onSubmit={handleExcelAddSubmit}
		></ExcelAddDialog>

		<img
			id="previewHoverImg"
			src={minimal_base64_img}
			style={{ display: 'none', position: 'fixed', top: 130, right: 50, zIndex: 1200, minWidth: '20vw', maxWidth: "50vw", objectFit: 'cover' }}
		/>


		<Backdrop
			sx={{ background: '#00000044', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={exportPercentage > 0}
		>
			<Box sx={{ background: '#fff', display: 'flex', borderRadius: '100%' }}>
				<CircularProgressWithLabel value={exportPercentage} />
			</Box>
		</Backdrop>

		<Dialog
			open={warehouseFilterOpen}
			onClose={() => { setWarehouseFilterOpen(false) }}
		>
			<Box
				sx={{ p: 4 }}
			>
				<Typography>
					生成现货表格
				</Typography>
				<ul>
					<li>商品 -- 已到货、已绑定仓库</li>
					<li>仓库 -- 现货库存（已绑定聚水潭）</li>
					<li>库存 -- <input type='number' min={-1} style={{ width: '4em', textAlign: 'right' }} value={warehouse_stock_generate_stock_above} onChange={(event) => { setWarehouse_stock_generate_stock_above(parseInt(event.target.value)) }} />以上保留（默认库存0不保留）</li>
					<li>库存 -- <input type='number' min={-1} style={{ width: '4em', textAlign: 'right' }} value={warehouse_stock_generate_stock_below} onChange={(event) => { setWarehouse_stock_generate_stock_below(parseInt(event.target.value)) }} />以下保留（默认库存0不保留）</li>
					<li>高亮 -- <input type='number' min={-1} style={{ width: '4em', textAlign: 'right' }} value={warehouse_stock_product_highlight_distance_day} onChange={(event) => { setWarehouse_stock_product_highlight_distance_day(parseInt(event.target.value)) }} />距离商品最后更新日多少日高亮</li>
					<li>库存 -- <input type='number' min={-1} style={{ width: '4em', textAlign: 'right' }} onChange={(event) => { setWarehouse_stock_max_show_count(parseInt(event.target.value)) }} value={warehouse_stock_max_show_count} />以下显示精确库存</li>
					<li>汇率 -- <input type='number' min={-1} style={{ width: '4em', textAlign: 'right' }} value={warehouse_stock_price_ratio} onChange={(event) => { setWarehouse_stock_price_ratio(parseFloat(event.target.value)) }} />人民币兑目标金额的汇率（最终价格=价格*汇率）</li>
				</ul>
				<Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
					<Button onClick={() => { setExportProductsConfigShow(true) }}>确定</Button>
					<Button onClick={() => { setWarehouseFilterOpen(false) }}>取消</Button>
				</Box>
			</Box>
		</Dialog>

		<Dialog
			open={tagsOpen}
			onClose={() => { setTagsOpen(false) }}
			maxWidth='xl'
		>
			<TagsGenerate
				onClose={() => { setTagsOpen(false) }}
			></TagsGenerate>
		</Dialog>

		<ImagesManageDialog
			schema={props.schema}
			folderCache={folderCache}
			setFolderCache={setFolderCache}
			subFolderCache={subFolderCache}
			setSubFolderCache={setSubFolderCache}
			folderPageCache={folderPageCache}
			setFolderPageCache={setFolderPageCache}
			open={imagesManageDialogOpen}
			onClose={setImagesManageDialogOpen}
			imagesManageSelectMultiple={false}
			showSubmitButton={false}
			submit={() => { setImagesManageDialogOpen(false); }}
		>
		</ImagesManageDialog>

		<ExportProductsConfig
			open={exportProductsConfigShow}
			onClose={() => setExportProductsConfigShow(false)}
			config={exportProductsConfig}
			setConfig={setExportProductsConfig}
			setWarehouse_stock_price_ratio={setWarehouse_stock_price_ratio}
			warehouse_stock_price_ratio={warehouse_stock_price_ratio}
			submit={handleExportProductsConfigSubmit}
		></ExportProductsConfig>
	</TableContainer>
)
}

export default TableList