import CMSApi from '../utils/opsloth_cms_service_api'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Chip, Dialog, DialogActions, DialogTitle, Fab, TextField } from '@mui/material'
import { Container } from '@mui/system'
import select_items from '../utils/select_items'

type Dealers = {
  _id: string,
  name: string
}

function Dealers(props: any) {
  let dealerTextField = ''
  const dealerModal = new CMSApi('dealers')
  const [dealers, setDealers] = React.useState<Dealers[]>([])
  const [addDialogOpen, setAddDialogOpen] = React.useState(false)
  const getDealers = async () => {
    const res = await select_items.loadDealers()
    setDealers(res as Dealers[])
  }

  const handleOnTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dealerTextField = event.target.value
  }

  const handleAdd = async () => {
    const res: any = await dealerModal.post({
      name: dealerTextField.toUpperCase()
    })
    if (res.data._id) {
      props.newAlert('创建经销商成功')
      setAddDialogOpen(false)
      getDealers()
    }
  }

  const handleOnBrandDelete = (_id: string) => async () => {
    const confirm = window.confirm('确认删除该经销商？')
    if (confirm) {
      await dealerModal
        .setFilter({ _id: _id })
        .put({ deleted: true })
      getDealers()
    }
  }

  React.useEffect(() => {
    getDealers()
  }, [])

  return (
    <Box>
      {
        dealers.map((item, index) => {
          return <Chip
            key={index}
            sx={{ m: .7, px: 1, fontSize: '1em' }}
            label={item.name}
            size='medium'
            color='primary'
            onDelete={handleOnBrandDelete(item._id)}
          />
        }
        )
      }
      {
        <Fab
          sx={{ position: 'fixed', right: 20, bottom: 50 }}
          color='primary'
          size='medium'
          onClick={() => { setAddDialogOpen(true) }}
        >
          <AddIcon></AddIcon>
        </Fab >
      }
      <Dialog
        maxWidth='xl'
        open={addDialogOpen}
        onClose={() => { setAddDialogOpen(false); dealerTextField = '' }}
      >
        <Container
          maxWidth='xl'
        >
          <DialogTitle>添加经销商</DialogTitle>
          <TextField
            onChange={handleOnTextFieldChange}
            sx={{ width: '30em' }}
          ></TextField>
        </Container>
        <DialogActions>
          <Button
            sx={{ m: 1 }}
            onClick={() => setAddDialogOpen(false)}
          >
            {"取消"}
          </Button>

          <Button
            onClick={handleAdd}
          >
            {"提交"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}



export default Dealers