import { Box } from "@mui/material"
import TableDiv from '../components/Products/TableDiv'

const schema = {
  TABLE: { name: 'products', label: '产品', display: false, xlsx: false},
  mainImage: {label: '例图', addable: true, editable: true, xlsx: true},
  brand: { label: '品牌', addable: true, editable: true, type: 'select', xlsx: true},
  category: {label: '分类', addable: false, editable: true, type: 'select', xlsx: false, default: '扭蛋'},
  product_code: { label: '货号', addable: true, editable: true, xlsx: true},
  name_jp: { label: '日文名称', addable: true, editable: true, xlsx: true},
  name_zh: { label: '中文名称', addable: true, editable: true, xlsx: true},
  price_jpy: { label: '日元售价', addable: true, editable: true, type: 'number', xlsx: true},
  price_cny_cn: { label: '代理版(元/个)', addable: true, editable: true, type: 'number', xlsx: true},
  price_cny_jp: { label: '日版(元/个)', addable: true, editable: true, type: 'number', xlsx: true},
  pack_spec: { label: '每袋数量', addable: true, editable: true, type: 'number', xlsx: true},
  carton_spec: { label: '每箱数量', addable: true, editable: true, type: 'number', xlsx: true},
  launch_date: { label: '发售日期', addable: true, editable: true, type: 'date', xlsx: false},
  arrived: { label: '到货', addable: true, editable: true, type: 'select', xlsx: false},
  remark: { label: '备注', addable: true, editable: true, xlsx: false},
  on_taobao: {label: '淘宝', addable: false, editable: true, xlsx: false, type: 'select'},
  warehouse_stock_product_name: {label: '库存', addable: true, editable: true, xlsx: false},
  tags: {label: '标签', addable: false, editable: true, xlsx: false},
  createdAt: { label: '创建时间', addable: false, editable: false, xlsx: false},

  order_number_pack: {label: '订购(袋)', addable: false, editable: false, display: false, xlsx: true},
  order_number_carton: {label: '订购(箱)', addable: false, editable: false, display: false, xlsx: true},
  order_remark: {label: '备注', addable: false, editable: false, display: false, xlsx: true},

  // order_price: {label: '订购价格', addable: false, editable: false, display: false, xlsx: true},
}

function Products(props: any) {
  return (
    <Box>
      <TableDiv
        schema={schema}
        newAlert={props.newAlert}
      >
      </TableDiv>

    </Box>
  )
}

export default Products