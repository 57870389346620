import { Box } from "@mui/material"

function TagsThumbnail(props: any) {
  return <Box sx={{ height: 'fit-content', lineHeight: '0px', width: 'fit-content' }}>
    {
      Array.from({ length: props.tagGenerateRow }).map((itemRow, indexRow) =>
        <Box key={indexRow} sx={{ display: 'flex', justifyContent: 'space-evenly', width: `${props.tagGenerateColumn * 8}px` }}>
          {
            Array.from({ length: props.tagGenerateColumn }).map((itemColumn, indexColumn) =>
              <Box
                key={indexRow * props.tagGenerateColumn + indexColumn}
                sx={{ height: '8px', width: '8px', fontSize: '2em', userSelect: 'none', color: props.checkArrayGenerated[indexRow * props.tagGenerateColumn + indexColumn] ? '#1976d2' : '#ddd' }}
              >·</Box>
            )
          }
        </Box>
      )
    }
  </Box>
}

export default TagsThumbnail