import React, { ReactNode } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import ExcelJS from 'exceljs'
import getSmallerImgUrl from '../../utils/getSmallerImgUrl'

function OrderTableXLSX(props: any) {
  const ORDER_NUMBER_PACK_INDEX = 15
  const ORDER_NUMBER_CARTON_INDEX = 16
  const ORDER_REMARK_INDEX = 17
  return (
    <TableContainer
      sx={props.sx}
    >
      <Table>
        <TableHead>
          <TableRow
          >
            {
              props.sheet.getRow(1).values.map((value: ExcelJS.CellValue, value_index: number) => {
                if (value_index > 3) {
                  let valueTemp: string | object = ''
                  if ((value as any).richText){
                    (value as any).richText.forEach((text: {text: string}) => {
                      valueTemp += text.text
                    })
                  }else{
                    (valueTemp as any) = value
                  }
                  return <TableCell
                    sx={{
                      fontSize: '.4em',
                      minWidth: '1em',
                      maxWidth: '1em',
                      whiteSpace: 'nowrap',
                      borderLeft: 'solid 1px #ccc',
                      borderTop: 'solid 1px #ccc',
                      background: '#DCE6F1'
                    }}
                    size="small"
                    key={value_index}
                  >{valueTemp as ReactNode}</TableCell>
                }
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.sheet.getRows(2, props.sheet.rowCount - 1).map((row: ExcelJS.CellValue, row_index: number) => (

              (
                (row as any).values[ORDER_NUMBER_PACK_INDEX] > 0
                || (row as any).values[ORDER_NUMBER_PACK_INDEX]?.result > 0
              )
              &&
              <TableRow
                key={`row_${row_index}`}
                >
                {props.sheet.getRow(1).values.map((v: any, value_index: any) => {
                  const value = (row as any).values[value_index]
                  let valueTemp: string | number = ''
                  if (value && (value as any).richText){
                    (value as any).richText.forEach((text: {text: string}) => {
                      valueTemp += text.text
                    })
                  }else{
                    if (value?.result){
                      console.log(value);
                      valueTemp = value.result ?? '空白'
                    }else{
                      valueTemp = value ?? '空白'
                    }
                  }
                  if (value_index > 3) {
                    return <Tooltip
                      key={`value_${value_index}`}
                      title={valueTemp as ReactNode}
                      placement="right"
                    >
                      <TableCell
                        sx={{
                          p: 1,
                          fontSize: '.4em',
                          minWidth: '1em',
                          maxWidth: '1em',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          borderLeft: 'solid 1px #ccc',
                          background: ((value > 0 && value_index === ORDER_NUMBER_PACK_INDEX)
                            || (value?.result > 0 && value_index === ORDER_NUMBER_PACK_INDEX)
                            || (value > 0 && value_index === ORDER_NUMBER_CARTON_INDEX)
                            )
                            ? '#DAEEF3'
                            : ''
                        }}
                      >
                        {
                          props.sheet.getRow(1).values[value_index] === '例图' ? <img
                            src={getSmallerImgUrl(value)}
                            style={{ width: '8em', height: '8em', objectFit: 'cover', transformOrigin: 'left center' }}
                          >
                          </img> : (valueTemp as any)
                        }
                        </TableCell>
                    </Tooltip>
                  }
                })}
              </TableRow>
            ))
          }

        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default OrderTableXLSX