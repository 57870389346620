import { Box, Button, Chip, Container, Dialog, Divider, Typography } from "@mui/material"
import React from "react"
import CMSApi from '../../utils/opsloth_cms_service_api'

function LogsDialog(props: any) {

  const [logs, setLogs] = React.useState([])
  const [currentPage, setCurrentPage] = React.useState(0)
  const [pageSize] = React.useState(10)

  const getLogs = async () => {
    if (props._id) {
      const dataModel = new CMSApi('logs')
      const res = await dataModel
        .setFilter({ document_id: props._id })
        .setOptions({ sort: '-createdAt', limit: pageSize, skip: currentPage * pageSize })
        .get()
      setLogs((res as any).data)
    }
  }

  const handlePrevPageClick = () => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNextPageClick = () => {
    if (logs.length == pageSize) {
      setCurrentPage(currentPage + 1)
    }
  }

  React.useEffect(() => {
    setCurrentPage(0)
    getLogs()
  }, [props.open])

  React.useEffect(() => {
    getLogs()
  }, [currentPage])

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={() => props.setOpen(false)}
    >
      {
        <Box
          sx={{ m: 3 }}
        >{
          logs.map((log, index) => (
            <Box
            key={index}
            sx={{ m: .5 }}
            >
                <Chip
                sx={{m:1, float: 'left'}}
                label={currentPage * pageSize + index + 1}
                />
                <Box sx={{display:'flex', flexDirection: 'column', whiteSpace:'pre-line'}}>
                {
                  log['mainImage'] && <img src={log['mainImage']}></img>
                }
                  {
                    log['column_name'] + ': ' +
                    log['old_value'] + ' > ' +
                    log['new_value']
                  }
                  <br />
                  {
                    log['log_user'] +
                    (log['remark'] ?? '')
                  }
                  <br />
                  {
                    new Date(log['createdAt']).toLocaleString()
                  }
                  <Divider sx={{ my: 1 }}></Divider>
                </Box>
              </Box>
            ))
          }
          <Button
            onClick={handlePrevPageClick}
          >上一页</Button>
          <Button
            variant="text"
          >{currentPage + 1}</Button>
          <Button
            onClick={handleNextPageClick}
          >下一页</Button>
        </Box>
      }
    </Dialog>
  )
}

export default LogsDialog