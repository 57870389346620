import { Typography, Dialog, Checkbox, Box, FormControlLabel, DialogTitle, DialogActions, Button, TextField } from "@mui/material"
import React from "react"

function ExportProductsConfig(props: any) {

  const [config, setConfig] = React.useState(JSON.parse(JSON.stringify(props.config)))

  const handleCheckboxChange = (key: string) => (event: any) => {
    config[key].checked = !config[key].checked
    setConfig(JSON.parse(JSON.stringify(config)))
  }

  React.useEffect(() => {
    // setConfig()
  }, [props.open])

  return <Dialog
    open={props.open}
    onClose={props.onClose}
    maxWidth="md"
    fullWidth
  >
    <DialogTitle>导出选项（是否展示）</DialogTitle>
    <Box sx={{ display: 'flex', p: 4, justifyContent: 'space-between' }}>
      {
        Object.entries(config).map((item: any) =>
          <FormControlLabel key={item[0]} label={item[1].label} control={
            <Checkbox
              checked={item[1].checked}
              onChange={handleCheckboxChange(item[0])}
            />
          } />
        )
      }
      <TextField label="汇率" type="number" value={props.warehouse_stock_price_ratio} onChange={(event) => { props.setWarehouse_stock_price_ratio(parseFloat(event.target.value)) }}></TextField>
    </Box>
    <DialogActions>
      <Button
        sx={{ m: 1 }}
        onClick={() => props.onClose(false)}
      >
        {props.showSubmitButton !== false ? "取消" : "关闭"}
      </Button>

      <Button
        onClick={()=>{props.submit(config);props.onClose(false)}}
      >
        {"提交"}
      </Button>

    </DialogActions>
  </Dialog>
}

export default ExportProductsConfig