import { Autocomplete, TextField, InputAdornment, CircularProgress, AutocompleteChangeReason } from "@mui/material"
import React, { SyntheticEvent } from "react"
import SearchIcon from '@mui/icons-material/Search'
import CMSApi from '../../utils/opsloth_cms_service_api'

interface Products {
  _id: string
  name: string
  sum_stock: number
  jancode: string
}

function WarehouseStocksChooseList(props: any) {
  const [data, setData] = React.useState<Products[]>([])
  const [loading, setLoading] = React.useState(false)
  const [keyword, setKeyword] = React.useState('')

  const warehouseStockProductsModel = new CMSApi('products', 'https://opsloth.com/warehouse_stock')

  const getStocksByKeyword = async (keyword: string | undefined) => {
    setLoading(true)
    const productsRes: any = await warehouseStockProductsModel
      .setFilter({
        $or:[{name: { $regex: keyword }}, {jancode: { $regex: keyword }}],
        deleted: {$ne: true}
      })
      .setField('_id name deleted jancode')
      .setOptions({
        limit: 20
      })
      .get()
    let stocks_temp = []
    for (let i = 0; i < productsRes.data.length; i++) {
      productsRes.data[i].id = productsRes.data[i]._id
      stocks_temp.push({ $toObjectId: productsRes.data[i]._id })
    }
    const realStocksRes: any = await getRealStocks(stocks_temp)
    productsRes.data.forEach((products: any, product_index: number) => {
      realStocksRes.data.forEach((stock: any) => {
        if (products._id == stock._id) {
          productsRes.data[product_index].sum_stock = stock.sum_stock
          // productsRes.data[product_index].name += productsRes.data[product_index].jancode
        }
      })
    })
    setData(productsRes.data)
    setLoading(false)
  }

  const getRealStocks = (products_id: any) => {
    return new Promise(async (resolve, reject) => {
      const stocksModal = new CMSApi('', 'https://opsloth.com/warehouse_stock/stocks/aggregate')
      const stocksRes: any = await stocksModal
        .post([
          {
            $match: {
              $expr: { $in: ['$product_id', products_id] },
              deleted: { $ne: true },
              stock: { $gt: 0 },
              $and: [{sku_id: { $nin: ['', undefined] }}, {warehouse_name: {$nin: ["代购房间-零售预售","打包室-预售批发区", "办公室-零售预售区", "零售预售","预售+批发","瑕疵破损","囤货箱"]}}],
            }
          },
          {
            $group: {
              _id: "$product_id",
              sum_stock: { $sum: '$stock' }
            }
          }
        ])
      resolve(stocksRes)
    })
  }

  const handleOnChange = async (event: SyntheticEvent<Element, Event>, value: Products | null, reason: AutocompleteChangeReason) => {
    props.setWarehouseStockProduct(value)
  }

  const handleOnInputChange = (event: SyntheticEvent<Element, Event>, value: string) => {
    setKeyword(value)
  }

  const handleOnKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      getStocksByKeyword(keyword)
    }
  }

  React.useEffect(() => {
    props.setWarehouseStockProduct(null)
  }, [])

  return (
    <div>
      <Autocomplete
        options={data}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        onKeyDown={handleOnKeyDown}
        getOptionLabel={(option) => option.name + option.jancode}
        renderOption={(props, option) => <li {...props} key={option._id}>{option.name} <br></br>JANCODE: {option.jancode} 现货库存：{option.sum_stock ?? 0}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            label="选择库存"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  )
}

export default WarehouseStocksChooseList