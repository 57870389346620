import React from 'react'
import { Dialog, Grid, Box, Typography, Button, Container, MenuItem, Select, FormHelperText, Checkbox, Chip, Divider, List, ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material"
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CMSApi from '../../utils/opsloth_cms_service_api'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import CachedIcon from '@mui/icons-material/Autorenew';
import RefreshIcon from '@mui/icons-material/Refresh';
import TagsChooseRule from './TagsChooseRule'
import savefile from '../../utils/savefile';
import ExcelJS from 'exceljs'

import TagsThumbnail from './TagsThumbnail'
import '../../css/TagsGenerate.css'
import { Label, ProductionQuantityLimits } from '@mui/icons-material'

interface FlexItemInfo {
  element: any
  id: string
  x: number
  y: number
  width: number
  height: number
}


function TagsGenerate(props: any) {
  const [items, setItems] = React.useState([
    { id: "1", text: "Item 1" },
    { id: "2", text: "Item 2" },
    { id: "3", text: "Item 3" },
    { id: "4", text: "Item 4" }
  ]);
  const [TagsChooseRuleOpen, setTagsChooseRuleOpen] = React.useState(false)

  const [tagGenerateRow, setTagGenerateRow] = React.useState(1)
  const [tagGenerateColumn, setTagGenerateColumn] = React.useState(1)
  const [tagGenerateCount, setTagGenerateCount] = React.useState(1)

  const [checkArray, setCheckArray] = React.useState([false])

  const [rules, setRules] = React.useState<any[]>([])
  const jointRules = React.useRef<any[]>([])

  const [products, setProducts] = React.useState<any[]>([])
  const usedProduct = React.useRef(new Set())

  const onDragIndex = React.useRef([-1, -1])
  const onDropIndex = React.useRef([-1, -1])
  const onDragTarget: any = React.useRef()
  const onDragEnterIndex = React.useRef([-1, -1])
  const oldFlexItemsInfo: any = React.useRef()

  const handleCheckboxChange = (event: any) => {
    checkArray[event.target.dataset.index] = event.target.checked
    setCheckArray([...checkArray])
  }

  const onRuleChosen = (rule: any) => {
    addRule(rule)
  }

  const addRule = (rule: any) => {
    rule.checkArray = JSON.parse(JSON.stringify(checkArray))
    rules.push(rule)
    setRules([...rules])
  }

  const handleGenerateClick = async () => {
    const products = await getProductsByRule(getJointRule(rules))
    productsFormat(products)
  }

  const getJointRule = (rules: any) => {
    let rulesTemp: any = {}

    for (let i = 0; i < tagGenerateRow * tagGenerateColumn; i++) {
      let tempCheckCount: any = []
      for (let j = 0; j < rules.length; j++) {
        if (rules[j].checkArray[i] == true) {
          tempCheckCount.push(j)
        }
      }
      if (tempCheckCount.length > 0) {
        if (!rulesTemp[JSON.stringify(tempCheckCount)]) {
          rulesTemp[JSON.stringify(tempCheckCount)] = {}
          rulesTemp[JSON.stringify(tempCheckCount)].checkArray = []
        }
        for (let k = 0; k < tempCheckCount.length; k++) {
          Object.keys(rules[k]).forEach((key: string) => {
            if (key != 'checkArray') {
              if (rulesTemp[JSON.stringify(tempCheckCount)][key]) {
                rulesTemp[JSON.stringify(tempCheckCount)][key].push(...rules[tempCheckCount[k]][key])
              } else {
                rulesTemp[JSON.stringify(tempCheckCount)][key] = [...rules[tempCheckCount[k]][key]]
              }
              rulesTemp[JSON.stringify(tempCheckCount)][key] = (rulesTemp[JSON.stringify(tempCheckCount)][key].filter((item: any, index: number) => {
                return rulesTemp[JSON.stringify(tempCheckCount)][key].indexOf(item) === index
              })).sort()
            }
          })
        }
        rulesTemp[JSON.stringify(tempCheckCount)].checkArray[i] = true
      }
    }
    jointRules.current = Object.values(rulesTemp)
    console.log(jointRules.current)
    return Object.values(jointRules.current)
  }

  const getProductsByRule = async (jointRules: any) => {
    // 随机获取已到货商品
    const productModal = new CMSApi('', 'https://cms.opsloth.com/cms_service/products/aggregate')
    const facetTemp: { [key: number | string]: any } = {}
    facetTemp['noRule'] = []
    jointRules.forEach((rule: any, index: number) => {
      facetTemp[index] = [{ $match: {} }]
      Object.keys(rule).forEach(key => {
        if (key != 'checkArray' && rule[key].length > 0) {
          if (key == 'tags') {
            facetTemp[index][0].$match[key] = { $all: rule[key] }
          } else {
            facetTemp[index][0].$match[key] = { $in: rule[key] }
          }
        }
      })
      //多获取一些商品，防止商品过多重复
      facetTemp[index].push({ $sample: { size: 2 * tagGenerateCount * rule.checkArray.filter((item: boolean) => item).length } })
    })

    facetTemp['noRule']?.push({ $sample: { size: 2 * tagGenerateRow * tagGenerateColumn * tagGenerateCount } })
    const productsRes: any = await productModal.post([
      {
        $match: {
          arrived: '已到货',
          deleted: { $ne: true },
          warehouse_stock_product_id: { $nin: ['', undefined] }
        }
      },
      {
        $project: {
          brand: true,
          mainImage: true,
          name_zh: true,
          price_jpy: true,
          tags: true,
          warehouse_stock_product_id: true,
          warehouse_stock_product_name: true
        }
      },
      {
        $facet: facetTemp
      }
    ])

    //获取已到货商品库存数量
    //TODO filter warehouse_stock_product_ids
    let warehouse_stock_product_ids = new Array()
    for (let ruleIndex of Object.keys(productsRes.data[0])) {
      for (let product of productsRes.data[0][ruleIndex]) {
        warehouse_stock_product_ids.push({ $toObjectId: (product as any).warehouse_stock_product_id })
      }
    }

    const stocksModal = new CMSApi('', 'https://opsloth.com/warehouse_stock/stocks/aggregate')
    const stocksRes: any = await stocksModal
      .post([
        {
          $match: {
            $expr: { $in: ['$product_id', warehouse_stock_product_ids] },
            deleted: { $ne: true },
            $and: [{sku_id: { $nin: ['', undefined] }}, {warehouse_name: {$nin: ["代购房间-零售预售", "打包室-预售批发区", "办公室-零售预售区", "零售预售","预售+批发","瑕疵破损","囤货箱"]}}],
            // sku_id: { $nin: ['', undefined] },
          }
        },
        {
          $group: {
            _id: "$product_id",
            sum_stock: { $sum: '$stock' }
          }
        }
      ])

    //set products stocks
    let stocksMap = new Map()
    stocksRes.data.forEach((item: any) => {
      stocksMap.set(item._id, item.sum_stock)
    })

    for (let ruleIndex of Object.keys(productsRes.data[0])) {
      for (let product of productsRes.data[0][ruleIndex]) {
        product.stocks = stocksMap.get(product.warehouse_stock_product_id)
      }
    }

    return productsRes.data
  }

  //商品按照组数分组，按照库存数量排序
  const productsFormat = (products: any) => {
    usedProduct.current = new Set()
    Object.keys(products[0]).forEach((key) => {
      products[0][key].sort((a: any, b: any) => {
        return (a.stocks ?? 0) - (b.stocks ?? 0)
      })
    })

    let productsTemp = Array.from(new Array(tagGenerateCount), () => new Array())

    for (let i = 0; i < tagGenerateCount; i++) {
      for (let j = 0; j < tagGenerateRow * tagGenerateColumn; j++) {
        let added = false
        for (let x = 0; x < jointRules.current.length; x++) {
          if (jointRules.current[x].checkArray[j]) {
            if (!usedProduct.current.has(products[0][x][products[0][x].length - 1]?._id)) {
              productsTemp[i][j] = products[0][x][products[0][x].length - 1] ?? {}
              usedProduct.current.add(products[0][x][products[0][x].length - 1]?._id)
              added = true
            } else {
              j--
            }
            products[0][x].splice(products[0][x].length - 1, 1)
          }
        }

        if (!added) {
          if (!usedProduct.current.has(products[0]['noRule'][products[0]['noRule'].length - 1]?._id)) {
            productsTemp[i][j] = products[0]['noRule'][products[0]['noRule'].length - 1] ?? {}
            usedProduct.current.add(products[0]['noRule'][products[0]['noRule'].length - 1]?._id)
          } else {
            j--
          }
          products[0]['noRule'].splice(products[0]['noRule'].length - 1, 1)
        }
        usedProduct.current.delete(undefined)
      }
    }
    console.log(productsTemp)
    setProducts([...productsTemp])
  }

  const getProductAgain = (tableIndex: number, productIndex: number) => async (event: any) => {
    event.stopPropagation()

    let aggregateMatch: any = {
      $match: {
        arrived: '已到货',
        deleted: { $ne: true },
        warehouse_stock_product_id: { $nin: ['', undefined] }
      }
    }
    let ruleClickedTemp: any = 'noRule'
    for (let x = 0; x < jointRules.current.length; x++) {
      if (jointRules.current[x].checkArray[productIndex]) {
        ruleClickedTemp = x
      }
    }
    if (ruleClickedTemp != 'noRule') {
      Object.keys(jointRules.current[ruleClickedTemp]).forEach(key => {
        if (key != 'checkArray' && jointRules.current[ruleClickedTemp][key].length > 0) {
          if (key == 'tags') {
            aggregateMatch.$match[key] = { $all: jointRules.current[ruleClickedTemp][key] }
          } else {
            aggregateMatch.$match[key] = { $in: jointRules.current[ruleClickedTemp][key] }
          }
        }
      })
    }

    const productModal = new CMSApi('', 'https://cms.opsloth.com/cms_service/products/aggregate')
    const productRes: any = await productModal.post([
      aggregateMatch,
      {
        $sample: {
          size: 1
        }
      },
      {
        $project: {
          brand: true,
          mainImage: true,
          name_zh: true,
          price_jpy: true,
          tags: true,
          warehouse_stock_product_id: true,
          warehouse_stock_product_name: true
        }
      }
    ])
    const stocksModal = new CMSApi('', 'https://opsloth.com/warehouse_stock/stocks/aggregate')
    const stocksRes: any = await stocksModal
      .post([
        {
          $match: {
            $expr: { $in: ['$product_id', [{ "$toObjectId": productRes.data[0].warehouse_stock_product_id }]] },
            deleted: { $ne: true },
            $and: [{sku_id: { $nin: ['', undefined] }}, {warehouse_name: {$nin: ["代购房间-零售预售","打包室-预售批发区", "办公室-零售预售区", "零售预售","预售+批发","瑕疵破损","囤货箱"]}}],
            // sku_id: { $nin: ['', undefined] },
          }
        },
        {
          $group: {
            _id: "$product_id",
            sum_stock: { $sum: '$stock' }
          }
        }
      ])
    productRes.data[0].stocks = stocksRes.data[0]?.sum_stock ?? -1
    changeProduct(tableIndex, productIndex, productRes.data[0])
  }

  const getProductRandom = (tableIndex: number, productIndex: number) => async (event: any) => {
    event.stopPropagation()

    const productModal = new CMSApi('', 'https://cms.opsloth.com/cms_service/products/aggregate')
    const productRes: any = await productModal.post([
      {
        $match: {
          arrived: '已到货',
          deleted: { $ne: true },
          warehouse_stock_product_id: { $nin: ['', undefined] }
        }
      },
      {
        $sample: {
          size: 1
        }
      },
      {
        $project: {
          brand: true,
          mainImage: true,
          name_zh: true,
          price_jpy: true,
          tags: true,
          warehouse_stock_product_id: true,
          warehouse_stock_product_name: true
        }
      }
    ])
    const stocksModal = new CMSApi('', 'https://opsloth.com/warehouse_stock/stocks/aggregate')
    const stocksRes: any = await stocksModal
      .post([
        {
          $match: {
            $expr: { $in: ['$product_id', [{ "$toObjectId": productRes.data[0].warehouse_stock_product_id }]] },
            deleted: { $ne: true },
            $and: [{sku_id: { $nin: ['', undefined] }}, {warehouse_name: {$nin: ["代购房间-零售预售","打包室-预售批发区", "办公室-零售预售区", "零售预售","预售+批发","瑕疵破损","囤货箱"]}}],
            // sku_id: { $nin: ['', undefined] }
          }
        },
        {
          $group: {
            _id: "$product_id",
            sum_stock: { $sum: '$stock' }
          }
        }
      ])
    productRes.data[0].stocks = stocksRes.data[0]?.sum_stock ?? -1
    changeProduct(tableIndex, productIndex, productRes.data[0])
  }

  const changeProduct = (tableIndex: number, productIndex: number, product: any) => {
    if (usedProduct.current.has(product._id)) {
      alert('获取到重复的了，请重新获取')
    } else {
      products[tableIndex].splice(productIndex, 1, product)
      setProducts([...products])
    }
  }

  const onSetTakeoutStockClick = (tableIndex: number, productIndex: number) => (event: any) => {
    const input: string | null = window.prompt('输入需要带的数量')
    if (parseInt(input ?? '0') > 0) {
      products[tableIndex][productIndex].takeoutStock = parseInt(input ?? '0')
    } else {
      products[tableIndex][productIndex].takeoutStock = undefined
    }
    setProducts([...products])
  }

  const onDragDown = (tableIndex: number, productIndex: number) => (event: any) => {
    onDragIndex.current = [tableIndex, productIndex]
  }

  const onDragMove = (tableIndex: number, productIndex: number) => (event: any) => {
    event.preventDefault()
  }

  const onDragEnter = (tableIndex: number, productIndex: number) => (event: any) => {

  }

  const onDrop = (tableIndex: number, productIndex: number) => (event: any) => {
    onDropIndex.current = [tableIndex, productIndex]
    let container: any = document.getElementById('tagsGenerateProductsTable_' + onDragIndex.current[0])
    let container2: any = document.getElementById('tagsGenerateProductsTable_' + onDropIndex.current[0])
    console.log(onDragIndex.current[0])
    oldFlexItemsInfo.current = getFlexItemsInfo(container.childNodes).concat(getFlexItemsInfo(container2.childNodes))
    let productTemp = products[onDragIndex.current[0]][onDragIndex.current[1]]

    products[onDragIndex.current[0]].splice(onDragIndex.current[1], 1)
    products[tableIndex].splice(productIndex, 0, productTemp)

    setProducts([...products])
  }

  React.useEffect(() => {
    let container: any = document.getElementById('tagsGenerateProductsTable_' + onDropIndex.current[0])
    let container2: any = document.getElementById('tagsGenerateProductsTable_' + onDragIndex.current[0])
    if (container){
      const newFlexItemsInfo: any = getFlexItemsInfo(container.childNodes).concat(getFlexItemsInfo(container2.childNodes))
      aminateFlexItems(oldFlexItemsInfo.current, newFlexItemsInfo)
    }


  }, [products])


  function getFlexItemsInfo(container: any): FlexItemInfo[] {
    return Array.from(container)?.map((item: any) => {
      const rect = item.getBoundingClientRect()
      return {
        id: item.id,
        element: item,
        x: rect.left,
        y: rect.top,
        width: rect.right - rect.left,
        height: rect.bottom - rect.top,
      }
    })
  }

  function aminateFlexItems(
    oldFlexItemsInfo: FlexItemInfo[],
    newFlexItemsInfo: FlexItemInfo[]
  ): void {
    for (const newFlexItemInfo of newFlexItemsInfo) {

      const oldFlexItemInfo: any = oldFlexItemsInfo.find(
        (itemInfo: any) => itemInfo.id === newFlexItemInfo.id
      )
      let translateX = 0
      let translateY = 0
      let scaleX = 0
      let scaleY = 0
      if (oldFlexItemInfo) {
        translateX = oldFlexItemInfo.x - newFlexItemInfo.x
        translateY = oldFlexItemInfo.y - newFlexItemInfo.y
        scaleX = oldFlexItemInfo.width / newFlexItemInfo.width
        scaleY = oldFlexItemInfo.height / newFlexItemInfo.height
      }
      newFlexItemInfo.element.animate(
        [
          {
            transform: `translate(${translateX}px, ${translateY}px) scale(${scaleX}, ${scaleY})`,
          },
          { transform: 'none' },
        ],
        {
          duration: 300,
          easing: 'ease-out',
        }
      )
    }
  }

  const handleXLSXClick = async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'opsloth-cms';
    workbook.lastModifiedBy = 'opsloth-cms';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.properties.date1904 = true;

    const sheet = workbook.addWorksheet('Sheet1')

    products.forEach((productsByTable, tableIndex: number) => {
      for (let i = 0; i < tagGenerateRow + 1; i++) {
        let rows: any = []
        let rows_takeoutStock: any = []
        for (let j = 0; j < tagGenerateColumn; j++) {
          sheet.getColumn(j + 1).width = 60
          sheet.getColumn(j + 1).alignment = { horizontal: 'left' };
          if (productsByTable[i * tagGenerateColumn + j]) {
            rows.push((productsByTable[i * tagGenerateColumn + j].warehouse_stock_product_name ?? "无结果"))
            rows_takeoutStock.push((productsByTable[i * tagGenerateColumn + j].takeoutStock ?? "无结果"))
          }
        }
        sheet.getRow((tableIndex * (tagGenerateRow + 1) + i + 1) * 2).values = rows
        sheet.getRow((tableIndex * (tagGenerateRow + 1) + i + 1) * 2 + 1).values = rows_takeoutStock
      }
    })

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.ms-excel" })
    savefile(`扭蛋机列表${new Date().toLocaleString().replaceAll('/', '-')}.xlsx`, blob)
  }

  return (

    <Container maxWidth="xl" sx={{ pb: 5 }}>
      <Box sx={{ display: 'block', width: 'fit-content', m: 'auto' }}>
        <Box sx={{ display: 'flex', py: 3 }}>
          <Box sx={{ display: 'flex', background: '#d8edfc', borderRadius: '10em', mx: 2 }}>
            <RemoveCircleIcon color='primary' sx={{ cursor: 'pointer' }} onClick={() => {
              if (rules.length == 0) {
                setTagGenerateRow(tagGenerateRow > 1 ? (tagGenerateRow - 1) : 1)
                setCheckArray([false])
              } else {
                alert('已设定规则，无法变更排列')
              }
            }} />
            <Typography>{tagGenerateRow}行</Typography>
            <AddCircleIcon color='primary' sx={{ cursor: 'pointer' }} onClick={() => {
              if (rules.length == 0) {
                setTagGenerateRow(tagGenerateRow + 1 > 3 ? 3 : tagGenerateRow + 1)
                setCheckArray([false])
              } else {
                alert('已设定规则，无法变更排列')
              }
            }} />
          </Box>
          <Box sx={{ display: 'flex', background: '#d8edfc', borderRadius: '10em', mx: 2 }}>
            <RemoveCircleIcon color='primary' sx={{ cursor: 'pointer' }} onClick={() => {
              if (rules.length == 0) {
                setTagGenerateColumn(tagGenerateColumn > 1 ? tagGenerateColumn - 1 : 1)
                setCheckArray([false])
              } else {
                alert('已设定规则，无法变更排列')
              }
            }} />
            <Typography>{tagGenerateColumn}列</Typography>
            <AddCircleIcon color='primary' sx={{ cursor: 'pointer' }} onClick={() => {
              if (rules.length == 0) {
                setTagGenerateColumn(tagGenerateColumn + 1 > 12 ? 12 : tagGenerateColumn + 1)
                setCheckArray([false])
              } else {
                alert('已设定规则，无法变更排列')
              }
            }} />
          </Box>
          <Box sx={{ display: 'flex', background: '#d8edfc', borderRadius: '10em', mx: 2 }}>
            <RemoveCircleIcon color='primary' sx={{ cursor: 'pointer' }} onClick={() => {
              setTagGenerateCount(tagGenerateCount > 1 ? tagGenerateCount - 1 : 1)
            }} />
            <Typography>{tagGenerateCount}组</Typography>
            <AddCircleIcon color='primary' sx={{ cursor: 'pointer' }} onClick={() => {
              setTagGenerateCount(tagGenerateCount + 1)
            }} />
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'block', m: 'auto', width: 'fit-content' }}>
        {
          (
            Array.from({ length: tagGenerateRow })).map((itemRow, indexRow) =>
              <Box key={indexRow} sx={{ display: 'flex', width: '100%' }}>
                {
                  (Array.from({ length: tagGenerateColumn })).map((itemColumn, indexColumn) => {
                    return <Checkbox
                      key={indexRow * tagGenerateColumn + indexColumn}
                      inputProps={{
                        // @ts-ignore
                        'data-index': indexRow * tagGenerateColumn + indexColumn,
                      }}
                      checked={checkArray[indexRow * tagGenerateColumn + indexColumn] ?? false}
                      onChange={handleCheckboxChange}
                    />
                  })
                }
              </Box>
            )
        }
      </Box>
      <Box sx={{ display: 'block', width: 'fit-content', m: 'auto' }}>
        <Button disabled={!checkArray.includes(true)} onClick={() => { setTagsChooseRuleOpen(true) }}>设置规则</Button>
        <Button disabled={!checkArray.includes(true)} onClick={() => { setCheckArray([false]) }}>清空选择</Button>
      </Box>
      <Box sx={{ px: 3, py: 1 }}>
        {
          rules.map((rule, index) =>
            <Box key={index} sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <TagsThumbnail
                checkArrayGenerated={rule.checkArray}
                tagGenerateRow={tagGenerateRow}
                tagGenerateColumn={tagGenerateColumn}
              ></TagsThumbnail>
              {
                Object.keys(rule).map((key, index) =>
                  key != 'checkArray'
                    ? rule[key].map((value: string | number) =>
                      <Chip key={index + '/' + value} label={value} size="small" sx={{ m: .2 }} />
                    )
                    : <div key={index + '/'}></div>
                )
              }
              <Chip
                color="warning"
                label="删除"
                sx={{ m: .2 }}
                size="small"
                onDelete={() => {
                  rules.splice(index, 1)
                  setRules([...rules])
                }}
              />
            </Box>
          )
        }
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 2 }}>
        <Button onClick={handleGenerateClick}>生成</Button>
        <Button onClick={handleXLSXClick}>导出</Button>
        <Button onClick={() => { props.onClose() }}>取消</Button>
      </Box>

      <Box id={'tagsGenerateProductsTable'}>
        {
          products.map((productsTable, tableIndex: number) =>
            <Box id={'tagsGenerateProductsTable_' + tableIndex} key={tableIndex} sx={{ display: 'flex', flexWrap: 'wrap', my: 2 }}>
              <Typography id={'tagsGenerata_tag_box_' + tableIndex} sx={{ width: '100%' }}>第 {tableIndex + 1} 组</Typography>
              {
                productsTable.map((product: any, productIndex: number) =>
                  <Box
                    id={'tagsGenerata_tag_box_' + product._id}
                    className="tagsGenerate_tags_box"
                    key={tableIndex + ' ' + productIndex}
                    draggable="true"
                    onClick={onSetTakeoutStockClick(tableIndex, productIndex)}
                    onDragStart={onDragDown(tableIndex, productIndex)}
                    onDragOver={onDragMove(tableIndex, productIndex)}
                    onDragEnter={onDragEnter(tableIndex, productIndex)}
                    onDrop={onDrop(tableIndex, productIndex)}
                    sx={{
                      width: `calc(${Math.floor(100 / tagGenerateColumn)}% - 24px)`,
                      m: '4px',
                      p: '8px',
                      background: product?._id ? '#d8edfc' : '#ffe891',
                      borderRadius: '1em',
                      cursor: 'pointer'
                    }}
                  >
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Chip size="small" label={product?.brand ?? '无品牌'} sx={{ mr: .5, cursor: 'pointer' }} />
                        <Chip size="small" label={'￥' + product?.price_jpy ?? ''} sx={{ cursor: 'pointer' }} />
                      </Box>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <CachedIcon
                          sx={{ width: '.8em', transform: 'rotate(45deg)' }}
                          className='tagsGenerate_CachedIcon'
                          onClick={getProductAgain(tableIndex, productIndex)}

                        />
                        <RefreshIcon
                          sx={{ width: '.8em' }}
                          className='tagsGenerate_CachedIcon'
                          onClick={getProductRandom(tableIndex, productIndex)}
                        />
                        <Typography sx={{ fontSize: '.5em', lineHeight: '3em', px: '10px', fontWeight: product?.stocks < 40 || product?.stocks < product?.takeoutStock ? 'bold' : 'none', color: product?.stocks < 40 || product?.stocks < product?.takeoutStock ? '#dc3b3b' : '#1976d2' }}>库存:{product?.takeoutStock ?? ''}/{product?.stocks ?? '无'}</Typography>
                      </Box>
                    </Box>
                    <Typography sx={{ fontSize: '.5em' }}>{product?.warehouse_stock_product_name ?? '无中文名 / 无匹配项'}</Typography>

                    {
                      product?.tags?.map((tag: any) =>
                        <Chip key={tag} size="small" label={tag ?? '无标签'} sx={{ mr: .5 }} />
                      )
                    }
                  </Box>
                )
              }

            </Box>
          )
        }
      </Box>

      <TagsChooseRule
        open={TagsChooseRuleOpen}
        onClose={() => { setTagsChooseRuleOpen(false) }}
        onRuleChosen={onRuleChosen}
      ></TagsChooseRule>
    </Container>

  )
}

export default TagsGenerate