import React from 'react'
import { Button, Checkbox, FormControlLabel, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import axios from 'axios'
import getSmallerImgUrl from "../../utils/getSmallerImgUrl";
import ProductsLogsDialog from './ProductsLogsDialog';
const minimal_base64_img = 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

function TableListRow(props: any) {
  const [productsLogsDialogOpen, setProductsLogsDialogOpen] = React.useState(false)
  const productsLogsClickedId = React.useRef('')


  const processContent = (key: string, data: { [x: string]: string | number }) => {
    return key === 'createdAt' || key === 'updatedAt' || key === 'launch_date' || key === 'arrive_date' || key === 'cut_off_date'
      ? key === 'launch_date' || key === 'arrive_date' || key === 'cut_off_date'
        ? data[key]
          ? new Date(data[key]).toLocaleDateString()
          : '<未设定日期>'
        : new Date(data[key]).toLocaleString()
      : (key === 'mainImage' || key === 'images')
        ? `${data[key] == undefined || data[key] == "" ? "<无>" : ""}`
        : `${data[key] !== undefined && data[key] !== '' && data[key] !== 0 && data[key] !== null && data[key].toString().replaceAll(' ', '') !== '' ? data[key] : '<无>'}`
  }

  const onProductsLogsClick = (_id: string) => (event: any) => {
    setProductsLogsDialogOpen(!productsLogsDialogOpen)
    productsLogsClickedId.current = _id
  }


  return (
    <TableRow
      key={props.item._id}
      hover
      selected={props.checkboxSelected}
    >
      <TableCell
        padding="checkbox"
      >
        <FormControlLabel
          labelPlacement='start'
          label={`${(props.index + 1) + props.pageLimit * (props.currentPage)}.`}
          control={
            <Checkbox
              onClick={(event) => { props.handleSubCheckboxClick(event, props.index) }}
              checked={props.checkboxSelected}
            />
          }
        />
      </TableCell>
      {
        Object.keys(props.schema).map((key: any, index2: number) => {
          return (
            props.schema[key].display !== false && <TableCell
              key={`${props.item}_${index2}`}
              sx={{ px: 1, py: key === 'mainImage' ? 0 : null }}
            >{
                <Tooltip
                  title={key === 'name_zh' || key === 'name_jp' || key === 'warehouse_stock_product_name' ? props.item[key] : ''}
                  placement='right'
                >
                  <Typography
                    sx={{ cursor: 'pointer', whiteSpace: 'nowrap', maxWidth: key == "images" ? '6em' : '14em', overflowX: key === 'mainImage' ? 'visiable' : 'hidden', width: 'fit-content', display: 'flex' }}
                    onClick={props.handleEditClick(props.item._id, key, props.item[key], props.index, props.schema[key].editable)}
                    onMouseLeave={() => { document.getElementById('previewHoverImg')!.style.display = 'none' }}
                  >
                    {
                      // key !== 'mainImage' && key !== 'images' &&
                      processContent(key, props.item)
                    }
                    {
                      key === 'mainImage' && props.item[key]
                      && <img
                        id={props.item._id + '_img'}
                        data-src={getSmallerImgUrl(props.item[key])}
                        data-base64ed={props.item['base64ed'] ? 0 : props.item['base64ed']}
                        src={props.item['base64ed'] ? minimal_base64_img : getSmallerImgUrl(props.item[key], 24)}
                        style={{ width: '2em', height: '2em', objectFit: 'cover', transformOrigin: 'left center' }}
                        onMouseOver={async () => { document.getElementById('previewHoverImg')!.setAttribute('src', await props.handleGetImgWithCache(props.item[key], props.item._id, 0)); document.getElementById('previewHoverImg')!.style.display = 'block'}}
                        onMouseLeave={() => { document.getElementById('previewHoverImg')!.style.display = 'none' }}
                      ></img>
                    }
                    {
                      key === 'images' && props.item[key]
                      && props.item[key].map((img: string, img_index: number) => {
                        if (img_index <= 2){
                          return <img
                            key={img_index}
                            src={getSmallerImgUrl(img, 24)}
                            style={{ width: '2em', height: '2em', objectFit: 'cover', transformOrigin: 'left center' }}
                            onMouseOver={async () => { document.getElementById('previewHoverImg')!.setAttribute('src', await props.handleGetImgWithCache(img, props.item._id, 0)); document.getElementById('previewHoverImg')!.style.display = 'block' }}
                            onMouseLeave={() => { document.getElementById('previewHoverImg')!.style.display = 'none' }}
                          ></img>
                        }
                      }
                      )
                    }
                  </Typography>
                </Tooltip>
              }
            </TableCell>
          )
        })
      }
      <TableCell>
        <Typography
          sx={{ whiteSpace: 'nowrap' }}
          onClick={onProductsLogsClick(props.item._id)}
        >日志</Typography>
      </TableCell>
      <ProductsLogsDialog
        open={productsLogsDialogOpen}
        setOpen={setProductsLogsDialogOpen}
        _id={productsLogsClickedId.current}
      ></ProductsLogsDialog>
    </TableRow>
  )
}

export default TableListRow