import { Autocomplete, AutocompleteChangeReason, Box, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import React, { SyntheticEvent } from 'react'
import CMSApi from '../../utils/opsloth_cms_service_api'
import SearchIcon from '@mui/icons-material/Search'

interface Customer {
  _id: string
  company: string
  customer_type: string
  customer_types: []
}

function CustomerChooseList(props: any) {
  const pageLimit = 20
  const [data, setData] = React.useState<readonly Customer[]>([])
  const [customerAutoCompleteOpen, setCustomerAutoCompleteOpen] = React.useState(false)
  const [customerLoading, setCustomerLoading] = React.useState(false)
  const [customerKeyword, setCustomerKeyword] = React.useState<string | undefined>()
  const customerTypesModel = new CMSApi('customer_types')

  const getCustomersByKeyword = async (keyword: string | undefined) => {
    setCustomerLoading(true)

    let customersModel
    if (props.category == '海关') {
      customersModel = new CMSApi('custom_customers')
    } else {
      customersModel = new CMSApi('customers')
    }

    const customerRes: any = await customersModel
      .setFilter({
        company: { $regex: keyword ?? '' }
      })
      .setField("_id company customer_type")
      .setOptions({
        limit: pageLimit,
      })
      .get()
    setData(customerRes.data)

    setCustomerLoading(false)
  }

  const getCustomerTypesByCustomer = (customer: Customer | null) => {
    return new Promise(async (resolve, reject) => {
      const customerTypesRes: any = await customerTypesModel
        .setFilter({
          name: customer?.customer_type,
          deleted: { $ne: true }
        })
        .setField('-createdAt -updatedAt')
        .get()

      resolve(customerTypesRes.data)
    })
  }

  const handleCustomerOnChange = async (event: SyntheticEvent<Element, Event>, value: Customer | null, reason: AutocompleteChangeReason) => {
    if (reason === 'selectOption' && value?.customer_type) {
      value.customer_types = await getCustomerTypesByCustomer(value) as any
    }
    props.setReturnData(value)
  }

  const handleCustomerOnInputChange = (event: SyntheticEvent<Element, Event>, value: string) => {
    setCustomerKeyword(value)
  }

  const handleCustomerOnKeyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      getCustomersByKeyword(customerKeyword)
    }
  }

  React.useEffect(() => {
    props.setReturnData()
    getCustomersByKeyword('')
  }, [])

  return (
    <Grid2
      container
      spacing={2}
      sx={{ my: 2 }}
    >

      <Grid2
        xs={12}
      >
        <Autocomplete
          open={customerAutoCompleteOpen}
          onOpen={() => { setCustomerAutoCompleteOpen(true) }}
          onClose={() => { setCustomerAutoCompleteOpen(false) }}
          options={data}
          onChange={handleCustomerOnChange}
          onInputChange={handleCustomerOnInputChange}
          onKeyDown={handleCustomerOnKeyDown}
          isOptionEqualToValue={(item, value) => value ? item.company === value.company : false}
          getOptionLabel={(option) => option ? option.company : option}
          renderInput={(params) => (
            <TextField
              {...params}
              label="选择客户"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <React.Fragment>
                    {customerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid2>
      <Grid2
        xs={12}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', px: 4 }}
      >
        <Typography sx={{ mb: 2 }}>{props.returnData?.customer_type}{props.returnData?.customer_types?.length ? '（以箱、袋的价格优先）' : '无客户分类'}</Typography>
        {
          props.returnData?.customer_types?.map((item: any) =>
            <Box
              key={item._id}
              sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography sx={{ width: '25%' }}>{item.brand}</Typography>
              <Typography sx={{ width: '25%' }}>{item.carton_price_cny ?? 0} (元/箱)</Typography>
              <Typography sx={{ width: '25%' }}>{item.pack_price_cny ?? 0} (元/袋)</Typography>
              <Typography sx={{ width: '25%' }}>{item.single_price_cny ?? 0} (元/个)</Typography>
            </Box>
          )
        }
        {props.category == '海关' && 
          <Typography>订单列表（海关）中不会计算（袋）/（盒）的数量，只计算箱数</Typography>
      }
      </Grid2>
    </Grid2>
  )
}

export default CustomerChooseList