import React, { ChangeEvent } from 'react'
import { TextField, Typography, Card } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import LoginIcon from '@mui/icons-material/Login'
import CMSApi from '../utils/opsloth_cms_service_api'

function Login(props: any) {
	const [account, setAccount] = React.useState('')
	const [nickname, setNickname] = React.useState('')
	const [password, setPassword] = React.useState('')
	const [level, setLevel] = React.useState(0)
	const [loading, setLoading] = React.useState(false)

	const handleAccountChange = (event: ChangeEvent<HTMLInputElement>) => {
		setAccount(event.target.value)
	}

	const handleNicknameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setNickname(event.target.value)
	}

	const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value)
	}

	const handleLevelChange = (event: ChangeEvent<HTMLInputElement>) => {
		setLevel(parseInt(event.target.value))
	}

	const login = async (event: any) => {
		setLoading(true)
		setTimeout(async () => {
			try {
				const user = new CMSApi("users")
				const userRes: any = await user.login({
					account: account,
					password: password
				})
				if (userRes.data.login) {
					// (window as any).user_nickname = '何烈-测试'
					(window as any).user_nickname = userRes.data.nickname;
					(window as any).user_level = userRes.data.level
					props.setLogined(true)
					setLoading(false)
				}
			} catch (e) {
				console.log(e)
				alert('登陆失败')
				setLoading(false)
			}
		}, 1000);
	}

	const register = async (event: any) => {
		setLoading(true)
		setTimeout(async () => {
			try {
				const user = new CMSApi("users")
				const userRes: any = await user.register({
					account: account,
					password: password,
					nickname: nickname.replace('注册',''),
					level: level
				})
				if (userRes.data._id) {
					(window as any).user_nickname = userRes.data.nickname
						(window as any).user_level = userRes.data.level
					props.setLogined(true)
					setLoading(false)
				}
			} catch (e) {
				alert('登陆失败')
				setLoading(false)
			}
		}, 1000);
	}

	React.useEffect(() => {
		login({})
	}, [])// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Card
			sx={{ my: 8, p: 2, display: 'flex', flexDirection: 'column' }}
		>
			<Typography
				variant='h5'
				align='center'
				sx={{ my: 4 }}
			>登录账号</Typography>

			<TextField
				disabled={loading}
				label="账号"
				value={account}
				onChange={handleAccountChange}
				sx={{ mb: 2 }}
			></TextField>

			<TextField
				disabled={loading}
				label="密码"
				value={password}
				type="password"
				onChange={handlePasswordChange}
				sx={{ mb: 2 }}
			></TextField>

			<TextField
				disabled={loading}
				label="昵称"
				value={nickname}
				onChange={handleNicknameChange}
				sx={{ mb: 2 }}
			></TextField>

			{
				nickname.indexOf('注册') == 0 && <TextField
					disabled={loading}
					label="等级"
					type="number"
					value={level}
					onChange={handleLevelChange}
					sx={{ mb: 2 }}
				></TextField>
			}

			<LoadingButton
				loading={loading}
				loadingPosition="end"
				endIcon={<LoginIcon />}
				type="submit"
				variant='contained'
				sx={{ background: '#3b82d5', height: '3rem', fontSize: '1.2rem' }}
				onClick={login}
			>登录</LoadingButton>

			{
				nickname.indexOf('注册') == 0 && <LoadingButton
					loading={loading}
					loadingPosition="end"
					endIcon={<LoginIcon />}
					type="submit"
					variant='contained'
					sx={{ background: '#3b82d5', height: '3rem', fontSize: '1.2rem' }}
					onClick={register}
				>注册（level设置为1）</LoadingButton>
			}
		</Card>
	)
}

export default Login