import { Drawer, List, ListItem, ListItemButton, ListItemText, ListItemIcon, } from "@mui/material";

function LeftDrawer(props: any) {
	return (
		<Drawer
			anchor="left"
			open={props.open}
			onClose={props.onClose}
		>
			<List sx={{ width: 300, marginTop: "50px" }}>
				{props.tabs.map((item: string, index: number) => (
					<ListItem
						key={item}
						disablePadding alignItems="center"
						onClick={props.onChosen(index)}
					>
						<ListItemButton>
							<ListItemIcon></ListItemIcon>
							<ListItemText primary={item} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Drawer>
	);
}

export default LeftDrawer;