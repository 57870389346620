import React, { ReactNode } from 'react'
import { Badge, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import DragAndDrop from '../public/DragAndDrop'
import ExcelJS from 'exceljs'
import CustomerChooseList from '../Customers/CustomerChooseList'
import OrderTableXLSX from './OrderTableXLSX'
import uploadImage from "../../utils/uploadImage"

function JobAddDialog
  (props: any) {
  const [dropedFiles, setDropedFiles] = React.useState<File[]>([])
  const [dropedImgs, setDropedImgs] = React.useState<File[]>([])
  const [dropedWorkbook, setDropedWorkbook] = React.useState<ExcelJS.Workbook>(new ExcelJS.Workbook())

  const handleFilesChange = (files: File[]) => {
    setDropedFiles(files)
    readXLSX(files[0])
  }

  const handleimgsChange = (files: File[]) => {
    setDropedImgs(files)
  }

  const readXLSX = async (file: File) => {
    if (file) {
      var reader = new FileReader();
      reader.onload = async (evt: any) => {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(evt.currentTarget.result)
        analyzeXLSX(workbook)
      }
      reader.readAsArrayBuffer(file);
    } else {
      setDropedWorkbook(new ExcelJS.Workbook())
      console.log(dropedWorkbook)
    }
  }

  const analyzeXLSX = (workbook: ExcelJS.Workbook) => {
    if (workbook) {
      console.log(workbook)
      props.setCustomer(workbook.worksheets[0].name)
      setDropedWorkbook(workbook)
    }
  }

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth='xl'
      onClose={() => { props.onClose(false) }}
    >
      <DialogTitle>{'添加订单'}</DialogTitle>
      <DialogContent>
        <Box sx={{display: 'flex', justifyContent:"space-between"}}>
          <Box sx={{width: "49%"}}>
            <DragAndDrop
              customer_id="order"
              dropedFiles={dropedFiles}
              setDropedFiles={handleFilesChange}
              accept='.xlsx, .xls'
            ></DragAndDrop>
          </Box>
          <Box sx={{width: "49%"}}>
            <DragAndDrop
              customer_id="order_imgs"
              title="付款凭证"
              multiple={true}
              dropedFiles={dropedImgs}
              setDropedFiles={handleimgsChange}
              accept='.png, .jpg, .jpeg'
            ></DragAndDrop>
          </Box>
        </Box>
        {/* <CustomerChooseList
          category={props.category}
          returnData={props.customer}
          setReturnData={props.setCustomer}
        >
        </CustomerChooseList> */}
        <Box sx={{display:'flex'}}>
          <TextField
            required
            label="批发编号"
            value={props.jobName}
            sx={{ width: '48%', my: 3, mr: '2%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.setJobName(event.target.value)
            }}
          />
          <TextField
            required
            label="物流方式"
            value={props.logistics}
            sx={{ width: '48%', my: 3, mr: '2%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.setLogistics(event.target.value)
            }}
          />
          <TextField
            multiline
            required
            rows={3}
            label="收件地址"
            value={props.address}
            sx={{ width: '48%', my: 3 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.setAddress(event.target.value)
            }}
          />
        </Box>
        {
          <Box>
            {
              dropedWorkbook.worksheets.map((sheet, sheet_index) => (
                <Box key={sheet_index}>
                  <Chip
                    label={sheet.name}
                    color="primary"
                    size='small'
                    sx={{ mb: -1.5, ml: -1.5 }}
                  />
                  <OrderTableXLSX
                    sx={{ my: .5 }}
                    key={sheet_index}
                    sheet={sheet}
                  ></OrderTableXLSX>
                </Box>
              ))
            }
          </Box>
        }

      </DialogContent>
      <DialogActions>
        <Button
          sx={{ mx: 1 }}
          onClick={() => { props.onClose(false) }}
        >
          {"取消"}
        </Button>

        <Button
          onClick={async () => {
            if (props.customer) {
              if (window.confirm('确认创建工单？')) {
                //TODO generate orders by dropedworkbook
                const uploaded_img_urls = await uploadImage(dropedImgs, "wechat_warehouse_stock_jobs_pic_payment", "job_payment_imgs")
                props.onSubmit(dropedWorkbook,uploaded_img_urls)
                setDropedWorkbook(new ExcelJS.Workbook())
                setDropedFiles([])
                setDropedImgs([])
              }
            } else {
              props.newAlert('未选择客户无法创建工单!', 'warning')
            }
          }}
        >
          {"提交"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default JobAddDialog
