import CMSApi from '../../utils/opsloth_cms_service_api'
import React from 'react'
import * as echarts from 'echarts/core';
import { BarChart} from "echarts/charts";
import {TitleComponent,TooltipComponent,GridComponent,LegendComponent} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);

function OrdersChart(props: any) {
  const myChart = React.useRef<any>({})
  const [chartType, setChartType] = React.useState('percent')
  const [chartOrders, setChartOrders] = React.useState([])
  const [month, setMonth] = React.useState(new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1))
  const sum_price_cny = React.useRef(0)

  const getOrdersAggregateByCustomer = async () => {
    const dataModel = new CMSApi('orders/aggregate')
    const date = new Date(month)
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1)
    const res: any = await dataModel.post([
      {
        $match: {
          deleted: { $ne: true },
          createdAt: { $gte: date, $lte: lastDay }
        }
      },
      {
        $group: {
          _id: "$company",
          sum_price_cny: { "$sum": "$price_cny" },
          sum_deposit_cny: { "$sum": "$deposit_cny" },
          sum_balance_cny: { "$sum": "$balance_cny" }
        }
      },
      {
        $sort: { _id: 1 }
      }
    ])
    setChartOrders(res.data)
  }

  const processOrders = (orders: [{ _id: string, sum_price_cny: number, sum_deposit_cny: number, sum_balance_cny: number }]) => {
    let labels: string[] = []
    let price_cnys: number[] = []
    let deposit_cnys: number[] = []
    let balance_cnys: number[] = []
    sum_price_cny.current = 0
    orders.forEach(order => {
      labels.push(order._id)
      if (chartType === 'percent') {
        price_cnys.push(parseFloat((parseFloat((parseFloat((parseFloat(order.sum_price_cny.toFixed(2)) - parseFloat(order.sum_deposit_cny.toFixed(2)) - parseFloat(order.sum_balance_cny.toFixed(2))).toFixed(2)) / parseFloat(order.sum_price_cny.toFixed(2))).toFixed(2)) * 100).toFixed(2)))
        deposit_cnys.push(parseFloat((parseFloat((parseFloat(order.sum_deposit_cny.toFixed(2)) / parseFloat(order.sum_price_cny.toFixed(2))).toFixed(2)) * 100).toFixed(2)))
        balance_cnys.push(parseFloat((parseFloat((parseFloat(order.sum_balance_cny.toFixed(2)) / parseFloat(order.sum_price_cny.toFixed(2))).toFixed(2)) * 100).toFixed(2)))
      } else if (chartType === 'price') {
        price_cnys.push(parseFloat(order.sum_price_cny.toFixed(2)) - parseFloat(order.sum_deposit_cny.toFixed(2)) - parseFloat(order.sum_balance_cny.toFixed(2)))
        deposit_cnys.push(parseFloat(order.sum_deposit_cny.toFixed(2)))
        balance_cnys.push(parseFloat(order.sum_balance_cny.toFixed(2)))
        sum_price_cny.current += parseFloat(order.sum_price_cny.toFixed(2))
      }
    })

    let chartData = [
      {
        name: '已付尾款' + (chartType === 'percent' ? '百分比' : '金额'),
        type: 'bar',
        stack: 'cny',
        label: {
          show: true,
          formatter: function (value: { data: number }) {
            return value.data > 0 ? value.data + (chartType === 'percent' ? '%' : '元') : ''
          }
        },
        data: balance_cnys
      },
      {
        name: '已付定金' + (chartType === 'percent' ? '百分比' : '金额'),
        type: 'bar',
        stack: 'cny',
        label: {
          show: true,
          formatter: function (value: { data: number }) {
            return value.data > 0 ? value.data + (chartType === 'percent' ? '%' : '元') : ''
          }
        },
        data: deposit_cnys
      },
      {
        name: '剩余支付' + (chartType === 'percent' ? '百分比' : '金额'),
        type: 'bar',
        stack: 'cny',
        label: {
          show: true,
          formatter: function (value: { data: number }) {
            return value.data > 0 ? value.data + (chartType === 'percent' ? '%' : '元') : ''
          }
        },
        data: price_cnys
      }
    ]
    return { labels, chartData }
  }

  const updateChart = () => {
    const chartData = processOrders(chartOrders as any)
    myChart.current.setOption({
      backgroundColor: 'white',
      title: {
        text: '订单已付定金、尾款' + (chartType === 'percent' ? '百分比' : '金额。订单金额共 ' + sum_price_cny.current + ' 元'),
        left: '3%',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {right: 40,},
      grid: {
        left: '3%',
        right: '3%',
        bottom: '5%',
        containLabel: true
      },
      xAxis:
      {
        type: 'category',
        data: chartData.labels,
        axisLabel: {
          fontSize: '1.1em',
          marginLeft: 10,
          show: true,
          interval: 0,
          rotate: -60,
          inside: false,
        },
      }
      ,
      yAxis: {
        type: 'value'
      },
      series: chartData.chartData
    });
  }

  const resizeEcharts = () => {
    myChart.current.resize()
  }

  React.useEffect(() => {
    myChart.current = echarts.init((document as any).getElementById('echart'));
  }, [])

  React.useEffect(() => {
    getOrdersAggregateByCustomer()
  }, [month])

  React.useEffect(() => {
    updateChart()
  }, [chartOrders, chartType])

  React.useLayoutEffect(() => {
    window.addEventListener('resize', resizeEcharts);
    return () => window.removeEventListener('resize', resizeEcharts);
  }, [])

  return (
    <div style={{background: 'white', paddingTop: '1em'}}>
      <Box sx={{ my: '1%', mx: '2%', width: '50%' }}>
        <TextField
          size="small"
          type='month'
          sx={{ mx: 2 }}
          value={month}
          onChange={(event) => {
            setMonth(event.target.value)
          }}
        ></TextField>
        <FormControl>
          <RadioGroup
            row
            defaultValue="percent"
            name="row-radio-buttons-group"
            onChange={(event) => {setChartType(event.target.value)}}
          >
            <FormControlLabel value="percent" control={<Radio />} label="百分比" />
            <FormControlLabel value="price" control={<Radio />} label="订单金额" />
          </RadioGroup>
        </FormControl>
      </Box>
      <div id="echart" style={{ width: '100%', height: '80vh'}}></div>
    </div>
  )
}

export default OrdersChart