import { Box } from "@mui/material"
import OrdersChart from "../components/Welcome/OrdersChart"

function Welcome(props: any) {
  return (
    <Box>
      <OrdersChart
        newAlert={props.newAlert}
      >
      </OrdersChart>

    </Box>
  )
}

export default Welcome