import axios from 'axios'
class WAREHOUSE_STOCK_APi {
	url = "https://opsloth.com/warehouse_stock"
	table_name!: string
	data?: object = {}
	filter?: object
	field?: string
	options?: object

	constructor(table_name: string, url = "https://opsloth.com/warehouse_stock") {
		this.table_name = table_name
		this.url = url
	}

	async get(params?: object) {
		try {
			return await axios.get(`${this.url}/${this.table_name}${this.processParams(params)}`)
		} catch (e) {
			return e
		}
	}
	async count(params?: object) {
		try {
			return await axios.get(`${this.url}/${this.table_name}/count${this.processParams(params)}`)
		} catch (e) {
			return e
		}
	}

	async post(data: object, params?: object) {
		try {
			return await axios.post(`${this.url}/${this.table_name}${this.processParams(params)}`, data ? data : this.data)
		} catch (e) {
			return e
		}
	}

	async put(data: object, params?: object) {
		try {
			return await axios.put(`${this.url}/${this.table_name}${this.processParams(params)}`, data ? data : this.data)
		} catch (e) {
			return e
		}
	}

	async send_template_message(data: object) {
		try {
			return await axios.post(`https://opsloth.com/wechat_ops_access_token/send_template_message`, data ? data : this.data)
		} catch (e) {
			return e
		}
	}

	private processParams = (params?: { [key: string]: any | string }) => {
		let parmasUrlString = ''
		if (!params) {
			params = {}
		}
		params.filter = this.filter ?? {}
		params.field = this.field ?? ''
		params.options = this.options ?? {}
		Object.keys(params).forEach(key => {
			if (typeof (params![key]) === 'string') {
				parmasUrlString += `&${key}=${params![key].toString()}`
			} else {
				parmasUrlString += `&${key}=${JSON.stringify(params![key])}`
			}
		})
		parmasUrlString = parmasUrlString.replace('&', '?')
		return parmasUrlString
	}

	setFilter(filter: object) {
		this.filter = filter
		return this
	}

	setField(field: any) {
		this.field = field
		return this
	}

	setOptions(options: object) {
		this.options = options
		return this
	}

}
export default WAREHOUSE_STOCK_APi