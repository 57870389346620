import CMSApi from '../utils/opsloth_cms_service_api'

interface items {
    label: string,
    value: string | number
}

const arriveds: items[] = [
    {
        label: "未到货",
        value: "未到货"
    },
    {
        label: "已报关",
        value: "已报关"
    },
    {
        label: "将到货",
        value: "将到货"
    },
    {
        label: "已到货",
        value: "已到货"
    },
    {
        label: "不对外",
        value: "不对外"
    }
]

const categorys: items[] = [
    {
        label: "扭蛋",
        value: "扭蛋"
    },
    {
        label: "周边",
        value: "周边"
    },
    {
        label: "周边-现货",
        value: "周边-现货"
    },
    {
        label: "手办",
        value: "手办"
    }
]

const on_taobaos: items[] = [
    {
        label: "未上淘宝",
        value: ""
    },
    {
        label: "已上淘宝",
        value: "已上淘宝"
    }
]

let brands: items[] = [
    {
        "label": "AMUSE",
        "value": "AMUSE"
    },
    {
        "label": "BANDAI",
        "value": "BANDAI"
    },
    {
        "label": "BEAM",
        "value": "BEAM"
    },
    {
        "label": "BENELIC",
        "value": "BENELIC"
    },
    {
        "label": "BUSHIROAD",
        "value": "BUSHIROAD"
    },
    {
        "label": "FUKUYA",
        "value": "FUKUYA"
    },
    {
        "label": "GACHATO",
        "value": "GACHATO"
    },
    {
        "label": "GSC",
        "value": "GSC"
    },
    {
        "label": "HMA",
        "value": "HMA"
    },
    {
        "label": "HOTLINE",
        "value": "HOTLINE"
    },
    {
        "label": "IKIMON",
        "value": "IKIMON"
    },
    {
        "label": "IP4",
        "value": "IP4"
    },
    {
        "label": "J-DREAM",
        "value": "J-DREAM"
    },
    {
        "label": "KAIYODO",
        "value": "KAIYODO"
    },
    {
        "label": "KENELEPHANT",
        "value": "KENELEPHANT"
    },
    {
        "label": "KITAN",
        "value": "KITAN"
    },
    {
        "label": "KYODOH",
        "value": "KYODOH"
    },
    {
        "label": "MIKAZUKO USAGI",
        "value": "MIKAZUKO USAGI"
    },
    {
        "label": "PEANUTS CLUB",
        "value": "PEANUTS CLUB"
    },
    {
        "label": "PROOF",
        "value": "PROOF"
    },
    {
        "label": "QUALIA",
        "value": "QUALIA"
    },
    {
        "label": "RAINBOW",
        "value": "RAINBOW"
    },
    {
        "label": "SHINEG",
        "value": "SHINEG"
    },
    {
        "label": "SK JAPAN",
        "value": "SK JAPAN"
    },
    {
        "label": "SO-TA",
        "value": "SO-TA"
    },
    {
        "label": "STASTO",
        "value": "STASTO"
    },
    {
        "label": "SUNHOSEKI",
        "value": "SUNHOSEKI"
    },
    {
        "label": "T-ARTS",
        "value": "T-ARTS"
    },
    {
        "label": "TARLIN",
        "value": "TARLIN"
    },
    {
        "label": "TOY SPIRITS",
        "value": "TOY SPIRITS"
    },
    {
        "label": "TOYS CABIN",
        "value": "TOYS CABIN"
    },
    {
        "label": "ULCAP",
        "value": "ULCAP"
    },
    {
        "label": "UNIQREATE",
        "value": "UNIQREATE"
    },
    {
        "label": "VAG",
        "value": "VAG"
    },
    {
        "label": "YELL",
        "value": "YELL"
    },
    {
        "label": "YELL毛绒",
        "value": "YELL毛绒"
    },
    {
        "label": "YOU+MORE!",
        "value": "YOU+MORE!"
    },
    {
        "label": "博报堂",
        "value": "博报堂"
    },
    {
        "label": "梦屋",
        "value": "梦屋"
    },
    {
        "label": "石川玩具",
        "value": "石川玩具"
    }
]

const logistics_companys: items[] = [
    {
        label: "中通",
        value: "中通"
    },
    {
        label: "申通",
        value: "申通"
    },
    {
        label: "圆通",
        value: "圆通"
    },
    {
        label: "顺丰",
        value: "顺丰"
    },
    {
        label: "跨越",
        value: "跨越"
    },
    {
        label: "韵达",
        value: "韵达"
    },
    {
        label: "丹鸟",
        value: "丹鸟"
    },
    {
        label: "极兔",
        value: "极兔"
    },
    {
        label: "邮政",
        value: "邮政"
    },
    {
        label: "货拉拉",
        value: "货拉拉"
    },
    {
        label: '其他',
        value: '其他'
    }
]

const logistics_statuses: items[] = [
    {
        label: "运输中",
        value: "运输中"
    },
    {
        label: "已签收",
        value: "已签收"
    },
    {
        label: "已拦截",
        value: "已拦截"
    },
    {
        label: "退货中",
        value: "退货中"
    },
    {
        label: "记掉件",
        value: "记掉件"
    },
    {
        label: "已退回",
        value: "已退回"
    },
    {
        label: "已结算",
        value: "已结算"
    }
]

const store_names: items[] = [
    {
        label: "批发",
        value: "批发"
    },
    {
        label: "淘宝-剥了虾的壳",
        value: "淘宝-剥了虾的壳"
    },
    {
        label: "淘宝-水豚小怪兽",
        value: "淘宝-水豚小怪兽"
    },
    {
        label: "拼多多-我家玩具太太太可爱了",
        value: "拼多多-我家玩具太太太可爱了"
    },
    {
        label: "拼多多-虾壳社",
        value: "拼多多-虾壳社"
    },
    {
        label: "拼多多-虾壳社二店",
        value: "拼多多-虾壳社二店"
    },
    {
        label: "拼多多-水豚家",
        value: "拼多多-水豚家"
    },
    {
        label: "速卖通",
        value: "速卖通"
    },
    {
        label: "LAZADA",
        value: "LAZADA"
    },
    {
        label: "其他",
        value: "其他"
    }
]

const order_statuses: items[] = [
    {
        label: "创建订单",
        value: "创建订单"
    },
    {
        label: "定金已付",
        value: "定金已付"
    },
    {
        label: "尾款已付",
        value: "尾款已付"
    },
    {
        label: "定金未付",
        value: "定金未付"
    },
    {
        label: "尾款未付",
        value: "尾款未付"
    },
    {
        label: "已部分发货",
        value: "已部分发货"
    },
    {
        label: "已全部发货",
        value: "已全部发货"
    },
    {
        label: "订单已完成",
        value: "订单已完成"
    }
]

const cargo_sents: items[] = [
    {
        label: "未发货",
        value: "未发货"
    },
    {
        label: "已全部发货",
        value: "已全部发货"
    },
    {
        label: "已部分发货",
        value: "已部分发货"
    }
]
const tagsItem: items[] = [
    {
        label: "可爱",
        value: "可爱"
    }
]

const price_jpys: items[] = [
    {
        label: "200日元",
        value: 200
    },
    {
        label: "300日元",
        value: 300
    },
    {
        label: "400日元",
        value: 400
    },
    {
        label: "500日元",
        value: 500
    },
]

const dealers: items[] = []
// const select_items: { [key: string]: items[] } = {
//     arrived: arriveds,
//     brand: brands,
//     logistics_company: logistics_companys,
//     logistics_status: logistics_statuses,
//     store_name: store_names,
//     order_status: order_status,
//     cargo_sent: cargo_sent
// };

class Select_items {
    arrived: items[];
    brand: items[];
    tag: items[];
    price_jpy: items[];
    logistics_company: items[];
    logistics_status: items[];
    store_name: items[];
    category: items[];
    dealer: items[];
    order_status: items[];
    cargo_sent: items[];
    on_taobao: items[]
    constructor() {
        this.arrived = arriveds
        this.brand = brands
        this.category = categorys
        this.dealer = dealers
        this.tag = tagsItem
        this.price_jpy = price_jpys
        this.on_taobao = on_taobaos
        this.logistics_company = logistics_companys
        this.logistics_status = logistics_statuses
        this.store_name = store_names
        this.order_status = order_statuses
        this.cargo_sent = cargo_sents
        this.loadBrands()
        this.loadTags()
        this.loadDealers()
    }
    loadBrands() {
        return new Promise(async (resolve, reject) => {
            const brandsModal = new CMSApi('brands')
            const brandsRes: any = await brandsModal
                .setFilter({ deleted: false })
                .setOptions({ sort: 'brand' })
                .setField('brand')
                .get()
            let brands: items[] = []
            brandsRes.data.forEach((item: { brand: string }) => {
                brands.push({
                    label: item.brand,
                    value: item.brand
                })
            })
            this.brand = brands
            resolve(brandsRes.data)
        })
    }

    loadTags() {
        return new Promise(async (resolve, reject) => {
            const productTagsModal = new CMSApi('product_tags')
            const productTagsRes: any = await productTagsModal
                .setFilter({ deleted: false })
                .setOptions({ sort: 'tag' })
                .setField('-createdAt -updatedAt -deleted')
                .get()
            let tags: items[] = []
            productTagsRes.data.forEach((item: { tag: string }) => {
                tags.push({
                    label: item.tag,
                    value: item.tag
                })
            })
            this.tag = tags
            resolve(productTagsRes.data)
        })
    }

    loadDealers(){
        return new Promise(async (resolve, reject) => {
            const productDealersModal = new CMSApi('dealers')
            const productDealersRes: any = await productDealersModal
                .setFilter({ deleted: false })
                .setField('-createdAt -updatedAt -deleted')
                .get()
            let dealers: items[] = []
            productDealersRes.data.forEach((item: { name: string }) => {
                dealers.push({
                    label: item.name,
                    value: item.name
                })
            })
            this.dealer = dealers
            resolve(productDealersRes.data)
        })
    }

}

class Singleton {
    private static instance: Select_items;
    static getInstance() {
        if (!this.instance) {
            this.instance = new Select_items();
        }
        return this.instance;
    }
}

export default Singleton.getInstance()