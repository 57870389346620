import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import ExcelJS from 'exceljs'

function ExcelTableXLSX(props: any) {
  const tableHeads: string[] = ['货号', '日文名称', '中文名称', '成本(元/个)', '成本(日元/个)','日元售价', '代理版(元/个)', '日版(元/个)', '每盒数量', '每箱数量', '截单日期', '发售日期']
  return <TableContainer
    sx={props.sx}
  >
    <Table>
      <TableHead>
        <TableRow>
          {
            tableHeads.map((head, headIndex) =>
              <TableCell
                key={headIndex}
                sx={{
                  fontSize: '.4em',
                  minWidth: '1em',
                  maxWidth: '1em',
                  whiteSpace: 'nowrap',
                  borderLeft: 'solid 1px #ccc',
                  borderTop: 'solid 1px #ccc',
                  background: '#DCE6F1'
                }}
              >{head}</TableCell>
            )
          }
        </TableRow>
      </TableHead>
      <TableBody>

        {
          props.sheet?.getRows(2, props.sheet.rowCount - 1).map((row: ExcelJS.CellValue, row_index: number) =>
            <TableRow key={row_index}>
              {

                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index: number) =>
                  <TableCell
                    key={index}
                    sx={{
                      p: 1,
                      fontSize: '.4em',
                      minWidth: '1em',
                      maxWidth: '1em',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      borderLeft: 'solid 1px #ccc'
                    }}
                  >
                    {
                      (index == 11 || index == 12) && new Date(JSON.stringify((row as any)?.values[index] ?? '')?.replaceAll("\"", '')).toLocaleDateString()
                    }
                    {
                      (index != 11 && index != 12) && ((row as any)?.values[index] ?? '').toString()
                    }
                  </TableCell>
                )
              }
            </TableRow>
          )
        }

      </TableBody>
    </Table>
  </TableContainer>
}

export default ExcelTableXLSX